/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  FadCommentService,
  FadProviderDetailService,
  FadCompareCommentDetail,
  FadProviderDetail,
  FadSeachStateService,
} from '@dmx-fad-ui/fad/data-access';
import { Subscription } from 'rxjs';
import { CompareProviderService } from '../../services/compare-provider/compare-provider.service';
import { CompareProviderModel } from '../../services/compare-provider/compareProviderModel';
import { AhnTextComponent } from '../../components/ahn-text/ahn-text.component';

import { CredentialsCompare } from './compare-classes/credentials-compare/credentials-compare';
import { DistanceCompare } from './compare-classes/distance-compare/distance-compare';
import { ExpertiseCompare } from './compare-classes/expertise-compare/expertise-compare';
import { LanguageCompare } from './compare-classes/language-compare/language-compare';
import { LgbtCompare } from './compare-classes/lgbt-compare/lgbt-compare';
import { LocationCompare } from './compare-classes/location-compare/location-compare';
import { NameCompare } from './compare-classes/name-compare/name-compare';
import { OnlineSchedulingCompare } from './compare-classes/online-scheduling-compare/online-scheduling-compare';
import { RatingsCompare } from './compare-classes/ratings-compare/ratings-compare';
import { SeoService } from '../../services/seo-service/seo.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UrlMonitorService } from '@dmx-fad-ui/fad/services';
import { NavigationEndEvent } from '../../models/navigationEndEvent';
import { ahnTextPath } from '../../components/ahn-text/ahn-text.enum';
import { ViewportScroller } from '@angular/common';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'atlas-compare-page',
  templateUrl: './compare-page.component.html',
  styleUrls: ['./compare-page.component.scss'],
})
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export class ComparePageComponent implements OnInit, OnDestroy {
  queryParamObj: any;
  prov1: string = undefined;
  prov2: string = undefined;
  prov3: string = undefined;
  prov4: string = undefined;
  provListLength: number = undefined;
  provDetailList: FadProviderDetail[] = [];

  commentsRatingsList: FadCompareCommentDetail[] = [];
  provDetailListSub: Subscription;
  compareListSub: Subscription;
  isLoadedProvs: boolean = false;

  provList: string[] = [];
  nameCompareList: NameCompare[] = [];
  expertiseCompareList: ExpertiseCompare[] = [];
  onlineSchedulingCompareList: OnlineSchedulingCompare[] = [];
  locationCompareList: LocationCompare[] = [];
  distanceCompareList: DistanceCompare[] = [];
  languageCompareList: LanguageCompare[] = [];
  ratingsCompareList: RatingsCompare[] = [];
  credentialsCompareList: CredentialsCompare[] = [];
  lgbtCompareList: LgbtCompare[] = [];

  tempCredentialList: string[] = [];
  tempBoards: string[] = [];
  tempSchools: string[] = [];
  tempResidencies: string[] = [];
  tempJobTitles: string[] = [];
  tempFellowships: string[] = [];
  finalUpdatedRatings = [];
  showReturnText: boolean = true;

  nameCategory: string = 'Name';
  expertiseCategory: string = 'Expertise';
  onlineSchedulingCategory: string = 'Online Scheduling';
  locationCategory: string = 'Primary Location';
  distanceCategory: string = 'Distance';
  languageCategory: string = 'Languages';
  ratingsCategory: string = 'Ratings';
  credsCategory: string = 'Credentials';
  lgbtCategory: string = 'LGBTQIA+ Champion';
  compareProvSub: Subscription;
  compareProvData: CompareProviderModel[] = [];
  removeCompareProvidersSubscription: Subscription;
  imageNotFoundComparePath: string = '../../../../../assets/AHN-provider-not-found-square.png';
  urlArray: NavigationEndEvent[] = [];
  urlMonitorSub: Subscription;

  isProvDetailsFinished: boolean = false;
  isProvRatingsFinished: boolean = false;
  isNewComparePage: boolean = environment.kyruusFeatures.comparePage.isNewComparePage;

  @ViewChild('ahntext') child: AhnTextComponent;
  pageType: ahnTextPath;

  constructor(
    private provDetServ: FadProviderDetailService,
    private compareServ: CompareProviderService,
    private provRatServ: FadCommentService,
    private fadSearchStateServ: FadSeachStateService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private seoService: SeoService,
    private urlMonitorServ: UrlMonitorService,
    private viewportScroller: ViewportScroller,
  ) {
    this.activatedRoute = activatedRoute;
    this.provDetServ = provDetServ;
    this.compareServ = compareServ;
    this.provRatServ = provRatServ;
    this.fadSearchStateServ = fadSearchStateServ;
    this.urlMonitorServ = urlMonitorServ;
    this.router = router;
    this.pageType = ahnTextPath.results;

    this.urlMonitorSub = this.urlMonitorServ.urlMonitorSubj.subscribe((navEvents) => {
      this.urlArray = [];
      navEvents.forEach((item) => {
        if (item) {
          const textUrl = item.url;
          const isHashPresentUrl = textUrl.includes('#');
          if (isHashPresentUrl === false) {
            this.urlArray.push(item);
          }
        }
      });
    });

    const secondLastUrl = this.urlArray[this.urlArray.length - 2]
      ? this.urlArray[this.urlArray.length - 2].url
      : '/';

    if (
      secondLastUrl === '/' &&
      this.urlArray.find((navEvent) => navEvent.url.includes('/search-results/')) === undefined
    ) {
      this.showReturnText = false;
    }
  }

  ngOnInit(): void {
    this.viewportScroller.scrollToPosition([0, 0]);
    this.subscribeToCompareProvDetailSubj();

    const urlProvReqNPI = [];

    this.activatedRoute.queryParams.forEach((param) => {
      if (param.provider1) {
        urlProvReqNPI.push(param.provider1);
      }

      if (param.provider2) {
        urlProvReqNPI.push(param.provider2);
      }

      if (param.provider3) {
        urlProvReqNPI.push(param.provider3);
      }

      if (param.provider4) {
        urlProvReqNPI.push(param.provider4);
      }
    });

    const provCompareDetailReq = this.provDetServ.createCompareProviderReq(urlProvReqNPI);

    this.provDetServ.getCompareProviderDetail(provCompareDetailReq);

    this.removeCompareProvidersSubscription = this.compareServ.removeFromList$.subscribe(
      (provider) => this.removeProviderCompare(provider),
    );

    // This was to redirect if the user somehow ended up on compare with
    // no providers selected, leaving for now in case QA finds this is still a possibility
    // and this code is still necessary
    //
    // if (this.compareServ.checkboxCount === 0) {
    //   window.location.href = window.location.origin;
    // }

    this.prov1 = urlProvReqNPI[0];
    this.prov2 = urlProvReqNPI[1];
    this.prov3 = urlProvReqNPI[2];
    this.prov4 = urlProvReqNPI[3];

    if (this.prov1 !== '' && this.prov1 != undefined) {
      this.provList.push(this.prov1);
    }

    if (this.prov2 !== '' && this.prov2 != undefined) {
      this.provList.push(this.prov2);
    }

    if (this.prov3 !== '' && this.prov3 != undefined) {
      this.provList.push(this.prov3);
    }

    if (this.prov4 !== '' && this.prov3 != undefined) {
      this.provList.push(this.prov4);
    }

    this.provListLength = this.provList.length;
    this.seoService.addMainContent();
  }

  removeProviderCompare(provider: string): void {
    if (provider != '') {
      this.provListLength = this.compareServ.checkboxCount;
      if (this.provListLength >= 1 && this.provListLength <= 4) {
        this.compareServ.removeFromList(provider);

        this.router.navigate(['compare/providers'], {
          queryParams: this.compareServ.buildCompareQueryParams(),
        });
      } else {
        this.child.onReturnToCare();
      }
    }
  }

  emptyResultArrays() {
    this.nameCompareList = [];
    this.expertiseCompareList = [];
    this.onlineSchedulingCompareList = [];
    this.locationCompareList = [];
    this.distanceCompareList = [];
    this.languageCompareList = [];
    this.ratingsCompareList = [];
    this.credentialsCompareList = [];
    this.lgbtCompareList = [];
    this.tempCredentialList = [];
    this.tempBoards = [];
    this.tempSchools = [];
    this.tempResidencies = [];
    this.tempJobTitles = [];
    this.tempFellowships = [];
    this.commentsRatingsList = [];
    this.finalUpdatedRatings = [];
    this.provDetailList = [];
    this.isLoadedProvs = false;
    this.compareListSub.unsubscribe();
  }

  subscribeToCompareProvDetailSubj() {
    this.provDetailListSub = this.provDetServ.compareProviderSubj.subscribe((provs) => {
      this.isProvDetailsFinished = false;
      this.subscribeToCompareCommentsSubj();
      this.provDetailList = provs;

      const pl = this.provList;
      this.provDetailList = this.provDetailList.sort(function (providerA, providerB) {
        return pl.indexOf(providerA.npi) - pl.indexOf(providerB.npi);
      });

      this.isLoadedProvs = true;

      this.nameCompareList = this.createNameCompareListFromProviders(this.provDetailList);

      this.provDetailList.forEach((prov) => {
        this.locationCompareList.push(
          new LocationCompare(prov.locations, prov.npi, prov.profileName),
        );
      });

      this.provDetailList.forEach((prov) => {
        const distanceIndex = this.compareProvData.findIndex(
          (x) => x.profileName === prov.profileName,
        );
        if (distanceIndex != -1) {
          this.distanceCompareList.push(
            new DistanceCompare(this.compareProvData[distanceIndex].distance, prov.npi),
          );
        } else {
          this.distanceCompareList.push(new DistanceCompare('', prov.npi));
        }
      });

      this.provDetailList.forEach((prov) => {
        this.expertiseCompareList.push(new ExpertiseCompare(prov.conditions, prov.npi));
      });

      this.provDetailList.forEach((prov) => {
        this.languageCompareList.push(new LanguageCompare(prov.languages, prov.npi));
      });

      this.provDetailList.forEach((prov) => {
        this.onlineSchedulingCompareList.push(
          new OnlineSchedulingCompare(prov.schedulable, prov.npi, prov.profileName),
        );
      });

      this.provDetailList.forEach((prov) => {
        prov.boards.forEach((board) => {
          this.tempBoards.push(board);
        });
        prov.schools.forEach((school) => {
          this.tempSchools.push(school);
        });
        prov.schools.forEach((res) => {
          this.tempResidencies.push(res);
        });
        prov.jobTitles.forEach((job) => {
          this.tempJobTitles.push(job);
        });
        prov.fellowships.forEach((fellow) => {
          this.tempFellowships.push(fellow);
        });
        this.credentialsCompareList.push(
          new CredentialsCompare(
            prov.boards,
            prov.fellowships,
            prov.jobTitles,
            prov.residencies,
            prov.schools,
            prov.npi,
          ),
        );
      });

      this.provDetailList.forEach((prov) => {
        this.lgbtCompareList.push(new LgbtCompare(prov.lgbtqAligned, prov.npi));
      });

      const ratingsProvList = [];

      this.provDetailList.forEach((provider) => {
        ratingsProvList.push(provider.profileName);
      });

      const ratCompareReq = this.provRatServ.createCompareProviderReq(ratingsProvList);
      this.provRatServ.getCompareCommentDetail(ratCompareReq);
      this.isProvDetailsFinished = true;
      this.turnOffSpinnerIfReady();
    });
  }

  createNameCompareListFromProviders(provDetList: FadProviderDetail[]): NameCompare[] {
    const nameCompList: NameCompare[] = [];
    provDetList.forEach((prov) => {
      if (prov.printImageUrl === '../../../../../assets/AHN-provider-not-found.png') {
        nameCompList.push(
          new NameCompare(
            prov.displayName,
            prov.profileName,
            prov.npi,
            prov.signatureSpecialty,
            prov.reviewRatingCount,
            prov.reviewRatingValue,
            this.imageNotFoundComparePath,
          ),
        );
      } else {
        nameCompList.push(
          new NameCompare(
            prov.displayName,
            prov.profileName,
            prov.npi,
            prov.signatureSpecialty,
            prov.reviewRatingCount,
            prov.reviewRatingValue,
            prov.printImageUrl,
          ),
        );
      }
    });
    return nameCompList;
  }

  subscribeToCompareCommentsSubj() {
    this.compareListSub = this.provRatServ.compareRatingSubj.subscribe((comratings) => {
      this.isProvRatingsFinished = false;
      this.commentsRatingsList = comratings;
      this.isLoadedProvs = true;
      this.commentsRatingsList.forEach((ratings) => {
        this.ratingsCompareList.push(new RatingsCompare(ratings));
      });
      this.compareAndUpdateRatings();
    });
  }

  compareAndUpdateRatings() {
    this.provDetailList.forEach((prov) => {
      const updatedRatings = this.commentsRatingsList.find(
        (ratings) => ratings.commentsAndRatings.profileName === prov.profileName,
      );
      if (updatedRatings) {
        this.finalUpdatedRatings.push(new RatingsCompare(updatedRatings));
      }
    });
    this.isProvRatingsFinished = true;
    this.turnOffSpinnerIfReady();
  }

  ngOnDestroy() {
    if (this.provDetailListSub) {
      this.provDetailListSub.unsubscribe();
    }
    if (this.compareListSub) {
      this.compareListSub.unsubscribe();
    }
    if (this.removeCompareProvidersSubscription) {
      this.removeCompareProvidersSubscription.unsubscribe();
    }
  }

  turnOffSpinnerIfReady() {
    if (this.isProvDetailsFinished === true && this.isProvRatingsFinished === true) {
      this.fadSearchStateServ.isShowSpinnerSubj.next(false);
    }
  }
}
