import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ProviderContact } from './phoneNumber';

@Component({
  selector: 'engen-profile-banner-v2',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './profile-banner-v2.component.html',
  styleUrl: './profile-banner-v2.component.scss',
})
export class ProfileBannerV2Component {
  @Input() providerFullName: string = '';
  @Input() providerSpecialty: string = '';
  @Input() providerPhoneNumber: ProviderContact[] = [];
}
