<div
  *ngIf="providerExpertiseResults && providerExpertiseResults.length > 0"
  class="provider-expertise-container"
>
  <div
    id="provider-expertises"
    [ngClass]="isNewproviderProfileExperience ? 'ahn-expertise-new' : 'ahn-expertise'"
  >
    <h3>Expertise</h3>
    <div class="expertise-container">
      <div class="expertise-list" *ngFor="let providerexpertise of providerExpertiseResults">
        <span>{{ providerexpertise }}</span>
      </div>
    </div>
  </div>
</div>
