<div *ngIf="location" class="provider-locations-container">
  <div class="provider-contact-container">
    <div class="provider-contact">
      <div class="provider-each-flex">
        <div class="provider-inner-card">
          <div class="provider-practice-name roboto-bold">
            <a class="provider-name-color" [href]="getEncodedUrl(practiceBuilding.practiceName)">{{
              practiceBuilding.practiceName
            }}</a>
          </div>
          <div
            class="provider-practice-name"
            *ngIf="practiceBuilding.primaryPractice === 'true' && practiceBuilding.locationName"
          >
            {{ practiceBuilding.locationName }} (Primary)
          </div>
          <div
            class="provider-practice-name"
            *ngIf="practiceBuilding.primaryPractice === 'false' && practiceBuilding.locationName"
          >
            {{ practiceBuilding.locationName }}
          </div>
          <div class="provider-practice-name">{{ practiceBuilding.buildingAddress }}</div>
          <div class="provider-practice-name">
            {{ practiceBuilding.buildingCity }}, {{ practiceBuilding.buildingState }}
            {{ practiceBuilding.buildingZip }}
          </div>
          <div class="provider-directions">
            <a [href]="practiceBuilding.directions" target="_blank" class="roboto-semi-bold"
              >DIRECTIONS</a
            >
          </div>
          <div class="provider-phone">
            <a href="tel:{{ practiceBuilding.phone }}">{{ practiceBuilding.phone }}</a>
          </div>
          <div class="provider-fax" *ngIf="practiceBuilding.fax">
            Fax {{ practiceBuilding.fax }}
          </div>
          <div class="see-more-locations" *ngIf="seeMore">
            <span (click)="providerPage()" (keydown.enter)="providerPage()">
              See more locations</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
