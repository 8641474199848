<div class="print-page">
  <div *ngIf="!this.isNewPrintPage"></div>
  <div class="print-column">
    <div class="print-logo">
      <img src="../../../../assets/AHNlogo_SVG_fixedsize.svg" alt="AHN Logo" />
    </div>
    <div class="prov-image">
      <atlas-print-provider-image
        [imgSrc]="providerDetail.printImageUrl"
        (isContentLoadedEmitter)="onReceivedProviderImageContent($event)"
      ></atlas-print-provider-image>
    </div>
    <!-- QR CODE-->

    <div class="prov-image" *ngIf="providerDetail.employed === 'Employed'">
      <div class="scan-tip">Scan to view profile</div>
      <qrcode
        [qrdata]="qrCodeUrl"
        [width]="256"
        [errorCorrectionLevel]="correctionLevel"
        [elementType]="elementType"
        cssClass="qrcodeshadow"
      ></qrcode>
      <div class="url-wrap">findcare.ahn.org/<br />{{ providerDetail.profileName }}</div>
    </div>
  </div>

  <!-- INFO COLUMN -->
  <div class="info-column">
    <div *ngIf="providerDetail !== {}" class="provider-name">
      {{ providerDetail.displayName }}
    </div>
    <div class="sig-spec">
      {{ signatureSpecialty }}
    </div>
    <div *ngIf="hasData">
      <div>
        <atlas-print-locations
          [providerPracticeBuildings]="providerLocations"
          (isContentLoadedEmitter)="onReceivedLocationContent($event)"
        ></atlas-print-locations>
      </div>
    </div>
    <div *ngIf="hasData">
      <div>
        <atlas-print-bio
          [bioInput]="this.bio"
          (isContentLoadedEmitter)="onReceivedBioContent($event)"
        ></atlas-print-bio>
      </div>
    </div>
    <div class="print-expertises-container" *ngIf="providerExpertiseColumnOne.length > 0">
      <div>
        <h3>Expertise</h3>
      </div>
      <div [ngClass]="hasExpertise() ? 'bottom-border' : ''">
        <div class="print-expertise-container">
          <div class="print-expertise-column">
            <div
              *ngFor="let expertise of this.providerExpertiseColumnOne"
              class="expertise-list expertise-left"
            >
              <atlas-print-expertise
                [expertiseItem]="expertise"
                (isContentLoadedEmitter)="onReceivedExpertiseContent($event)"
              ></atlas-print-expertise>
            </div>
          </div>

          <div class="print-expertise-column">
            <div *ngFor="let expertise of this.providerExpertiseColumnTwo" class="expertise-list">
              <atlas-print-expertise
                [expertiseItem]="expertise"
                (isContentLoadedEmitter)="onReceivedExpertiseContent($event)"
              ></atlas-print-expertise>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="print-credentials-container">
      <div class="cred-buffer">
        <h3>Credentials</h3>
      </div>
      <div id="printCredentials" class="print-credentials bottom-border">
        <div *ngIf="schools.length > 0" class="print-credential">
          <div class="cred-title">Schools</div>
          <atlas-print-credentials
            [credentials]="schools"
            (isContentLoadedEmitter)="onReceivedSchoolsContent($event)"
          ></atlas-print-credentials>
        </div>
        <div *ngIf="boards.length > 0" class="print-credential">
          <div class="cred-title">Boards</div>
          <atlas-print-credentials
            [credentials]="boards"
            (isContentLoadedEmitter)="onReceivedBoardsContent($event)"
          ></atlas-print-credentials>
        </div>
        <div *ngIf="residencies.length > 0" class="print-credential">
          <div class="cred-title">Residencies</div>
          <atlas-print-credentials
            [credentials]="residencies"
            (isContentLoadedEmitter)="onReceivedResidencyContent($event)"
          ></atlas-print-credentials>
        </div>
        <div *ngIf="positions.length > 0" class="print-credential">
          <div class="cred-title">Positions</div>
          <atlas-print-credentials
            [credentials]="positions"
            (isContentLoadedEmitter)="onReceivedPositionsContent($event)"
          ></atlas-print-credentials>
        </div>
        <div *ngIf="fellowships.length > 0" class="print-credential">
          <div class="cred-title">Fellowships</div>
          <atlas-print-credentials
            [credentials]="fellowships"
            (isContentLoadedEmitter)="onReceivedFellowshipsContent($event)"
          ></atlas-print-credentials>
        </div>
      </div>
    </div>
  </div>
</div>
