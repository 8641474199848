/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/// <reference types="@types/google.maps" />
import {
  Component,
  Input,
  ViewChild,
  SimpleChanges,
  AfterViewInit,
  OnChanges,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import { ProviderResult } from '../../provider-result/provider-result';
import { MapInfoWindow, GoogleMap, MapMarker } from '@angular/google-maps';
import { ProviderMap } from './provider-map';
import { FadProviderDetailService } from '@dmx-fad-ui/fad/data-access';
import { isPlatformBrowser } from '@angular/common';
import { ahnTextPath } from '../../ahn-text/ahn-text.enum';

@Component({
  selector: 'atlas-provider-map',
  templateUrl: './provider-map.component.html',
  styleUrls: ['./provider-map.component.scss'],
})
export class ProviderMapComponent implements AfterViewInit, OnChanges {
  @Input() providerResult: ProviderResult;
  @Input() islocationsDetailPage: boolean;
  @Input() mapHeight: string;
  @ViewChild(MapInfoWindow) infoWindow: MapInfoWindow;
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;
  markers: any = [];
  allLocations: any = [];
  locations: any = [];
  bounds: any;
  positionSet: ProviderMap;
  infoContent: string = '';
  setIcon: string = '/assets/place.svg';
  checkMapspageProviderProfile = false;
  zoomLevel: number;

  constructor(
    private providerDetailService: FadProviderDetailService,
    @Inject(PLATFORM_ID) private platformId: string,
  ) {
    this.checkMapspageProviderProfile = this.providerDetailService.providerDetailMapView;
  }

  ngAfterViewInit() {
    if (
      isPlatformBrowser(this.platformId) &&
      'google' in window &&
      typeof google === 'object' &&
      typeof google.maps === 'object'
    ) {
      const bounds = this.getBounds(this.markers);
      this.map.googleMap.fitBounds(bounds);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      isPlatformBrowser(this.platformId) &&
      'google' in window &&
      typeof google === 'object' &&
      typeof google.maps === 'object'
    ) {
      if (this.providerResult) {
        this.getMapLocations(this.providerResult);
      }
    }
  }
  getMapLocations(mapresults) {
    if (isPlatformBrowser(this.platformId)) {
      this.allLocations = [];
      if (this.checkMapspageProviderProfile == true || this.islocationsDetailPage == true) {
        this.allLocations = mapresults;
      } else {
        mapresults.forEach((item) => {
          item.providerPracticeBuildings.forEach((element) => {
            this.allLocations.push(element);
          });
        });
      }

      if (this.allLocations.length >= 0) {
        this.markers = [];
        const latMap = new Map();
        const offset = 0.005; // Small offset
        this.allLocations.forEach((value, i) => {
          if (latMap.has(value.buildingLat)) {
            latMap.set(value.buildingLat, latMap.get(value.buildingLat) + 1);
            value.buildingLat =
              parseFloat(value.buildingLat) + latMap.get(value.buildingLat) * offset;
          } else {
            latMap.set(value.buildingLat, 1);
          }
          this.positionSet = {
            position: {
              lat: parseFloat(value.buildingLat),
              lng: parseFloat(value.buildingLong),
            },
            title: 'Marker label',
            icon: {
              url: this.setIcon,
              labelOrigin: {
                x: 27,
                y: 19,
              },
            },
            label: {
              color: 'white',
              text: window.location.href.includes('search-results')
                ? null
                : '' + (this.markers.length + 1),
            },

            info: `<div class="search-map-infowindow">
                  <div class="provider-contact-container">
                        <div class="provider-practice-name roboto-bold map-title">${value.practiceName}</div>
                        <div class="provider-practice-name">${value.buildingAddress}</div>
                        <div class="provider-practice-name">${value.buildingCity}, ${value.buildingState}
                   ${value.buildingZip}
                </div>
                <div class="map-phone-direction provider-locations-container">
                <div class="provider-phone"><a href="tel:${value.phone}">${value.phone}</a></div>
                <div class="provider-directions"><a class="roboto-semi-bold" href="https://maps.google.com/maps?q=${value.buildingAddress},${value.buildingCity},${value.buildingState},${value.buildingZip}" target="_blank">DIRECTIONS</a></div>
                        </div></div></div>`,
          };
          this.markers.push(this.positionSet);
        });
      }
    }
  }

  options: google.maps.MapOptions = {
    zoom: 6,
    maxZoom: 11,
    //   center: { lat: 40.426998, lng: -79.752580 },
    styles: [
      {
        featureType: 'administrative',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#444444',
          },
        ],
      },
      {
        featureType: 'landscape',
        elementType: 'all',
        stylers: [
          {
            color: '#f2f2f2',
          },
        ],
      },
      {
        featureType: 'poi',
        elementType: 'all',
        stylers: [
          {
            visibility: 'simplified',
          },
        ],
      },
      {
        featureType: 'poi.business',
        elementType: 'labels',
        stylers: [
          {
            visibility: 'off',
          },
          {
            color: '#178e58',
          },
          {
            saturation: 31,
          },
        ],
      },
      {
        featureType: 'poi.business',
        elementType: 'labels.icon',
        stylers: [
          {
            color: '#178e58',
          },
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'poi.medical',
        elementType: 'labels',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'poi.medical',
        elementType: 'labels.icon',
        stylers: [
          {
            color: '#178e58',
          },
        ],
      },
      {
        featureType: 'poi.park',
        elementType: 'geometry.fill',
        stylers: [
          {
            color: '##000000',
          },
        ],
      },
      {
        featureType: 'road',
        elementType: 'all',
        stylers: [
          {
            saturation: -100,
          },
          {
            lightness: 45,
          },
        ],
      },
      {
        featureType: 'road.highway',
        elementType: 'all',
        stylers: [
          {
            visibility: 'simplified',
          },
        ],
      },
      {
        featureType: 'road.arterial',
        elementType: 'labels.icon',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'transit',
        elementType: 'all',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'transit.station.airport',
        elementType: 'all',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'transit.station.bus',
        elementType: 'all',
        stylers: [
          {
            visibility: 'simplified',
          },
        ],
      },
      {
        featureType: 'water',
        elementType: 'all',
        stylers: [
          {
            color: '##000000',
          },
          {
            visibility: 'on',
          },
        ],
      },
    ],
  };

  openInfo(marker: MapMarker, content) {
    if (isPlatformBrowser(this.platformId)) {
      this.infoContent = content;
      this.infoWindow.open(marker);
    }
  }

  getBounds(markers: any) {
    if (isPlatformBrowser(this.platformId)) {
      let north;
      let south;
      let east;
      let west;

      for (const marker of markers) {
        // set the coordinates to marker's lat and lng on the first run.
        // if the coordinates exist, get max or min depends on the coordinates.
        north = north !== undefined ? Math.max(north, marker.position.lat) : marker.position.lat;
        south = south !== undefined ? Math.min(south, marker.position.lat) : marker.position.lat;
        east = east !== undefined ? Math.max(east, marker.position.lng) : marker.position.lng;
        west = west !== undefined ? Math.min(west, marker.position.lng) : marker.position.lng;
      }

      const bounds = { north, south, east, west };

      return bounds;
    }
  }
}
