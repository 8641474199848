import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ProviderResultV2Component } from '../provider-result-v2/provider-result-v2.component';

@Component({
  selector: 'engen-provider-results-set-v2',
  standalone: true,
  imports: [CommonModule, ProviderResultV2Component],
  templateUrl: './provider-results-set-v2.component.html',
  styleUrl: './provider-results-set-v2.component.scss',
})
export class ProviderResultsSetV2Component implements OnInit {
  @Input() providerResultsSet: any[] = [];

  ngOnInit() {
    console.log('Hello results set v2');
    console.log(this.providerResultsSet);
  }
}
