<div class="provider-result-container">
  <div class="provider-info-left-container" (click)="navigateToProviderProfile()">
    <div class="provider-image"><img src="{{ this.providerImage }}" /></div>
    <div>{{ this.fullName }}</div>
    <div *ngFor="let sn of this.providerSpecialty">{{ sn.name }}</div>
    <div class="accept-new-patient" *ngIf="this.acceptingNewPatients === true">
      <div><img src="../../../../assets/checkmark.svg" />Accepts New Patients</div>
      <div class="age-groups-seen" *ngFor="let age of this.ageGroupsSeen">{{ age }}</div>
    </div>
    <div class="provider-info-right-container">
      <div class="provider-location" *ngFor="let loc of this.locations">
        <div class="location-name">{{ loc.name }}</div>
        <div class="street-name">{{ loc.street1 }}</div>
        <div class="city-state-zip">{{ loc.city }}, {{ loc.state }} {{ loc.zip }}</div>
        <div class="fax-number">Fax {{ loc.fax }}</div>
      </div>
    </div>
  </div>

  <!-- <div class="provider-detail-page-new provider-detail-desktop">
  <div class="provider-desktop-view">
    <div class="profile-container">
      <div class="provider-profile-image">
        <div class="provider-image"><img src="{{ this.providerImage }}" /></div>
      </div>
      <div class="provider-profile-info">
        <div class="provider-profile-info-right-container">
          <div class="provider-information">
            <div class="profile-bcg-text">
              <img
                src="../../../../assets/small_ahn_logo.svg"
                height="17px"
                width="18px"
                alt="AHN Provider"
              />
              <div class="ahn-provider-text">AHN Provider</div>
            </div>
            <div class="provider-display-name sofia-pro-semi-bold" (click)="navigateToProviderProfile()">
              <h2>{{this.fullName}}</h2>
            </div>
            <div class="provider-text roboto-regular provider-specialty" *ngFor="let sn of this.providerSpecialty">
              <h2>{{ sn.name }}</h2>
            </div>
          </div>
        </div>
        <div class="provider-profile-infor-left-container"></div>
      </div>
    </div>
  </div>
</div> -->
</div>
