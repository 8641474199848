import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { KyrussProviderDetails } from './reviews';
import { ProviderRatingsPipeModule } from '../../pipes/provider-ratings-pipe.module';

const metricMap: Map<string, string> = new Map([
  ['CP explanations of prob/condition', 'Explanations about your problem or condition'],
  ['CP concern for questions/worries', 'Concern shown for your questions or worries'],
  ['Provider included you in decisions', 'Efforts to include you in decisions about your care'],
  ['Likelihood of recommending CP', 'Likelihood of recommending this care provider'],
  [
    "Care provider's discussion of any proposed treatment (options, risks, benefits, etc.)",
    'Discussion of proposed treatments (options, risks, benefits, etc.)',
  ],
]);

export function getDisplayedMetric(originalMetric: string): string {
  const displayedMetric = metricMap.get(originalMetric);
  if (displayedMetric) {
    return displayedMetric;
  } else {
    return originalMetric;
  }
}

@Component({
  selector: 'engen-profile-reviews-tab-v2',
  standalone: true,
  imports: [CommonModule, ProviderRatingsPipeModule],
  templateUrl: './profile-reviews-tab-v2.component.html',
  styleUrl: './profile-reviews-tab-v2.component.scss',
})
export class ProfileReviewsTabV2Component {
  @Input() providerDetails: KyrussProviderDetails;

  averageRating: number = 0;
  reviewCount: number = 0;
  subRatings: { average_rating: number; crawled_date: string; metric: string }[] = [];
  reviews: { body: string; rating: number; review_date: string }[] = [];
  showMoreReviews = false;

  ngOnInit(): void {
    this.populateData();
  }

  populateData() {
    if (
      this.providerDetails &&
      this.providerDetails.provider &&
      this.providerDetails.provider.reviews
    ) {
      this.averageRating = this.providerDetails.provider.reviews.aggregate_ratings.average_rating;
      this.reviewCount = this.providerDetails.provider.reviews.aggregate_ratings.review_count;
      this.reviews = this.providerDetails.provider.reviews.reviews.slice(0, 10);
      this.showMoreReviews = this.reviews.length < this.reviewCount;
      this.subRatings = this.providerDetails.provider.reviews.aggregate_ratings.sub_ratings || [];
      this.subRatings.forEach((rating) => {
        rating.metric = getDisplayedMetric(rating.metric);
      });
    }
  }

  showMore() {
    this.reviews = this.providerDetails.provider.reviews.reviews;
    this.showMoreReviews = false;
  }

  formatDate(review_date: string): string {
    const date = new Date(review_date);

    if (isNaN(date.getTime())) {
      return 'Invalid date';
    }

    const options: Intl.DateTimeFormatOptions = {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    };

    return date.toLocaleDateString('en-US', options);
  }
}
