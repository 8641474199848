export class Locations {
  city: string;
  name: string;
  state: string;
  zip: string;
  street1: string;
  phone: string;
  fax: string;
  url: string;

  id: string;
  rank: string;
  coordinates: string;
  lat: number;
  long: number;
  constructor(lat: number, long: number, coordinates?: string) {
    this.lat = lat;
    this.long = long;
    this.coordinates = coordinates;
  }
  getSubName() {
    return this.coordinates;
  }

  setSubName(coordinates: string) {
    this.coordinates = coordinates;
  }
}
