import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Locations } from './locations';

@Component({
  selector: 'engen-profile-locations-tab-v2',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './profile-locations-tab-v2.component.html',
  styleUrl: './profile-locations-tab-v2.component.scss',
})
export class ProfileLocationsTabV2Component implements OnInit {
  @Input() providerKyruusLocations: Locations[] = [];
  name: string;
  street1: string;
  city: string;
  state: string;
  zip: string;
  url: string;
  titleUrl: string;

  ngOnInit(): void {
    console.log('Component Locations: ');
    console.log('Component Locations: ');
    console.log(this.providerKyruusLocations);
  }

  formatAddress(): string {
    return `${this.street1 || ''} ${this.city || ''}, ${this.state || ''} ${this.zip || ''}`;
  }
  getEncodedUrl(keyword: string): string {
    return '/search-results/' + encodeURIComponent(keyword);
  }
}
