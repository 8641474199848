<div class="provider-insurance-v2">
  <div class="provider-insurance-container">
    <div class="provider-insurance">
      <h3>Insurance</h3>
      <p>
        Allegheny Health Network accepts a broad range of health insurance plans. Coverage can vary
        by location and practice. Always check with your insurance plan directly to confirm coverage
        for services and to understand your benefits.
      </p>
      <div class="insurance-container">
        <div class="insurance-list" *ngFor="let ai of this.providerAcceptedInsurance">
          <span>{{ ai.name }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
