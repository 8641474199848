import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function birthdateValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const birthdate = new Date(control.value);
    const maxDate = new Date(); // Today's date
    const minDate = new Date(maxDate.getFullYear() - 120, maxDate.getMonth(), birthdate.getDate()); // 120 years ago

    //Check for invalid date first
    if (isNaN(birthdate.getTime())) {
      return { invalidDate: true }; //Return an error if the date is invalid
    }

    // Regex to validate the date in MM/DD/YYYY format
    const dateRegex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/;
    if (!dateRegex.test(control.value)) {
      return { invalidDateFormat: true };
    }

    // Check if birthdate is within valid range: MM/DD/YYYY
    if (birthdate > maxDate || birthdate < minDate) {
      return { birthdateOutOfRange: true };
    }

    return null;
  };
}
