/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, Inject, Input, OnDestroy, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// eslint-disable-next-line
import {
  FadProviderDetailService,
  FadSeachStateService,
  FadSearchResult,
  FadSearchService,
} from '@dmx-fad-ui/fad/data-access';
import { MatDialog } from '@angular/material/dialog';
import { SpinnerComponent } from '../../pages/base-page/spinner/spinner.component';
import { Subscription } from 'rxjs';
import {
  FilterService,
  UrlMonitorService,
  PdfServiceService,
  PrintRequest,
} from '@dmx-fad-ui/fad/services';
import { IDialog } from '@dmx-fad-ui/fad/interfaces';
import { Clipboard } from '@angular/cdk/clipboard';
import { ahnTextPath } from '../ahn-text/ahn-text.enum';
import { AhnTextComponent } from '../ahn-text/ahn-text.component';
import { isPlatformBrowser } from '@angular/common';
import { NavigationEndEvent } from '../../models/navigationEndEvent';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'atlas-result-summary',
  templateUrl: './result-summary.component.html',
  styleUrls: ['./result-summary.component.scss'],
})
export class ResultSummaryComponent implements OnInit, IDialog, OnDestroy {
  @Input() currentSearchQuery?: string;
  @Input() pageType?: string = '';
  currentSearchResultCount: string;
  currentTotalResultCount: string;
  isCanShowResults: boolean = true;
  searchAutocorrect: string = undefined;
  isCanShowPrintIcon: boolean = false;
  currentProviderOnDisplayCountSub: Subscription;
  plainTextSub: Subscription;
  urlMonitorSub: Subscription;
  isShowBreadcrumbs: boolean;
  userAgent: any;
  isTablet: boolean = false;
  isShareOpen: boolean = false;
  clipboardValue: string;
  isCopyLinkShow: boolean = true;
  provDetails: any = null;
  provDetailsSub: Subscription;
  urlListSub: Subscription;
  urlList: NavigationEndEvent[] = [];
  @ViewChild('ahntext') child: AhnTextComponent;
  providerProfileName: any;
  isV2ProviderCard: boolean = environment.kyruusFeatures.resultsPage.isV2ProviderCard;

  constructor(
    private fadSearchService: FadSearchService,
    private fadSearchStateServ: FadSeachStateService,
    private fadProviderDetailServ: FadProviderDetailService,
    private router: Router,
    private filterServ: FilterService,
    private urlMonitorServ: UrlMonitorService,
    public dialog: MatDialog,
    private printPDFServ: PdfServiceService,
    private clipboard: Clipboard,
    private activatedRoute: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: string,
  ) {
    this.fadSearchService = fadSearchService;
    this.fadSearchStateServ = fadSearchStateServ;
    this.fadProviderDetailServ = fadProviderDetailServ;
    this.router = router;
    this.filterServ = filterServ;
    this.urlMonitorServ = urlMonitorServ;
    this.dialog = dialog;
    this.printPDFServ = printPDFServ;
    this.clipboard = clipboard;
    this.activatedRoute = activatedRoute;
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.userAgent = navigator.userAgent.toLowerCase();
      this.clipboardValue = window.location.href;
    }

    this.isCanShowPrintIcon = this.canShowPrintIcon();

    if (this.isV2ProviderCard === false) {
      this.fadSearchService.searchSubj.subscribe((result: FadSearchResult) => {
        const computedTotalResultCount: number =
          result.payload.startIndex + result.payload.requestResultCount;
        //if cloud-search expands the query on the last page, compute the corrected result count to display
        if (computedTotalResultCount > Number(this.currentTotalResultCount)) {
          //this.currentTotalResultCount = computedTotalResultCount.toString();
          this.currentTotalResultCount = result.payload.totalResultCount.toString();
        } else {
          this.currentTotalResultCount = result.payload.totalResultCount.toString();
        }
        this.searchAutocorrect = result.payload.suggestedQuery;

        if (this.searchAutocorrect !== null && this.searchAutocorrect !== undefined) {
          this.searchAutocorrect = this.splitAutocorrectedText(result.payload.suggestedQuery);
        }
        this.currentSearchResultCount = result.payload.requestResultCount.toString();
      });

      this.plainTextSub = this.filterServ.plainSearchTextSubj.subscribe((text) => {
        this.currentSearchQuery = text;
      });

      if (!this.urlListSub) {
        this.subToUrlList();
      }
    }

    let previousPageUrl: string;

    this.urlMonitorSub = this.urlMonitorServ.isUserUsingSearchResultsSubj.subscribe(
      (isUsingSearchResultsPage) => {
        if (this.urlList[this.urlList.length - 2]) {
          previousPageUrl = this.urlList[this.urlList.length - 2].url;
        } else {
          previousPageUrl = '/';
        }

        if (isUsingSearchResultsPage && this.pageType !== ahnTextPath.results) {
          this.isShowBreadcrumbs = true;
        } else {
          this.isShowBreadcrumbs = false;
        }
      },
    );

    // this.subToCurrentProviderOnDisplayCount();
    this.subToProviderDetails();
    //this.isTablet = this.evaluateIsTablet();
  }

  evaluateIsTablet() {
    return /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
      this.userAgent,
    );
  }

  splitAutocorrectedText(input: string) {
    return input.split('AND')[0].trim();
  }

  subToProviderDetails() {
    this.provDetailsSub = this.fadProviderDetailServ.providerDetailSubj.subscribe((provDet) => {
      this.provDetails = provDet.payload.providerDetail;
    });
  }

  subToUrlList() {
    this.urlListSub = this.urlMonitorServ.urlMonitorSubj.subscribe((urlList) => {
      this.urlList = [];
      urlList.forEach((item) => {
        if (item) {
          const textUrl = item.url;
          const isHashPresentUrl = textUrl.includes('#');
          if (isHashPresentUrl === false) {
            this.urlList.push(item);
          }
        }
      });
    });
  }

  canShowPrintIcon() {
    if (this.pageType === ahnTextPath.results) {
      return false;
    } else {
      return true;
    }
  }

  openInNewTab() {
    this.openDialog();
    this.providerProfileName = this.activatedRoute.snapshot.params['providername'];
    const printReqObj = new PrintRequest(this.providerProfileName);
    const printReq = this.printPDFServ.getPrintRequestJSON(printReqObj);
    this.printPDFServ.getPdf(printReq).subscribe((pdf) => {
      const file = new Blob([pdf], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, '_blank', 'width=1000, height=1000');
      this.closeDialog();
    });
  }

  openDialog() {
    this.dialog.open(SpinnerComponent);
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  ngOnDestroy(): void {
    if (this.urlListSub) {
      this.urlListSub.unsubscribe();
    }
  }
}
