<div class="provider-expertise-container">
  <div id="provider-expertises" class="ahn-expertise">
    <h3>Expertise</h3>
    <div class="expertise-container">
      <div class="expertise-list" *ngFor="let pe of this.providerExpertise">
        <span>{{ pe.name }}</span>
      </div>
    </div>
  </div>
</div>
