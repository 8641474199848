<div
  *ngIf="this.reviews.length > 0"
  class="provider-ratings-container-new star-rating-container-new"
>
  <h3>{{ 'Patient Satisfaction Reviews (' + this.reviewCount + ')' }}</h3>
  <p>
    Survey questions address the doctor’s compassion, communication style, and the likelihood the
    patient would recommend the doctor. Responses are measured on a scale of 1 to 5 with 5 being the
    best score.
  </p>
  <div class="h3-wrapper">
    <h3 class="left-h3">Overall</h3>
    <h3 class="right-h3">{{ averageRating + ' out of 5' }}</h3>
  </div>
  <hr />
  <div *ngIf="subRatings.length > 0">
    <div *ngFor="let subRating of subRatings">
      <div class="mobile-columns">
        <p class="roboto-regular">{{ subRating.metric }}</p>
        <div class="rating-value-container">
          <span class="material-icons-outlined">{{
            subRating.average_rating | starRating: subRating.average_rating
          }}</span>
          <span class="rating-value">{{ subRating.average_rating }}</span>
        </div>
      </div>
      <hr />
    </div>
    <p class="subtext">
      At Allegheny Health Network, we partner with Press Ganey to collect patient feedback. Doctors
      who see patients in an office or clinic and have a reliable number of completed patient
      surveys will have reviews included on their profile.
      <a href="https://www.ahn.org/about/about-reviews.html" target="_blank"
        >Learn more about patient satisfaction.</a
      >
    </p>
    <hr />
  </div>

  <h3>Patient Comments ({{ reviewCount }})</h3>
  <div></div>
  <br />
  <div *ngFor="let review of reviews">
    <p class="bump-up roboto-bold">{{ formatDate(review.review_date) }}</p>
    <p class="roboto-regular">{{ review.body }}</p>
    <hr />
    <br />
  </div>

  <button
    *ngIf="showMoreReviews"
    mat-button
    class="view-comments-button"
    (click)="showMore()"
    (keydown.enter)="showMore()"
    aria-label="View All Comments"
    tabindex="0"
  >
    <div class="view-comments-button-text">Show More Comments</div>
  </button>
</div>
