<div class="provider-language-v2">
  <div class="provider-language-container">
    <div class="provider-language">
      <h3>Languages Spoken*</h3>
      <div class="language-container">
        <div class="language-list" *ngFor="let lang of this.spokenLanguages">
          <span>{{ lang }}</span>
        </div>
      </div>
      <p>
        *Our providers self-report what languages they speak. All AHN practices have interpretive
        services available.
      </p>
    </div>
  </div>
</div>
