import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { AcceptedInsurance } from './acceptedInsurance';

@Component({
  selector: 'engen-accepted-insurance-v2',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './accepted-insurance-v2.component.html',
  styleUrl: './accepted-insurance-v2.component.scss',
})
export class AcceptedInsuranceV2Component {
  @Input() providerAcceptedInsurance: AcceptedInsurance[] = [];
}
