<div
  *ngIf="providerPracticeBuildings"
  [ngClass]="
    isNewproviderProfileExperience
      ? 'provider-locations-container-new'
      : 'provider-locations-container'
  "
>
  <h3>Locations</h3>
  <div class="provider-contact-container">
    <div [ngClass]="isNewproviderProfileExperience ? 'provider-contact-new' : 'provider-contact'">
      <div
        class="provider-each-flex"
        *ngFor="let pracBuilding of providerPracticeBuildings; index as i"
      >
        <div class="provider-inner-card">
          <div class="provider-practice-name roboto-bold">
            <a
              *ngIf="isNewproviderProfileExperience"
              class="practice-name-link"
              [href]="getEncodedUrl(pracBuilding.practiceName)"
              >{{ i + 1 + '. ' + pracBuilding.practiceName }}</a
            >
            <a
              *ngIf="!isNewproviderProfileExperience"
              class="practice-name-link"
              [href]="getEncodedUrl(pracBuilding.practiceName)"
              >{{ pracBuilding.practiceName }}</a
            >
          </div>
          <div
            class="provider-practice-name"
            *ngIf="pracBuilding.primaryPractice === 'true' && pracBuilding.locationName"
          >
            {{ pracBuilding.locationName }} (Primary)
          </div>
          <div class="provider-practice-name" *ngIf="pracBuilding.sublocationName">
            {{ pracBuilding.sublocationName }}
          </div>
          <div
            class="provider-practice-name"
            *ngIf="pracBuilding.primaryPractice === 'false' && pracBuilding.locationName"
          >
            {{ pracBuilding.locationName }}
          </div>
          <div class="provider-practice-name">{{ pracBuilding.buildingAddress }}</div>
          <div class="provider-practice-name">
            {{ pracBuilding.buildingCity }}, {{ pracBuilding.buildingState }}
            {{ pracBuilding.buildingZip }}
          </div>
          <div class="provider-directions">
            <a [href]="pracBuilding.directions" target="_blank" class="roboto-semi-bold"
              >DIRECTIONS</a
            >
          </div>
          <div class="provider-phone">
            <a href="tel:{{ pracBuilding.phone }}">{{ pracBuilding.phone }}</a>
          </div>
          <div class="provider-fax" *ngIf="pracBuilding.fax">Fax {{ pracBuilding.fax }}</div>
        </div>
        <hr *ngIf="isNewproviderProfileExperience" />
      </div>
    </div>
  </div>
</div>
