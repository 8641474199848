<div class="provider-credentials-container">
  <div class="ahn-credentials">
    <h3>Credentials</h3>
    <div class="credentials-info">
      <div class="cred-section" *ngIf="fellowshipsCount > 0">
        <div class="credential-wrapper">
          <p class="credentials-title">Fellowship</p>
          <div class="credential-container">
            <li *ngFor="let credential of fellowships" class="credential-list">
              {{ credential.name }}
            </li>
          </div>
        </div>
      </div>
      <div class="cred-section" *ngIf="resCount > 0">
        <div class="credential-wrapper">
          <p class="credentials-title">Residency</p>
          <div class="credential-container">
            <li *ngFor="let credential of residencies" class="credential-list">
              {{ credential.name }}
            </li>
          </div>
        </div>
      </div>
      <div class="cred-section" *ngIf="internCount > 0">
        <div class="credential-wrapper">
          <p class="credentials-title">Internship</p>
          <div class="credential-container">
            <li *ngFor="let credential of internships" class="credential-list">
              {{ credential.name }}
            </li>
          </div>
        </div>
      </div>
      <div class="cred-section" *ngIf="schoolsCount > 0">
        <div class="credential-wrapper">
          <p class="credentials-title">Medical School</p>
          <div class="credential-container">
            <li *ngFor="let credential of educations" class="credential-list">
              {{ credential.name }}
            </li>
          </div>
        </div>
      </div>
      <div class="cred-section" *ngIf="boardsCount > 0">
        <div class="credential-wrapper">
          <p class="credentials-title">Board Certification</p>
          <div class="credential-container">
            <li *ngFor="let credential of boardCert" class="credential-list">
              {{ credential.name }} - {{ credential.specialty_name }}
            </li>
          </div>
        </div>
      </div>
      <div class="cred-section" *ngIf="jobsCount > 0">
        <div class="credential-wrapper">
          <p class="credentials-title">Position</p>
          <div class="credential-container">
            <li *ngFor="let credential of positions" class="credential-list">
              {{ credential.name }}
            </li>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
