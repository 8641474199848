export class BoardCerts {
  specialty_name: string;
  board_name: string;
  certification_type: string;
  constructor(certification_type: string, board_name: string, specialty_name: string) {
    this.certification_type = certification_type;
    this.specialty_name = specialty_name;
    this.board_name = board_name;
  }
}
