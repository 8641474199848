/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, of, Subscription } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FadSearchResult } from './fad-search-result';
import { FadSearchRequest } from './fad-search-request';
import { catchError, map, timeout } from 'rxjs/operators';
import { environment } from '../../../../../../../../apps/dmx-fad-ui/src/environments/environment';
import { FadSeachStateService } from './fad-seach-state.service';
import { FadProviderDetailService } from '../fad-provider-detail/fad-provider-detail.service';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export class FadSearchService {
  searchSub = new Subscription();
  endpoint: string = `${environment.searchEndpoint}`;
  locationsEndPoint: string = `${environment.searchEndpointWithLocations}`;
  searchEndPoint: string;
  userQueryAppendedFiltersSub: Subscription;
  userQueryAppendedFilters: string;

  emptyResult: FadSearchResult = {
    header: {
      returnCode: '',
      returnCodeDesc: '',
      ts: '',
      uuid: '',
    },
    payload: {
      response: 'failure',
      hasMoreResults: false,
      startIndex: 0,
      requestResultCount: 0,
      totalResultCount: 0,
      suggestedQuery: null,
      results: [],
      facetResults: null,
    },
  };

  searchSubj = new BehaviorSubject<FadSearchResult | any>(this.emptyResult);

  selectedFilters: any = {
    outpatientenabled: false,
    ahn: false,
    video: false,
    languages: '',
    conditions: '',
    gender: 'Any Gender',
    schedule: false,
    lgbtq: false,
    location: '',
    locationdistance: 0,
    sortorder: 'DEFAULT',
  };

  constructor(
    private httpClient: HttpClient,
    private fadSearchStateServ: FadSeachStateService,
    private providerDetailService: FadProviderDetailService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {}

  extractTitles(userQuery: string) {
    const titles = [
      'Dr ',
      'Dr. ',
      'Doc ',
      'Doctor ',
      'Nurse ',
      'dr ',
      'dr. ',
      'doc ',
      'doctor ',
      'tor ',
      'nurse ',
      '.',
      '. ',
    ];
    let extracted = userQuery.trim();
    titles.forEach((title) => {
      if (extracted.startsWith(title) || extracted.endsWith(title)) {
        extracted = extracted.replace(title, '').trim();
      }
    });
    return extracted;
  }

  search(request: FadSearchRequest): void {
    request.searchQuery.userQuery = this.extractTitles(request.searchQuery.userQuery);
    request.searchQuery.userQuery =
      request.searchQuery.userQuery + this.fadSearchStateServ.userQueryTotalAppendedFilters;

    this.searchEndPoint = this.endpoint;
    this.fadSearchStateServ.isShowSpinnerSubj.next(true);
    this.providerDetailService.providerDetailMapView = false;
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('x-api-key', `${environment.xApiKey}`);

    // Start of greater-than-100 logic
    if (request.searchQuery.requestResultCount > 100) {
      const cumulativeResultsPayload = this.createEmptyFadSearchResult();

      const centuries = Math.floor(request.searchQuery.requestResultCount / 100);
      const remainder = request.searchQuery.requestResultCount % 100;

      const cohorts: FadSearchRequest[] = [];

      for (let i = 0; i <= centuries; i++) {
        if (i === centuries) {
          cohorts.push(this.generateRequestIteration(request, i * 100, remainder));
        } else if (i === 0) {
          cohorts.push(this.generateRequestIteration(request, 0, 100));
        } else {
          cohorts.push(this.generateRequestIteration(request, i * 100, 100));
        }
      }
      const observables: any[] = [];
      for (let i = 0; i < cohorts.length; i++) {
        observables.push(
          this.httpClient.post<FadSearchResult>(this.searchEndPoint, cohorts[i], { headers }),
        );
      }

      const fj: Observable<FadSearchResult[]> = forkJoin(observables as FadSearchResult[]);

      fj.subscribe({
        next: (res) => {
          for (let i = 0; i < res.length; i++) {
            if (
              res[i].payload.results.length > 0 &&
              cohorts[i].searchQuery.requestResultCount > 0
            ) {
              res[i].payload.results.forEach((singleProvider) => {
                cumulativeResultsPayload.payload.results.push(singleProvider);
              });
            }
          }

          cumulativeResultsPayload.header.returnCode = res[res.length - 1].header.returnCode;
          cumulativeResultsPayload.header.returnCodeDesc =
            res[res.length - 1].header.returnCodeDesc;
          cumulativeResultsPayload.header.ts = res[res.length - 1].header.ts;
          cumulativeResultsPayload.header.uuid = res[res.length - 1].header.uuid;

          cumulativeResultsPayload.payload.response = res[res.length - 1].payload.response;
          cumulativeResultsPayload.payload.hasMoreResults =
            res[res.length - 1].payload.hasMoreResults;
          cumulativeResultsPayload.payload.requestResultCount =
            res[res.length - 1].payload.requestResultCount;
          cumulativeResultsPayload.payload.startIndex = 0;
          cumulativeResultsPayload.payload.facetResults = res[res.length - 1].payload.facetResults;
          cumulativeResultsPayload.payload.totalResultCount =
            res[res.length - 1].payload.totalResultCount;
          cumulativeResultsPayload.payload.suggestedQuery =
            res[res.length - 1].payload.suggestedQuery;
        },
        complete: () => {
          this.fadSearchStateServ.isShowSpinnerSubj.next(false);
          this.searchSubj.next(cumulativeResultsPayload);
        },
      });
    }
    // Start of less-than-100 results logic
    else {
      this.httpClient
        .post<FadSearchResult>(this.searchEndPoint, request, {
          headers,
        })
        .pipe(
          timeout(10000),
          catchError((err: any) => {
            this.searchSubj.next(this.emptyResult);
            this.fadSearchStateServ.isShowSpinnerSubj.next(false);
            return of(null);
          }),
          map((resp) => {
            if (
              request.searchQuery.userQuery == '/' ||
              !this.validateGoodInput(request.searchQuery.userQuery)
            ) {
              this.searchSubj.next(this.emptyResult);
            } else {
              if (resp !== null) {
                this.searchSubj.next(resp);
              }
            }
          }),
        )
        .subscribe(() => {
          this.fadSearchStateServ.isShowSpinnerSubj.next(false);
        });
    } //end of search method block
  }

  generateRequestIteration(request: FadSearchRequest, startIndex: number, resultsCount: number) {
    const newReq: FadSearchRequest = {
      searchQuery: {
        userQuery: request.searchQuery.userQuery,
        startIndex: startIndex,
        requestResultCount: resultsCount,
        requestLocation: request.searchQuery.requestLocation,
        filterDistance: request.searchQuery.filterDistance,
        sortOrder: request.searchQuery.sortOrder,
      },
    };
    return newReq;
  }

  validateGoodInput(input: string) {
    const invalidArray = new Set();
    const inputLength = input.length > 3 ? 4 : input.length;
    if (inputLength > 3) {
      for (let i = 0; i < inputLength; i++) {
        invalidArray.add(input[i].toLowerCase());
      }
      if (invalidArray.size == 1) {
        return false;
      }
    }
    return true;
  }

  createEmptyFadSearchResult() {
    return {
      header: {
        returnCode: '',
        returnCodeDesc: '',
        ts: '',
        uuid: '',
      },
      payload: {
        response: 'failure',
        hasMoreResults: false,
        startIndex: 0,
        requestResultCount: 0,
        totalResultCount: 0,
        suggestedQuery: null,
        results: [],
        facetResults: null,
      },
    };
  }
}
