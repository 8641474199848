<!-- new experience -->
<div *ngIf="isNewProviderDetailExperience">
  <div *ngIf="pubMedLinkUrl" class="provider-publications-container-new">
    <div class="ahn-provider-publication">
      <h3 class="sofia-pro-semi-bold" target="_blank">Publications</h3>
      <div class="publication-info roboto-regular">
        This search is powered by PubMed, a service of the U.S. National Library of Medicine. PubMed
        is a third-party website with no affiliation with AHN. Please note that modifying search
        criteria and/or author’s name may enhance search results.
        <a [href]="pubMedLinkUrl"
          >View Publications<span class="material-icons-outlined">open_in_new</span></a
        >
      </div>
    </div>
  </div>
</div>
<!-- old experience -->
<div *ngIf="!isNewProviderDetailExperience">
  <div *ngIf="pubMedLinkUrl" class="provider-publications-container">
    <div class="ahn-provider-publication">
      <a [href]="pubMedLinkUrl" class="sofia-pro-semi-bold" target="_blank"
        >Search Publications<span class="padding-alignment material-icons-outlined">
          open_in_new
        </span></a
      >
      <div class="publication-info roboto-regular">
        This search is powered by PubMed, a service of the U.S. National Library of Medicine. PubMed
        is a third-party website with no affiliation with AHN. Please note that modifying search
        criteria and/or author’s name may enhance search results.
      </div>
    </div>
  </div>
</div>
