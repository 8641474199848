<div
  class="remove-provider"
  tabindex="0"
  (click)="removeProviderCompare(profileName)"
  (keydown.enter)="removeProviderCompare(profileName)"
>
  <mat-icon>close</mat-icon>
</div>
<div class="name-container">
  <div class="name-section name-image">
    <img width="100px" height="100px" [src]="imageUrl" alt="AHN Provider Logo" />
  </div>
  <div class="name-section name-info">
    <a role="link" [routerLink]="['/', this.profileName]" title="View {{ displayName }}'s Profile">
      <span (keydown.enter)="returnToProviderProfile(profileName)" class="prov-name">{{
        displayName
      }}</span>
    </a>
    <span class="prov-spec">{{ signature }}</span>
    <atlas-provider-star-rating
      [ratingCount]="ratingCount"
      [ratingValue]="ratingValue"
      [profileName]="this.profileName"
    >
    </atlas-provider-star-rating>
  </div>
</div>
