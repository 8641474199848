import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Expertise } from './expertise';

@Component({
  selector: 'engen-expertise-v2',
  standalone: true,
  imports: [CommonModule],
  exportAs: 'ExpertiseV2Component',
  templateUrl: './expertise-v2.component.html',
  styleUrl: './expertise-v2.component.scss',
})
export class ExpertiseV2Component {
  @Input() providerExpertise: Expertise[] = [];
}
