<!--/* Desktop and Tablet */-->
<div *ngIf="isNewProviderDetailExperience" class="provider-detail-page-new provider-detail-desktop">
  <div class="provider-desktop-view" #mainContent tabindex="0">
    <div class="profile-container">
      <div
        [ngClass]="isSquare ? 'provider-square' : 'provider-regular'"
        class="provider-profile-image"
      >
        <div class="provider-image">
          <img
            [ngClass]="noProviderImage ? 'desktop-image-no-provider' : ''"
            class="desktop-image"
            [src]="roundImageUrl"
            alt="AHN Provider Logo"
          />
        </div>
      </div>
      <div class="provider-profile-info">
        <div class="provider-profile-info-right-container">
          <div class="provider-information">
            <div *ngIf="employed" class="profile-bcg-text">
              <img
                src="../../../../../assets/small_ahn_logo.svg"
                height="17px"
                width="18px"
                alt="AHN Provider"
              />
              <div class="ahn-provider-text">AHN Provider</div>
            </div>
            <div class="provider-display-name sofia-pro-semi-bold">
              <h2>{{ displayName }}</h2>
            </div>
            <div class="provider-text roboto-regular provider-specialty">
              {{ signatureSpecialty }}
            </div>
            <div *ngIf="this.outPatientEnabled === 'true'">
              <div class="provider-phone-call">
                <a href="tel:{{ this.phoneNumber }}" title="{{ this.phoneNumber }}">{{
                  this.phoneNumber
                }}</a>
              </div>
            </div>

            <span class="provider-ratings" *ngIf="ratingValue !== '0'">
              <span class="provider-ratings-value">{{ ratingValue }}</span>
              <span class="provider-ratings-value material-icons-outlined">
                {{ ratingValue | starRating: ratingValue }}
              </span>
              <span
                (click)="isCommentsClick(true)"
                class="provider-ratings-value provider-ratings-count"
                >{{ ' (' + ratingCount + ')' }}</span
              >
            </span>

            <span class="provider-ratings" *ngIf="commentCount !== 0">
              <span class="comments-spacing"></span>
              <span class="provider-ratings-value">Comments</span>
              <a
                class="provider-ratings-comments"
                (click)="isCommentsClick(true)"
                title="See Comments"
              >
                {{ '(' + commentCount + ')' }}</a
              >
            </span>

            <div *ngIf="this.outPatientEnabled === 'true'">
              <div class="make-appointment-container" *ngIf="schedulable === 'true'">
                <button
                  mat-button
                  class="make-appointment-button"
                  (click)="onMakeAppointment()"
                  (keydown.enter)="onMakeAppointment()"
                  [disableRipple]="true"
                  title="Make an Appointment"
                >
                  MAKE AN APPOINTMENT
                </button>
              </div>
            </div>
            <div *ngIf="this.outPatientEnabled === 'false'">
              <atlas-no-scheduling-text [colorBlue]="false"></atlas-no-scheduling-text>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--/* Mobile */-->
<div *ngIf="isNewProviderDetailExperience" class="provider-detail-page-new provider-detail-mobile">
  <div class="provider-mobile-view" #mainContent tabindex="0">
    <div class="profile-container">
      <div
        [ngClass]="isSquare ? 'provider-square' : 'provider-regular'"
        class="provider-profile-image"
      >
        <div class="provider-image image-no-provider">
          <img class="mobile-image" [src]="imageUrl" alt="AHN Provider Mobile Logo" />
        </div>
        <div *ngIf="employed" class="profile-bcg-text">
          <img
            src="../../../../../assets/small_ahn_logo.svg"
            height="17px"
            width="18px"
            alt="AHN Provider"
          />
          <div class="ahn-provider-text">AHN Provider</div>
        </div>
      </div>
      <div class="provider-profile-info">
        <div class="provider-profile-info-right-container">
          <div class="provider-information">
            <div *ngIf="employed" class="profile-bcg-text">
              <img
                src="../../../../../assets/small_ahn_logo.svg"
                height="17px"
                width="18px"
                alt="AHN Provider"
              />
              <div class="ahn-provider-text">AHN Provider</div>
            </div>
            <div class="provider-display-name sofia-pro-semi-bold">
              <h2>{{ displayName }}</h2>
            </div>
            <div class="provider-text roboto-regular provider-specialty">
              {{ signatureSpecialty }}
            </div>
            <div *ngIf="this.outPatientEnabled === 'true'" class="provider-phone-call">
              <a href="tel:{{ this.phoneNumber }}" title="{{ this.phoneNumber }}">{{
                this.phoneNumber
              }}</a>
            </div>

            <span class="provider-ratings" *ngIf="ratingValue !== '0'">
              <span class="provider-ratings-value">{{ ratingValue }}</span>
              <span class="provider-ratings-value material-icons-outlined">
                {{ ratingValue | starRating: ratingValue }}
              </span>
              <span
                (click)="isCommentsClick(true)"
                class="provider-ratings-value provider-ratings-count"
                >{{ ' (' + ratingCount + ')' }}</span
              >
            </span>

            <span class="provider-ratings" *ngIf="ratingCount !== '0'">
              <span class="comments-spacing"></span>
              <span class="provider-ratings-value">Comments</span>
              <a
                class="provider-ratings-comments"
                (click)="isCommentsClick(true)"
                title="See Comments"
              >
                {{ '(' + commentCount + ')' }}</a
              >
            </span>
          </div>
          <!--/* Mobile Only */-->
          <div class="make-appointment-container" *ngIf="this.outPatientEnabled === 'true'">
            <div *ngIf="schedulable === 'true'">
              <button
                mat-button
                class="make-appointment-button"
                (click)="onMakeAppointment()"
                (keydown.enter)="onMakeAppointment()"
                [disableRipple]="true"
              >
                MAKE AN APPOINTMENT
              </button>
            </div>
            <div *ngIf="this.outPatientEnabled === 'false'">
              <atlas-no-scheduling-text [colorBlue]="true"></atlas-no-scheduling-text>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
