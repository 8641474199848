import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchComponent } from './search.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { ReactiveFormsModule } from '@angular/forms';
import { MatListModule } from '@angular/material/list';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { TypeheadCategoryComponent } from '../typehead-category/typehead-category.component';
import { TypeaheadSingleComponent } from '../typeahead-single/typeahead-single.component';

@NgModule({
  declarations: [SearchComponent],
  schemas: [NO_ERRORS_SCHEMA],
  exports: [SearchComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatListModule,
    MatAutocompleteModule,
    MatIconModule,
    MatTooltipModule,
    RouterModule,
    TypeheadCategoryComponent,
    TypeaheadSingleComponent,
    TypeheadCategoryComponent,
  ],
})
export class SearchModule {}
