<div class="provider-locations-container">
  <h3>Locations</h3>
  <div class="provider-contact-container">
    <div
      class="provider-each-flex"
      *ngFor="let location of this.providerKyruusLocations; index as i"
    >
      <div class="provider-inner-card">
        <div class="provider-practice-name roboto-bold">
          <a class="practice-name-link" [href]="getEncodedUrl(location.name)">
            {{ i + 1 + '. ' + location.name }}</a
          >
        </div>

        <div class="provider-practice-name">{{ location.street1 }}</div>
        <div class="provider-practice-name">
          {{ location.city }}, {{ location.state }} {{ location.zip }}
        </div>
        <div class="provider-directions">
          <a [href]="location.url" target="_blank" class="roboto-semi-bold">DIRECTIONS</a>
        </div>
        <div class="provider-phone">
          <a href="tel:{{ location.phone }}">{{ location.phone }}</a>
        </div>
        <div class="provider-fax" *ngIf="location.fax">Fax {{ location.fax }}</div>
      </div>
      <hr />
    </div>
  </div>
</div>
