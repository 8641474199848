<!-- feature flag for New provider profile page -->
<div *ngIf="isNewproviderProfileExperience" class="provider-detail-container">
  <div class="provider-detail-new-experience">
    <div class="provider-margin provider-top">
      <section class="provider-detail-profile">
        <div *ngIf="this.isV2ProviderBanner">
          <engen-profile-banner-v2
            [providerFullName]="this.providerFullName"
            [providerSpecialty]="this.providerSpecialty"
            [providerPhoneNumber]="this.providerPhoneNumber"
          ></engen-profile-banner-v2>
        </div>
        <div *ngIf="!this.isV2ProviderBanner">
          <atlas-provider-profile
            [providerProfileDetails]="this.providerDetails"
            [providerPracticeBuildings]="this.providerLocations"
          ></atlas-provider-profile>
        </div>
      </section>
      <div class="new-material-tab-experience">
        <section id="tabnav">
          <mat-tab-group [(selectedIndex)]="selectedTabindex" animationDuration="0ms">
            <mat-tab label="About">
              <div *ngIf="this.isV2AboutTab">
                <engen-profile-about-tab-v2
                  [aboutProvider]="this.aboutProvider"
                ></engen-profile-about-tab-v2>
                <engen-age-groups-scene-v2 [ageGroups]="this.ageGroups"></engen-age-groups-scene-v2>
                <engen-languages-scene-v2
                  [spokenLanguages]="this.spokenLanguages"
                ></engen-languages-scene-v2>
                <engen-expertise-v2
                  [providerExpertise]="this.providerExpertise"
                ></engen-expertise-v2>
                <engen-provider-credentials-v2
                  [providerCredentials]="this.providerCredentials"
                ></engen-provider-credentials-v2>
                <engen-accepted-insurance-v2
                  [providerAcceptedInsurance]="this.providerAcceptedInsurance"
                ></engen-accepted-insurance-v2>
                <engen-provider-publications-v2
                  [providerPublications]="this.providerPublications"
                ></engen-provider-publications-v2>
              </div>
              <div *ngIf="!this.isV2AboutTab">
                <atlas-provider-bio
                  *ngIf="checkProviderBio"
                  [bio]="this.bio"
                  [youTubeVideoBioUrl]="this.youTubeVideoBioUrl"
                  [providerLanguageResults]="this.providerLanguageResults"
                  [displayName]="this.displayName"
                  [videoFilterEnabled]="this.videoFilterEnabled"
                ></atlas-provider-bio>
                <atlas-provider-expertise
                  [providerExpertiseResults]="this.providerExpertiseResults"
                ></atlas-provider-expertise>
                <atlas-provider-credentials
                  [schoolsResult]="this.schools"
                  [residenciesResult]="this.residencies"
                  [jobTitlesResult]="this.jobTitles"
                  [boardsResult]="this.boards"
                  [fellowshipsResult]="fellowships"
                ></atlas-provider-credentials>
                <atlas-provider-lgbt *ngIf="lgbtqAligned"></atlas-provider-lgbt>
                <atlas-provider-publications
                  [pubMedLinkUrl]="this.pubMedLinkUrl"
                ></atlas-provider-publications>
                <atlas-provider-videodisclaimer
                  *ngIf="youTubeVideoBioUrl && videoFilterEnabled"
                ></atlas-provider-videodisclaimer>
              </div>
            </mat-tab>

            <mat-tab label="Location">
              <div *ngIf="this.isV2LocationTab">
                <section class="provider-detail-map">
                  <atlas-provider-map
                    *ngIf="isBrowser()"
                    [mapHeight]="this.mapHeight"
                    [providerKyruusLocations]="this.providerKyruusLocations"
                  ></atlas-provider-map>
                </section>

                <engen-profile-locations-tab-v2
                  [providerKyruusLocations]="this.providerKyruusLocations"
                >
                </engen-profile-locations-tab-v2>
              </div>
              <div *ngIf="!this.isV2LocationTab">
                <section class="provider-detail-map">
                  <atlas-provider-map
                    *ngIf="isBrowser()"
                    [mapHeight]="this.mapHeight"
                    [providerResult]="this.providerLocations"
                  ></atlas-provider-map>
                  <atlas-provider-locations
                    class="provider-locations"
                    [providerPracticeBuildings]="this.providerLocations"
                  ></atlas-provider-locations>
                </section>
              </div>
            </mat-tab>
            <div *ngIf="this.showReview">
              <mat-tab label="Reviews">
                <div *ngIf="this.isV2ReviewsTab">
                  <engen-profile-reviews-tab-v2 [providerDetails]="providerDetails_V2">
                  </engen-profile-reviews-tab-v2>
                </div>
                <div *ngIf="!this.isV2ReviewsTab">
                  <atlas-provider-ratings-comments
                    class="provider-reviews"
                    [providerDetails]="providerDetails"
                    [providerName]="this.providerName"
                  ></atlas-provider-ratings-comments>
                </div>
              </mat-tab>
            </div>
            <div *ngIf="this.showReferral">
              <mat-tab label="Refer Your Patient">
                <div *ngIf="this.isV2ReferralTab" class="new-provider-detail-referral">
                  <engen-profile-referral-tab-v2
                    *ngIf="this.referralsOn"
                    [providerLocations]="this.providerPracticeBuildings"
                    [provider]="this.providerDetails"
                  >
                  </engen-profile-referral-tab-v2>
                </div>
                <div *ngIf="!this.isV2ReferralTab">
                  <section *ngIf="hasData" class="new-provider-detail-referral-container">
                    <div *ngIf="this.showReferral" class="new-provider-detail-referral">
                      <atlas-referral-button
                        *ngIf="this.referralsOn"
                        [providerLocations]="this.providerPracticeBuildings"
                        [provider]="this.providerDetails"
                      ></atlas-referral-button>
                    </div>
                  </section>
                </div>
              </mat-tab>
            </div>
          </mat-tab-group>
        </section>
      </div>
    </div>
  </div>
</div>

<!-- feature flag for old provider profile page.
 Remove this code once once we go live with new version. -->
<div *ngIf="!isNewproviderProfileExperience" class="provider-detail-container">
  <div class="provider-detail-old-experience">
    <div class="provider-margin provider-top">
      <section class="provider-detail-profile">
        <atlas-provider-profile
          [providerProfileDetails]="this.providerDetails"
          [providerPracticeBuildings]="this.providerLocations"
        ></atlas-provider-profile>
      </section>
      <div class="provider-top-right">
        <section class="provider-detail-map">
          <atlas-provider-map
            *ngIf="isBrowser()"
            [mapHeight]="this.mapHeight"
            [providerResult]="this.providerLocations"
          ></atlas-provider-map>
        </section>
        <section *ngIf="hasData" class="provider-detail-referral">
          <div *ngIf="this.showReferral">
            <atlas-referral-button
              *ngIf="this.referralsOn"
              [providerLocations]="this.providerPracticeBuildings"
              [provider]="this.providerDetails"
            ></atlas-referral-button>
          </div>
        </section>
      </div>
    </div>
    <div class="provider-margin">
      <div *ngIf="hasData" class="provider-detail-info">
        <atlas-provider-locations
          [providerPracticeBuildings]="this.providerLocations"
        ></atlas-provider-locations>
        <atlas-provider-bio
          *ngIf="checkProviderBio"
          [bio]="this.bio"
          [youTubeVideoBioUrl]="this.youTubeVideoBioUrl"
          [providerLanguageResults]="this.providerLanguageResults"
          [displayName]="this.displayName"
          [videoFilterEnabled]="this.videoFilterEnabled"
        ></atlas-provider-bio>
        <atlas-provider-expertise
          [providerExpertiseResults]="this.providerExpertiseResults"
        ></atlas-provider-expertise>
        <atlas-provider-ratings-comments
          [providerDetails]="providerDetails"
          [providerName]="this.providerName"
        ></atlas-provider-ratings-comments>
        <atlas-provider-credentials
          [schoolsResult]="this.schools"
          [residenciesResult]="this.residencies"
          [jobTitlesResult]="this.jobTitles"
          [boardsResult]="this.boards"
          [fellowshipsResult]="fellowships"
        ></atlas-provider-credentials>
        <atlas-provider-lgbt *ngIf="lgbtqAligned"></atlas-provider-lgbt>
        <atlas-provider-publications
          [pubMedLinkUrl]="this.pubMedLinkUrl"
        ></atlas-provider-publications>
        <atlas-provider-videodisclaimer
          *ngIf="youTubeVideoBioUrl && videoFilterEnabled"
        ></atlas-provider-videodisclaimer>
      </div>
    </div>
  </div>
</div>
