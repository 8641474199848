import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'engen-profile-about-tab-v2',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './profile-about-tab-v2.component.html',
  styleUrl: './profile-about-tab-v2.component.scss',
})
export class ProfileAboutTabV2Component {
  @Input() aboutProvider: string = '';
}
