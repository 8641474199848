/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import {
  FadSeachStateService,
  FadSearchRequest,
  FadSuggestService,
  FadSearchService,
  FadSearchSortOrder,
} from '@dmx-fad-ui/fad/data-access';
import { FadSuggestResponse } from 'libs/fad/data-access/src/lib/services/api/fad-suggest/fad-suggest-response';
import { SuggestResult } from '../../models/suggestResult/suggestResult';
import { Subscription } from 'rxjs';
import {
  WindowSizeService,
  SearchMonitorService,
  LocationService,
  FilterService,
  SimpleUtilsService,
  ClearFilterService,
  StyleObservablesService,
  TabableService,
  UrlMonitorService,
} from '@dmx-fad-ui/fad/services';
import { debounceTime } from 'rxjs/operators';
import { NavigationEndEvent } from '../../models/navigationEndEvent';
import { ahnTextPath } from '../ahn-text/ahn-text.enum';
import { environment } from '../../../../environments/environment';
import { mockTypeaheadCan } from '../../../../assets/mockdata/typeahead_can_kyruus';

@Component({
  selector: 'atlas-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit, OnDestroy {
  @Input() shouldAutoFocus: boolean = true;
  @Input() isLandingPage?: boolean = true;
  @Input() isProviderDetailPage?: boolean = false;
  @Input() isSearchResultsPage?: boolean = false;
  @Input() isLocationDetailPage?: boolean = false;
  @Input() existingSearchText?: string = '';
  @ViewChild('searchInput') private _inputElement: ElementRef;
  searchFormGroup: UntypedFormGroup;
  suggestResult: FadSuggestResponse;
  typeaheadResultV2: any;
  suggestions: SuggestResult;
  pickers: string[] = [];
  suggestionSub: Subscription;
  placeholderText: string = 'Specialty, location, name...';
  placeholder: string = this.placeholderText;
  panelWidth: string = '';
  suggestResultTypes = [];
  typeaheadResponseTypes = [];
  selectedSuggestType = '';
  suggestedResults = [];
  typeaheadResults = [];
  genderFilterSub: Subscription;
  genderSelectionString: string = '';
  locationProviderFilterSub: Subscription;
  locationProviderSelectionString: string = '';
  ahnSelectionString: string = '';
  videoBioSub: Subscription;
  onlineSchedulingSub: Subscription;
  makeAppointmentSub: Subscription;
  makeAppointmentSelectionString: string = '';
  isMakeAppointment: boolean = false;
  videoAppointmentSub: Subscription;
  LGBTQQIP2SAASub: Subscription;
  isLGBTQQIP2SAAAligned: boolean = false;
  isLGBTQQIP2SAAAlignedSelectionString: string = '';
  ahnProviderSub: Subscription;
  isAHNProviderSearch: boolean = false;
  selectedVideoBio: any;
  isVideoBioSearch: boolean = false;
  vidBioSelectionString: string = '';
  selectedOnlineScheduling: any;
  isOnlineScheduling: boolean = false;
  onlineSchedulingSelectionString: string = '';
  selectedVideoAppointment: any;
  isVideoAppointment: boolean = false;
  videoAppointmentSelectionString: string = '';
  conditionsFacetSub: Subscription;
  conditionsFilterString: string = '';
  languageFacetSub: Subscription;
  languageFilterString: string = '';
  sortOrderSubscription: Subscription;
  activeSortOrder: string = FadSearchSortOrder.default;
  locationFilterSubscription: Subscription;
  suspendSuggestOnSearch: boolean = false;
  searchBarActiveSub: Subscription;
  isSearchBarActive: boolean = false;
  storedSearchTerm: string = '';
  storedSearchTermSub: Subscription;
  queryParamObj: any = {};
  refocusSub: Subscription = new Subscription();
  urlMonitorSub: Subscription;
  urlArray: NavigationEndEvent[] = [];
  callCount: number = 0;
  isV2typeahead: boolean = environment.kyruusFeatures.isV2typeahead;
  isUsingNewV2TypeaheadEndpoint: boolean = environment.kyruusFeatures.isUsingNewV2TypeaheadEndpoint;

  get searchTermsControl(): UntypedFormControl {
    return <UntypedFormControl>this.searchFormGroup.get(['searchTermsControlName']);
  }

  constructor(
    private router: Router,
    // eslint-disable-next-line no-unused-vars
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private fadSeachStateService: FadSeachStateService,
    private fadSearchService: FadSearchService,
    private fadSuggestService: FadSuggestService,
    private searchMonitorServ: SearchMonitorService,
    private locationService: LocationService,
    private filterServ: FilterService,
    private utilsServ: SimpleUtilsService,
    private clearFilterService: ClearFilterService,
    private windowService: WindowSizeService,
    private styleObservableServ: StyleObservablesService,
    private tabableService: TabableService,
    private urlMonitorService: UrlMonitorService,
  ) {
    this.router = router;
    this.formBuilder = formBuilder;
    this.fadSeachStateService = fadSeachStateService;
    this.fadSearchService = fadSearchService;
    this.fadSuggestService = fadSuggestService;
    this.searchMonitorServ = searchMonitorServ;
    this.locationService = locationService;
    this.filterServ = filterServ;
    this.utilsServ = utilsServ;
    this.clearFilterService = clearFilterService;
    this.windowService = windowService;
    this.styleObservableServ = styleObservableServ;
    this.tabableService = tabableService;
    this.urlMonitorService = urlMonitorService;

    router.events.subscribe((event: NavigationStart) => {
      if (
        event.navigationTrigger === 'popstate' &&
        this.urlArray[this.urlArray.length - 1].url.includes(ahnTextPath.results)
      ) {
        this.callCount++;
        if (this.callCount < 2) {
          this.navigateOnPopState(event.url);
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.refocusSub.unsubscribe();
  }

  ngOnInit(): void {
    // this.subscribeToURLParams(); // This has to be ahead of other subscriptions
    this.suggestionSub = this.fadSuggestService.suggestSubj.subscribe((result) => {
      if (this.isV2typeahead) {
        if (this.isUsingNewV2TypeaheadEndpoint === true) {
          console.log('Subscription content: ');
          console.log(result);
          this.typeaheadResultV2 = result;
          this.resetSuggestedResults();
          this.setSuggestedResultsV2(this.typeaheadResultV2);
          console.log('LIVE TYPEAHEAD DATA:');
          console.log(this.typeaheadResultV2);
        } else {
          this.typeaheadResultV2 = mockTypeaheadCan;
          this.resetSuggestedResults();
          this.setSuggestedResultsV2(this.typeaheadResultV2);
          // console.log('Setting new typeahead:');
          // console.log(this.typeaheadResultV2);
        }
      } else {
        this.suggestResult = result;
        this.resetSuggestedResults();
        this.setSuggetedResults(this.suggestResult);
        // console.log('Setting OLD typeahead:');
        // console.log(this.suggestResult);
      }
    });

    this.refocusSub = this.tabableService.focusMainContent$.subscribe((data) => {
      if (data && this.shouldAutoFocus && !window.location.pathname.includes('search-results')) {
        this.recieveRefocus();
      }
    });

    this.urlMonitorSub = this.urlMonitorService.urlMonitorSubj.subscribe((navEvents) => {
      this.urlArray = [];
      navEvents.forEach((item) => {
        if (item) {
          const textUrl = item.url;
          const isHashPresentUrl = textUrl.includes('#');
          if (isHashPresentUrl === false) {
            this.urlArray.push(item);
          }
        }
      });
    });

    this.subscribeToGenderFilter();
    this.subscribeToLocationProviderFilter();
    this.subscribeToAHNProviders();
    this.subscribeToVideoBio();
    this.subscribeToLGBTQQIP2SAA();
    this.subscribeToOnlineScheduling();
    this.subscribeToVideoAppointment();
    this.subscribeToConditionsFacet();
    this.subscribeToSortOrder();
    this.subscribeToLocationFilter();
    this.subscribeToLanguageSpoken();
    this.subscribeToSearchBarActivity();
    this.subscribeToMakeAppointment();
    this.subscribeToStoredSearchTerm();
    this.route.queryParamMap.subscribe((params) => {
      this.queryParamObj = { ...params.keys, ...params };
    });

    this.setSearchTermsControl();

    this.searchTermsControl.valueChanges.pipe(debounceTime(500)).subscribe(() => {
      this.updateSuggestions();
    });
  }

  setSearchTermsControl() {
    this.searchFormGroup = this.formBuilder.group({});

    if (this.existingSearchText !== '' || this.isLandingPage == true) {
      this.searchFormGroup.addControl(
        'searchTermsControlName',
        this.formBuilder.control(this.existingSearchText, []),
      );
      this.searchMonitorServ.storedSearchEntrySubj.next(this.existingSearchText);
    } else {
      this.searchFormGroup.addControl(
        'searchTermsControlName',
        this.formBuilder.control(this.storedSearchTerm, []),
      );
    }
  }

  subscribeToStoredSearchTerm() {
    this.storedSearchTermSub = this.searchMonitorServ.storedSearchEntrySubj.subscribe((stored) => {
      this.storedSearchTerm = stored;
    });
  }

  subscribeToSearchBarActivity() {
    this.searchBarActiveSub = this.styleObservableServ.searchBarIsActiveSubj.subscribe(
      (isSearchBarBeingUsed) => {
        this.isSearchBarActive = isSearchBarBeingUsed;
      },
    );
  }

  subscribeToConditionsFacet() {
    this.conditionsFacetSub = this.filterServ.conditionFilterSubj.subscribe((conditionModel) => {
      if (conditionModel.length > 0) {
        this.conditionsFilterString = ` AND conditions:${this.replaceSpaceWithHyphen(
          conditionModel,
        )}`;
      } else {
        this.conditionsFilterString = '';
      }
    });
  }

  subscribeToLanguageSpoken() {
    this.languageFacetSub = this.filterServ.lanaguageFilterSubj.subscribe((languageSpoken) => {
      if (languageSpoken.length > 0) {
        this.languageFilterString = ` AND languages:${this.replaceSpaceWithHyphen(languageSpoken)}`;
      } else {
        this.languageFilterString = '';
      }
    });
  }

  replaceSpaceWithHyphen(input: string): string {
    return input.split(' ').join('-');
  }

  subscribeToGenderFilter() {
    this.genderFilterSub = this.filterServ.genderFilterSubj.subscribe((genderFilter) => {
      if (genderFilter === 'Male') {
        this.genderSelectionString = ' AND gender:male';
      } else if (genderFilter === 'Female') {
        this.genderSelectionString = ' AND gender:female';
      } else if (genderFilter === 'Any Gender') {
        this.genderSelectionString = '';
      }
    });
  }

  subscribeToLocationProviderFilter() {
    let locationProvSelString: string = '';
    this.locationProviderFilterSub = this.filterServ.providerLocationFilterSubj.subscribe((fil) => {
      if (fil === 'Locations Only') {
        locationProvSelString = ' AND objectType:practiceLocation';
      } else if (fil === 'Providers Only') {
        locationProvSelString = ' AND objectType:provider';
      } else {
        locationProvSelString = ' ';
      }
      this.fadSeachStateService.userQueryTotalAppendedFilters = locationProvSelString;
    });
  }

  subscribeToAHNProviders() {
    this.ahnProviderSub = this.filterServ.ahnProviderSubj.subscribe((ahn) => {
      this.isAHNProviderSearch = ahn;
      if (this.isAHNProviderSearch) {
        this.ahnSelectionString = ' AND employed:Employed';
      } else {
        this.ahnSelectionString = '';
      }
    });
  }

  subscribeToVideoBio() {
    this.videoBioSub = this.filterServ.videoBioSubj.subscribe((vid) => {
      this.isVideoBioSearch = vid;
      if (this.isVideoBioSearch) {
        this.vidBioSelectionString = ' AND video:true';
      } else {
        this.vidBioSelectionString = '';
      }
    });
  }

  subscribeToLGBTQQIP2SAA() {
    this.LGBTQQIP2SAASub = this.filterServ.LGBTQQIP2SAASubj.subscribe((LGBTQQIP2SAA) => {
      this.isLGBTQQIP2SAAAligned = LGBTQQIP2SAA;
      if (this.isLGBTQQIP2SAAAligned) {
        this.isLGBTQQIP2SAAAlignedSelectionString = ' AND lgbtq:true';
      } else {
        this.isLGBTQQIP2SAAAlignedSelectionString = '';
      }
    });
  }

  subscribeToOnlineScheduling() {
    this.onlineSchedulingSub = this.filterServ.onlineSchedulingSubj.subscribe((online) => {
      this.isOnlineScheduling = online;
      if (this.isOnlineScheduling) {
        this.onlineSchedulingSelectionString = ' AND schedule:true';
      } else {
        this.onlineSchedulingSelectionString = '';
      }
    });
  }

  subscribeToMakeAppointment() {
    this.makeAppointmentSub = this.filterServ.makeAppointmentSubj.subscribe((online) => {
      this.isMakeAppointment = online;
      if (this.isMakeAppointment) {
        this.makeAppointmentSelectionString = ' AND outPatientEnabled:true';
      } else {
        this.makeAppointmentSelectionString = '';
      }
    });
  }

  subscribeToVideoAppointment() {
    this.videoAppointmentSub = this.filterServ.videoAppointmentSubj.subscribe((video) => {
      this.isVideoAppointment = video;
      if (this.isVideoAppointment) {
        this.videoAppointmentSelectionString = ' AND telemedicine:true';
      } else {
        this.videoAppointmentSelectionString = '';
      }
    });
  }

  //re-enable processing of suggest results if a user is typing
  //and didnt key ENTER (submit/search)
  enableSuggestProcessingOnKeydown(event: KeyboardEvent) {
    if (event.key !== 'Enter') {
      this.suspendSuggestOnSearch = false;
    }
  }

  resetSuggestedResults() {
    this.suggestedResults = [];
    this.selectedSuggestType = '';
  }

  setSuggetedResults(suggestResult) {
    if (this.suspendSuggestOnSearch === true) {
      //after a search, don't process remaining
      //suggest api responses, so that we keep the picklist collapsed
      this.resetSuggestedResults();
      return;
    }

    if (suggestResult.payload.results) {
      this.suggestResultTypes = Object.keys(suggestResult.payload.results);
      this.suggestedResults = suggestResult.payload.results;
      // console.log('Types: ');
      // console.log(this.suggestResultTypes);
      // console.log('Results: ');
      // console.log(this.suggestedResults);
    }
  }

  setSuggestedResultsV2(typeaheadResult) {
    if (this.suspendSuggestOnSearch === true) {
      //after a search, don't process remaining
      //suggest api responses, so that we keep the picklist collapsed
      this.resetSuggestedResults();
      return;
    }

    if (typeaheadResult) {
      this.typeaheadResponseTypes = Object.keys(typeaheadResult);
      this.typeaheadResults = typeaheadResult;
      // console.log('Types: ');
      // console.log(this.typeaheadResponseTypes);
      // console.log('Results: ');
      // console.log(this.typeaheadResults);
    }
  }

  initiateSearch() {
    //this prevents a duplicate search call
    if (this.isLandingPage) {
      return;
    } else {
      //this.search();
      return;
    }
  }

  searchCommandFromControl() {
    this.suspendSuggestOnSearch = true;
    this.filterServ.conditionFilterSubj.next('');
    this.filterServ.clearAllFiltersSubj.next(true);
    this.clearFilterService.isCheckConditionFilterEnabled.next(false);
    this.clearFilterService.isAllCheckBoxState.next(false);
    this.filterServ.genderFilterSubj.next('Any Gender');
    this.filterServ.providerLocationFilterSubj.next('All Results');
    this.filterServ.lanaguageFilterSubj.next('');
    this.filterServ.ahnProviderSubj.next(false);
    this.filterServ.LGBTQQIP2SAASubj.next(false);
    this.filterServ.videoAppointmentSubj.next(false);
    this.filterServ.onlineSchedulingSubj.next(false);
    this.filterServ.makeAppointmentSubj.next(false);
    this.filterServ.videoBioSubj.next(false);
    this.clearFilterService.isLanguageSpokenState.next(false);
    this.filterServ.totalResultsCountSubj.next(10);
    this.resetSortOnNewSearch();

    if (this.locationService.searchAddress === '' || this.locationService.searchDistance === 20) {
      this.search();
    } else {
      this.locationDefaultSearch();
    }
  }
  locationDefaultSearch() {
    const searchText = this.getSearchTextFromSuggest(this.searchTermsControl.value);
    const location = this.locationService.searchAddress;
    if (!this.utilsServ.hasNoValue(searchText))
      this.router
        .navigate([`/search-results/${searchText}`], {
          queryParams: {
            location: location,
            locationdistance: '20',
          },
        })
        .then(() => {
          window.location.reload();
        });
  }

  search() {
    this.fadSeachStateService.scrollY = 0;
    this.searchMonitorServ.hasUsedSearchBarIndexSubj.next(true);
    this.fadSeachStateService.currentSearchOrderSubj.next(this.activeSortOrder);
    const searchText = this.getSearchTextFromSuggest(this.searchTermsControl.value);
    if (this.utilsServ.hasNoValue(searchText)) {
      return;
    }
    this.searchMonitorServ.storedSearchEntrySubj.next(searchText);

    this.searchFormGroup.get(['searchTermsControlName']).setValue(searchText);
    const request: FadSearchRequest = {
      searchQuery: {
        userQuery:
          searchText +
          this.genderSelectionString +
          this.locationProviderSelectionString +
          this.ahnSelectionString +
          this.vidBioSelectionString +
          this.isLGBTQQIP2SAAAlignedSelectionString +
          this.conditionsFilterString +
          this.onlineSchedulingSelectionString +
          this.languageFilterString +
          this.videoAppointmentSelectionString +
          this.makeAppointmentSelectionString,
        startIndex: 0,
        requestResultCount: 10,
        requestLocation: this.locationService.searchAddress,
        filterDistance: this.locationService.searchDistance,
        sortOrder: this.activeSortOrder,
      },
    };
    this.fadSeachStateService.request = request;
    this.filterServ.plainSearchTextSubj.next(this.searchTermsControl.value);

    const modifiedSearchText = this.utilsServ.removeBackSlashForSearchResultsUrl(
      this.searchTermsControl.value,
    );

    if (this.isLandingPage === true || this.isProviderDetailPage === true) {
      this.navigateRoutingBasedOnSelectedType(modifiedSearchText);
      this.setNewSearchState();
    } else if (this.isSearchResultsPage === true) {
      this.navigateRoutingBasedOnSelectedType(modifiedSearchText);
      const thisUrl = this.urlArray[this.urlArray.length - 1]
        ? decodeURI(this.urlArray[this.urlArray.length - 1].url)
        : '/';

      if (
        this.selectedSuggestType !== 'providerName' &&
        (!thisUrl.includes('?') ||
          (thisUrl.includes('location=') && !thisUrl.includes('sortorder=')))
      ) {
        this.setNewSearchState();
        this.fadSearchService.search(this.fadSeachStateService.request);
        this.suggestedResults = [];
      }
    }
  }

  //remove bulleted text that is appended to suggest values
  getSearchTextFromSuggest(suggestText: string): string {
    if (suggestText !== undefined) {
      const index = suggestText.indexOf('\u2022');
      if (index !== -1) {
        return suggestText.substring(0, index - 1);
      }
    }
    return suggestText;
  }

  onPickerSelected(name) {
    this.selectedSuggestType = name;
    const clickedValue = this.searchTermsControl.value;
    const searchText = this.getSearchTextFromSuggest(clickedValue);
    const sterilizedSearchText: string = this.utilsServ.sterilizeSearchText(clickedValue);

    if (this.selectedSuggestType === 'providerName') {
      const profileName = clickedValue.split('•')[2].trim();
      const searchBarName = clickedValue.split('•')[0].trim();
      this.searchMonitorServ.storedSearchEntrySubj.next(searchBarName);
      this.router.navigate([`/${profileName}`]);
    } else {
      this.fadSeachStateService.currentIndexSubj.next(0);
      this.windowService.windowScroolPosition.next(0);
      this.fadSeachStateService.checkLoadMoreSubj.next(false);

      const request: FadSearchRequest = {
        searchQuery: {
          userQuery: searchText,
          startIndex: 0,
          requestResultCount: 10,
          requestLocation: this.locationService.searchAddress,
          filterDistance: this.locationService.searchDistance,
          sortOrder: this.activeSortOrder,
        },
      };
      this.fadSeachStateService.request = request;
      this.filterServ.plainSearchTextSubj.next(searchText);
      this.navigateRoutingBasedOnSelectedType(sterilizedSearchText);
      this.setNewSearchState();
      this.searchCommandFromControl();
    }
  }

  onPickerSelectedV2(name) {
    this.selectedSuggestType = name;
    // console.log('search term value:');
    // console.log(name);
    const clickedValue = this.searchTermsControl.value.name;
    // console.log('Search Term Control:');
    // console.log(this.searchTermsControl.value.name);
    const searchText = this.getSearchTextFromSuggest(clickedValue);
    const sterilizedSearchText: string = this.utilsServ.sterilizeSearchText(clickedValue);

    if (this.selectedSuggestType === 'providerName') {
      const profileName = clickedValue.split('•')[2].trim();
      const searchBarName = clickedValue.split('•')[0].trim();
      this.searchMonitorServ.storedSearchEntrySubj.next(searchBarName);
      this.router.navigate([`/${profileName}`]);
    } else {
      this.fadSeachStateService.currentIndexSubj.next(0);
      this.windowService.windowScroolPosition.next(0);
      this.fadSeachStateService.checkLoadMoreSubj.next(false);

      const request: FadSearchRequest = {
        searchQuery: {
          userQuery: searchText,
          startIndex: 0,
          requestResultCount: 10,
          requestLocation: this.locationService.searchAddress,
          filterDistance: this.locationService.searchDistance,
          sortOrder: this.activeSortOrder,
        },
      };
      this.fadSeachStateService.request = request;
      this.filterServ.plainSearchTextSubj.next(searchText);
      this.navigateRoutingBasedOnSelectedType(sterilizedSearchText);
      this.setNewSearchState();
      this.searchCommandFromControl();
    }
  }

  updateSuggestions() {
    const searchText = this.searchTermsControl.value.trim();

    const request = {
      suggestQuery: {
        query: searchText,
      },
    };
    //set previous suggest result to [] if searchbox does not have text
    !searchText && this.resetSuggestedResults();

    //call suggest only if textbox has any text from user && is different from stored search term
    if (searchText !== this.storedSearchTerm) {
      if (this.isUsingNewV2TypeaheadEndpoint === false) {
        searchText && this.fadSuggestService.suggest(request);
      } else {
        searchText && this.fadSuggestService.typeahead_V2(request);
      }
    }
  }

  onFocus() {
    this.placeholder = '';
  }

  onBlur() {
    const presentSearchVal = <UntypedFormControl>(
      this.searchFormGroup.get(['searchTermsControlName'])
    );
    if (presentSearchVal.value.length === 0) {
      this.placeholder = this.placeholderText;
    }
  }

  setNewSearchState() {
    this.fadSeachStateService.currentIndexSubj.next(0);
    this.windowService.windowScroolPosition.next(0);
    this.fadSeachStateService.checkLoadMoreSubj.next(false);
    this.resetSuggestedResults();
    this.fadSeachStateService.currentSearchQuerySubj.next(
      this.fadSeachStateService.request.searchQuery.userQuery,
    );
    this.fadSeachStateService.currentSearchOrderSubj.next(FadSearchSortOrder.default);
  }

  subscribeToSortOrder(): void {
    this.sortOrderSubscription = this.fadSeachStateService.currentSearchOrderSubj.subscribe(
      (sort) => {
        this.activeSortOrder = sort;
      },
    );
  }

  subscribeToLocationFilter(): void {
    this.locationFilterSubscription = this.filterServ.locationFilterSubj.subscribe(() => {});
  }

  resetSortOnNewSearch() {
    this.fadSeachStateService.currentSearchOrderSubj.next(FadSearchSortOrder.default);
    this.activeSortOrder = FadSearchSortOrder.default;
  }

  navigateRoutingBasedOnSelectedType(inputSearchText: string) {
    if (this.selectedSuggestType === 'providerName') {
      const profileName = inputSearchText.split('•')[2].trim();
      if (this.isProviderDetailPage === true) {
        this.router.navigate([`/${profileName}`]).then(() => {
          window.location.reload();
        });
      } else {
        this.router.navigate([`/${profileName}`]);
      }
    } else {
      const url: URL = new URL(window.location.href);
      const params: URLSearchParams = url.searchParams;
      const location: string = params.get('location');
      const locationdistance: string = params.get('locationdistance');
      if (params.toString() != '' && location && locationdistance) {
        this.router.navigate([`/search-results/${inputSearchText}`], {
          queryParams: {
            location: location,
            locationdistance: locationdistance,
          },
        });
      } else {
        this.router.navigate([`/search-results/${inputSearchText}`]);
      }
    }
  }

  highlight(inputText: string, suggestedText: string, type: string) {
    const finalText = [];
    const suggestedArray = suggestedText.split('•');
    let matchedSuggestedText = suggestedArray[0];
    let subText = '';

    if (suggestedArray[1] && type == 'providerName') {
      subText = suggestedArray[1];
    } else if (suggestedArray[1]) {
      subText = suggestedArray[1] + (suggestedArray[2] ? ' • ' + suggestedArray[2] : '');
    }
    if (inputText) {
      const index = matchedSuggestedText.toLowerCase().indexOf(inputText.toLowerCase());
      if (index >= 0) {
        matchedSuggestedText =
          matchedSuggestedText.substring(0, index) +
          '<strong>' +
          matchedSuggestedText.substring(index, index + inputText.length) +
          '</strong>' +
          matchedSuggestedText.substring(index + inputText.length);
      }
    }
    finalText.push(matchedSuggestedText);
    if (subText) {
      finalText.push(subText);
    }
    return finalText;
  }

  highlightV2(inputText: string, picker) {
    // console.log('HIGHLIGHTING: ');
    // console.log(inputText + ' :: ' + picker.name + ' :: ' + picker.type);
    // console.log(this.typeaheadResults['provider_name']);
    const finalText = [];
    const suggestedArray = picker.name;
    const matchedSuggestedText = suggestedArray[0];
    const subText = picker.type;
    if (this.typeaheadResults['conditions_symptoms'] && picker.type == undefined) {
      picker.type = 'Provider';
    }
    if (picker.type == 'term' || picker.type == 'concept') {
      picker.type = 'Condition';
    }

    // console.log('Search Control Name:');
    // console.log(this.searchFormGroup.value.searchTermsControlName);

    // if (suggestedArray[1] && type == 'providerName') {
    //   subText = suggestedArray[1];
    // } else if (suggestedArray[1]) {
    //   subText = suggestedArray[1] + (suggestedArray[2] ? ' • ' + suggestedArray[2] : '');
    // }
    // if (inputText) {
    //   const index = matchedSuggestedText.toLowerCase().indexOf(inputText.toLowerCase());
    //   if (index >= 0) {
    //     matchedSuggestedText =
    //       matchedSuggestedText.substring(0, index) +
    //       '<strong>' +
    //       matchedSuggestedText.substring(index, index + inputText.length) +
    //       '</strong>' +
    //       matchedSuggestedText.substring(index + inputText.length);
    //   }
    // }
    // finalText.push(matchedSuggestedText);
    if (subText) {
      finalText.push(subText);
    }
    return finalText;
  }

  onClearText() {
    this.searchFormGroup.controls['searchTermsControlName'].setValue('');
  }

  onSearchTextModelChange() {
    if (this.searchFormGroup.controls['searchTermsControlName'].value.length > 0) {
      this.styleObservableServ.searchBarIsActiveSubj.next(true);
    } else {
      this.styleObservableServ.searchBarIsActiveSubj.next(false);
    }
  }

  recieveRefocus() {
    this._inputElement.nativeElement.focus();
    this.tabableService.enable = false;
  }

  navigateOnPopState(eventURL: string) {
    if (eventURL.includes(ahnTextPath.results)) {
      location.reload();
    }

    this.callCount = 0;
  }
}
