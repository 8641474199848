import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Credentials } from './credentials';

@Component({
  selector: 'engen-provider-credentials-v2',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './provider-credentials-v2.component.html',
  styleUrl: './provider-credentials-v2.component.scss',
})
export class ProviderCredentialsV2Component implements OnInit {
  @Input() providerCredentials: Credentials[] = [];

  fellowships: any[] = [];
  residencies: any[] = [];
  internships: any[] = [];
  educations: any[] = [];
  positions: any[] = [];
  boardCert: any[] = [];

  schoolsCount: number;
  resCount: number;
  jobsCount: number;
  boardsCount: number;
  fellowshipsCount: number;
  internCount: number;

  ngOnInit(): void {
    this.fellowships = this.providerCredentials.filter(
      (credential) => credential.type === 'Fellowship',
    );
    this.residencies = this.providerCredentials.filter(
      (credential) => credential.type === 'Residency',
    );
    this.internships = this.providerCredentials.filter(
      (credential) => credential.type === 'Internship',
    );
    this.educations = this.providerCredentials.filter(
      (credential) => credential.type === 'Education',
    );
    this.positions = this.providerCredentials.filter(
      (credential) => credential.type === 'Position',
    );
    this.boardCert = this.providerCredentials.filter((credential) => credential.type === 'board');

    this.schoolsCount = this.educations.length;
    this.resCount = this.residencies.length;
    this.jobsCount = this.positions.length;
    this.internCount = this.internships.length;
    this.fellowshipsCount = this.fellowships.length;
    this.boardsCount = this.boardCert.length;
  }
}
