/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Router } from '@angular/router';
import { SimpleUtilsService } from '@dmx-fad-ui/fad/services';
import { FadCommentService, FadProviderDetailRequest } from '@dmx-fad-ui/fad/data-access';
import { Subscription } from 'rxjs';
import { CommentsAndRatings } from '../../provider/provider-ratings-comments/commentsAndRatings';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'atlas-provider-star-rating',
  templateUrl: './provider-star-rating.component.html',
  styleUrls: ['./provider-star-rating.component.scss'],
})
export class ProviderStarRatingComponent implements OnInit, OnDestroy {
  @Input() ratingCount: any;
  @Input() ratingValue: any;
  @Input() profileName: string;
  commentCount: number = 0;
  commentsAndRatingsSub: Subscription;
  commentsAndRatings: CommentsAndRatings;
  checkZeroString: number;
  isCommentsDisabled: boolean = environment.isCommentsSegmentTemporarilyDisabled;

  constructor(
    private readonly simpleUtilsService: SimpleUtilsService,
    private fadCommentServ: FadCommentService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    if (this.profileName) {
      if (!this.isCommentsDisabled) {
        this.subscribeToCommentService();

        const provDetReq: FadProviderDetailRequest =
          this.simpleUtilsService.createFadProviderDetailRequest(this.profileName);

        this.fadCommentServ.getProviderComments(provDetReq);
      }
    }
    if (this.ratingValue) {
      this.checkZeroString = parseFloat(this.ratingValue);
      this.ratingValue = this.formatValue(this.ratingValue);
    }
    if (this.ratingCount) {
      this.ratingCount = this.formatValue(this.ratingCount);
    }
  }

  ngOnDestroy(): void {
    if (this.commentsAndRatingsSub) {
      this.commentsAndRatingsSub.unsubscribe();
    }
  }

  isCommentsClick(isClicked: boolean) {
    this.simpleUtilsService.isShowProviderReviewsTab.next(isClicked);
    this.router.navigate(['/' + this.profileName]);
  }

  formatValue(value: string): string {
    return parseFloat(value).toString();
  }

  subscribeToCommentService() {
    this.commentsAndRatingsSub = this.fadCommentServ.providerCommentsSubj.subscribe((cNr) => {
      if (cNr !== null && cNr !== undefined) {
        const receivedProfileName = cNr.payload.commentDetail.commentsAndRatings.profileName;
        if (receivedProfileName === this.profileName) {
          this.commentsAndRatings = cNr.payload.commentDetail.commentsAndRatings;
          this.commentCount = this.commentsAndRatings.comments.length;
        }
      }
    });
  }
}
