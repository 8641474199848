export class Credentials {
  type: string;
  name: string;
  specialty_name: string;
  constructor(type: string, name: string, specialty_name?: string) {
    this.type = type;
    this.name = name;
    this.specialty_name = specialty_name;
  }
  getSubName() {
    return this.specialty_name;
  }

  setSubName(specialty_name: string) {
    this.specialty_name = specialty_name;
  }
}
