<div class="provider-ages-seen-v2">
  <div class="provider-ages-seen-container">
    <div class="provider-ages-seen">
      <h3>Age Groups Seen</h3>
      <div class="ages-seen-container">
        <div class="ages-seen-list" *ngFor="let age of this.ageGroups">
          <span>{{ age }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
