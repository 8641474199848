<!--/* Desktop and Tablet */-->
<div class="provider-detail-page-new provider-detail-desktop">
  <div class="provider-desktop-view" #mainContent tabindex="0">
    <div class="profile-container">
      <div class="provider-profile-info">
        <div class="provider-profile-info-right-container">
          <div class="provider-information">
            <div class="profile-bcg-text">
              <img
                src="../../../../assets/small_ahn_logo.svg"
                height="17px"
                width="18px"
                alt="AHN Provider"
              />
              <div class="ahn-provider-text">AHN Provider</div>
            </div>
            <div class="provider-display-name sofia-pro-semi-bold">
              <h2>{{ providerFullName }}</h2>
            </div>
            <div class="provider-text roboto-regular provider-specialty">
              {{ providerSpecialty }}
            </div>
            <div *ngFor="let pn of this.providerPhoneNumber">
              <div class="provider-phone-call" *ngIf="pn.contact_type === 'phone'">
                <a href="tel:{{ pn.value }}" title="{{ pn.value }}">{{ pn.value }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
