/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, Subject, Subscription } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FadProviderDetailResponse } from './fad-provider-detail-response';
import { FadProviderDetailRequest } from './fad-provider-detail-request';
import { map } from 'rxjs/operators';
import { environment } from '../../../../../../../../apps/dmx-fad-ui/src/environments/environment';
import { FadCompareProviderRequest } from './fad-compare-provider-request';
import { FadProviderDetail } from './fad-provider-detail';
import { FadCommentService, FadSeachStateService } from '@dmx-fad-ui/fad/data-access';
import { CompareProviderService, CompareProviderModel } from '@dmx-fad-ui/fad/services';

@Injectable({
  providedIn: 'root',
})
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export class FadProviderDetailService {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  providerDetailSubj = new Subject<any>();
  compareProviderSubj = new Subject<FadProviderDetail[]>();
  compareProviderList: FadProviderDetail[] = [];
  endpoint: string = `${environment.providerDetailEndpoint}`;
  endpointV2: string = `${environment.providerDetailEndpointV2}`;
  providerDetailMapView = false;

  isUsingMockData: boolean = environment.kyruusFeatures.isUsingMockData;
  isCommentCallComplete: boolean = false;
  isCommentCallCompleteSub: Subscription;

  isCompareDetailCallCompleteSubj: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private httpClient: HttpClient,
    private fadSearchStateServ: FadSeachStateService,
    private compareServ: CompareProviderService,
    private fadCommentServ: FadCommentService,
  ) {
    this.httpClient = httpClient;
    this.fadSearchStateServ = fadSearchStateServ;
    this.fadCommentServ = fadCommentServ;
    this.compareServ = compareServ;
  }

  getProviderDetail(request: FadProviderDetailRequest): void {
    this.fadSearchStateServ.isShowSpinnerSubj.next(true);
    this.providerDetailMapView = true;
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('x-api-key', `${environment.xPubApiKey}`);
      const url = this.endpoint;
    this.httpClient
      .post<FadProviderDetailResponse>(url, request, {
        headers,
      })
      .pipe(
        map((resp) => {
          this.fadSearchStateServ.isShowSpinnerSubj.next(false);
          this.providerDetailSubj.next(resp);
        }),
      )
      .subscribe();
  }

  getProviderDetailV2(request: FadProviderDetailRequest): void {
    this.fadSearchStateServ.isShowSpinnerSubj.next(true);
    this.providerDetailMapView = true;
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('x-api-key', `${environment.xPubApiKey}`);

      const url = this.endpointV2;
    this.httpClient
      .post<FadProviderDetailResponse>(url, request, {
        headers,
      })
      .pipe(
        map((resp) => {
          this.fadSearchStateServ.isShowSpinnerSubj.next(false);
          this.providerDetailSubj.next(resp);
        }),
      )
      .subscribe();
  }

  getCompareProviderDetail(request: FadCompareProviderRequest): void {
    this.fadSearchStateServ.isShowSpinnerSubj.next(true);
    this.isCompareDetailCallCompleteSubj.next(false);
    this.compareProviderList = [];
    this.providerDetailMapView = true;
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('x-api-key', `${environment.xPubApiKey}`);

    // BEGINNING OF FJ

    const npisFound = [];
    for (let i = 0; i < request.providerQuery.npiList.length; i++) {
      if (request.providerQuery.npiList[i] !== '') {
        npisFound.push(this.createFadProviderDetailRequestNpi(request.providerQuery.npiList[i]));
      }
    }
    const url = this.endpoint;

    const observables: any[] = [];
    for (let i = 0; i < npisFound.length; i++) {

      observables.push(
        this.httpClient.post<FadProviderDetailResponse>(url, npisFound[i], { headers }),
      );
    }

    const fj: Observable<FadProviderDetailResponse[]> = forkJoin(
      observables as FadProviderDetailResponse[],
    );

    fj.subscribe({
      next: (res) => {
        for (let i = 0; i < res.length; i++) {
          if (res[i].payload.providerDetail !== null) {
            this.compareProviderList.push(res[i].payload.providerDetail);
          }
        }
      },
      complete: () => {
        this.compareProviderSubj.next(this.compareProviderList);
        this.isCompareDetailCallCompleteSubj.next(true);
        if (this.compareServ.compareProviders[0].displayName === '') {
          this.compareProviderList.forEach((provider) => {
            this.compareServ.addToList(
              new CompareProviderModel(
                provider.profileName,
                provider.displayName,
                provider.signatureSpecialty,
                provider.npi,
              ),
            );
          });
        }
      },
    });
    // END OF FJ
  }

  getCompareProviderDetailV2(request: FadCompareProviderRequest): void {
    this.fadSearchStateServ.isShowSpinnerSubj.next(true);
    this.isCompareDetailCallCompleteSubj.next(false);
    this.compareProviderList = [];
    this.providerDetailMapView = true;
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('x-api-key', `${environment.xPubApiKey}`);

    // BEGINNING OF FJ

    const npisFound = [];
    for (let i = 0; i < request.providerQuery.npiList.length; i++) {
      if (request.providerQuery.npiList[i] !== '') {
        npisFound.push(this.createFadProviderDetailRequestNpi(request.providerQuery.npiList[i]));
      }
    }
    const url = this.endpointV2;;

    const observables: any[] = [];
    for (let i = 0; i < npisFound.length; i++) {

      observables.push(
        this.httpClient.post<FadProviderDetailResponse>(url, npisFound[i], { headers }),
      );
    }

    const fj: Observable<FadProviderDetailResponse[]> = forkJoin(
      observables as FadProviderDetailResponse[],
    );

    fj.subscribe({
      next: (res) => {
        for (let i = 0; i < res.length; i++) {
          if (res[i].payload.providerDetail !== null) {
            this.compareProviderList.push(res[i].payload.providerDetail);
          }
        }
      },
      complete: () => {
        this.compareProviderSubj.next(this.compareProviderList);
        this.isCompareDetailCallCompleteSubj.next(true);
        if (this.compareServ.compareProviders[0].displayName === '') {
          this.compareProviderList.forEach((provider) => {
            this.compareServ.addToList(
              new CompareProviderModel(
                provider.profileName,
                provider.displayName,
                provider.signatureSpecialty,
                provider.npi,
              ),
            );
          });
        }
      },
    });
    // END OF FJ
  }

  createFadProviderDetailRequest(providerName: string): FadProviderDetailRequest {
    return { providerQuery: { profileName: providerName } };
  }

  createFadProviderDetailRequestNpi(npi: string): FadProviderDetailRequest {
    return { providerQuery: { npi: npi } };
  }
  createFadProviderDetailRequestPrint(providerName: string): FadProviderDetailRequest {
    return { providerQuery: { profileName: providerName, npi: '' } };
  }

  createCompareProviderReq(provNames: string[]): FadCompareProviderRequest {
    return { providerQuery: { npiList: provNames } };
  }
}
