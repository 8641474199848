export const mockTypeaheadCan = {
  conditions_symptoms: [
    {
      highlight: 'cancer of anal canal',
      name: 'cancer of anal canal',
      score: 8.944124,
      synonym: 'anal carcinoma in situ',
      type: 'term',
      uuid: 'T00015221',
    },
    {
      highlight: 'ear canal cancer',
      name: 'ear canal cancer',
      score: 8.944124,
      synonym: null,
      type: 'concept',
      uuid: 'C3665941',
    },
    {
      highlight: 'cancer of pleural cavity',
      name: 'cancer of pleural cavity',
      score: 8.048521,
      synonym: 'pleural cancer',
      type: 'term',
      uuid: 'T00062513',
    },
    {
      highlight: 'castrate-resistant prostate cancer',
      name: 'castrate-resistant prostate cancer',
      score: 7.9963255,
      synonym: 'prostate cancer',
      type: 'term',
      uuid: 'T00079476',
    },
    {
      highlight: 'cancer related sexual concerns',
      name: 'cancer related sexual concerns',
      score: 7.5475063,
      synonym: null,
      type: 'concept',
      uuid: 'C3661736',
    },
  ],
  provider_name: [
    {
      highlight: 'Candice Jayme Coles',
      id: 3844308,
      name: 'Candice Jayme Coles',
      preferred_name: 'Candice Jayme',
      preferred_name_highlight: 'Candice Jayme',
      score: 2.1666665,
    },
    {
      highlight: 'Candice Lee',
      id: 3846031,
      name: 'Candice Lee',
      preferred_name: 'Candice',
      preferred_name_highlight: 'Candice',
      score: 2.1666665,
    },
    {
      highlight: 'Candra Ann Cummings',
      id: 3846209,
      name: 'Candra Ann Cummings',
      preferred_name: 'Candra Ann',
      preferred_name_highlight: 'Candra Ann',
      score: 2.1666665,
    },
    {
      highlight: 'Anthony C Canterna',
      id: 3846165,
      name: 'Anthony C Canterna',
      preferred_name: 'Anthony C',
      preferred_name_highlight: 'Anthony C',
      score: 1.6666666,
    },
    {
      highlight: 'Rebecca Cannon',
      id: 3844216,
      name: 'Rebecca Cannon',
      preferred_name: 'Rebecca',
      preferred_name_highlight: 'Rebecca',
      score: 1.6666666,
    },
  ],
  specialties: [
    {
      highlight: 'Pediatric Hematology Oncology (Pediatric Cancer Doctor)',
      name: 'Pediatric Hematology Oncology',
      score: 23.415783,
      synonym: null,
      type: 'specialty',
      uuid: 'E0000891',
    },
    {
      highlight: 'Radiation Oncology (Cancer Care)',
      name: 'Radiation Oncology',
      score: 22.519413,
      synonym: null,
      type: 'specialty',
      uuid: 'E0000213',
    },
    {
      highlight: 'Hematology Oncology (Cancer Care)',
      name: 'Hematology Oncology',
      score: 22.272707,
      synonym: null,
      type: 'specialty',
      uuid: 'E0000890',
    },
    {
      highlight: 'Gynecologic Oncology (Gynecologic Cancers)',
      name: 'Gynecologic Oncology',
      score: 21.214396,
      synonym: null,
      type: 'specialty',
      uuid: 'E0000100',
    },
    {
      highlight: 'Surgical Oncology (Cancer Doctor)',
      name: 'Surgical Oncology',
      score: 20.862537,
      synonym: null,
      type: 'specialty',
      uuid: 'E0000223',
    },
  ],
  treatments_procedures: [
    {
      highlight: 'gynecologic cancer care',
      name: 'gynecologic cancer care',
      score: 8.048521,
      synonym: null,
      type: 'concept',
      uuid: 'C3657919',
    },
    {
      highlight: 'nutritional cancer care',
      name: 'nutritional cancer care',
      score: 8.048521,
      synonym: 'cancer diet',
      type: 'term',
      uuid: 'T00052835',
    },
    {
      highlight: 'palliative care for cancer symptoms',
      name: 'palliative care for cancer symptoms',
      score: 7.9963255,
      synonym: null,
      type: 'concept',
      uuid: 'C3662915',
    },
    {
      highlight: 'hospice and palliative care for cancer patients',
      name: 'hospice and palliative care for cancer patients',
      score: 7.9448175,
      synonym: null,
      type: 'concept',
      uuid: 'C3662894',
    },
    {
      highlight: 'canaloplasty',
      name: 'canaloplasty',
      score: 5.8954444,
      synonym: null,
      type: 'concept',
      uuid: 'C3670819',
    },
  ],
};
