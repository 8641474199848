<div class="persistent-bar">
  <atlas-search
    [isLandingPage]="false"
    [isProviderDetailPage]="true"
    [isSearchResultsPage]="false"
    [shouldAutoFocus]="false"
  ></atlas-search>
</div>

<div class="compare-container">
  <div class="return-print-share">
    <div class="compare-return-container">
      <div [ngClass]="showReturnText ? '' : 'hide-return'">
        <atlas-ahn-text #ahntext [indexPage]="false"></atlas-ahn-text>
      </div>
    </div>
    <atlas-share-button [pageType]="this.pageType"></atlas-share-button>
  </div>
  <div *ngIf="this.isNewComparePage">
    <engen-profile-compare-tab-v2> </engen-profile-compare-tab-v2>
  </div>

  <div class="compare-row compare-row-header">
    <atlas-compare-category
      class="row-segment-title top-buffer grey-background"
      [title]=""
    ></atlas-compare-category>
    <div class="row-segment grey-background content-width" *ngFor="let name of nameCompareList">
      <atlas-compare-prov-name
        [displayName]="name.displayName"
        [profileName]="name.profileName"
        [signature]="name.signatureSpecialty"
        [imageUrl]="name.imageUrl"
        [ratingValue]="name.ratingValue"
        [ratingCount]="name.ratingCount"
      ></atlas-compare-prov-name>
    </div>
  </div>
  <div class="compare-row">
    <atlas-compare-category
      class="row-segment-title top-buffer white-background"
      [title]="this.expertiseCategory"
    >
    </atlas-compare-category>
    <div class="row-segment white-background" *ngFor="let exp of expertiseCompareList">
      <atlas-compare-expertise [expertises]="exp.expertiseList"></atlas-compare-expertise>
    </div>
  </div>

  <div class="compare-row">
    <atlas-compare-category
      class="row-segment-title top-buffer blue-background"
      [title]="this.onlineSchedulingCategory"
    ></atlas-compare-category>
    <div class="row-segment blue-background" *ngFor="let sched of onlineSchedulingCompareList">
      <atlas-compare-online-scheduling
        [isOnlineScheduling]="sched.scheduling"
        [providerNPI]="sched.NPI"
        [profileName]="sched.profileName"
      ></atlas-compare-online-scheduling>
    </div>
  </div>
  <div class="compare-row">
    <atlas-compare-category
      class="row-segment-title top-buffer white-background"
      [title]="this.locationCategory"
    >
    </atlas-compare-category>
    <div class="row-segment white-background" *ngFor="let loc of locationCompareList">
      <atlas-compare-location [locationCompare]="loc"></atlas-compare-location>
    </div>
  </div>
  <!-- <div class="compare-row ">
    <atlas-compare-category
      class="row-segment-title top-buffer blue-background "
      [title]="this.distanceCategory"
    ></atlas-compare-category>
    <div class="row-segment blue-background " *ngFor="let distance of distanceCompareList">
      <atlas-compare-distance [distanceCompare]="distance"></atlas-compare-distance>
    </div>
  </div>-->
  <div class="compare-row">
    <atlas-compare-category
      class="row-segment-title top-buffer white-background"
      [title]="this.languageCategory"
    >
    </atlas-compare-category>
    <div class="row-segment white-background" *ngFor="let lang of languageCompareList">
      <atlas-compare-languages [languageCompare]="lang"></atlas-compare-languages>
    </div>
  </div>
  <div class="compare-row">
    <atlas-compare-category
      class="row-segment-title top-buffer blue-background"
      [title]="this.ratingsCategory"
    >
    </atlas-compare-category>
    <div class="row-segment blue-background" *ngFor="let rating of finalUpdatedRatings">
      <atlas-compare-ratings [ratingCompare]="rating"></atlas-compare-ratings>
    </div>
  </div>
  <div class="compare-row">
    <atlas-compare-category
      class="row-segment-title top-buffer white-background"
      [title]="this.credsCategory"
    >
    </atlas-compare-category>
    <div class="row-segment white-background" *ngFor="let cred of credentialsCompareList">
      <atlas-compare-credentials [creds]="cred"></atlas-compare-credentials>
    </div>
  </div>
  <div class="compare-row">
    <atlas-compare-category
      class="row-segment-title top-buffer blue-background"
      [title]="this.lgbtCategory"
    >
    </atlas-compare-category>
    <div class="row-segment blue-background" *ngFor="let lgbt of lgbtCompareList">
      <atlas-compare-lgbt [lgbt]="lgbt"></atlas-compare-lgbt>
    </div>
  </div>
</div>
