import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { LocationPracticeBuilding } from '@dmx-fad-ui/fad/services';
import { ahnTextPath } from '../../ahn-text/ahn-text.enum';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'atlas-provider-locations',
  templateUrl: './provider-locations.component.html',
  styleUrls: ['./provider-locations.component.scss'],
})
export class ProviderLocationsComponent {
  @Input() providerPracticeBuildings: LocationPracticeBuilding;
  routerLinkPath: string;
  isNewproviderProfileExperience = environment.isTabProviderPageEnabled;
  constructor(private router: Router) {
    this.routerLinkPath = ahnTextPath.results;
  }

  getEncodedUrl(keyword: string): string {
    return '/search-results/' + encodeURIComponent(keyword);
  }
}
