<div>
  <div class="sort-bar">
    <mat-form-field appearance="outline">
      <mat-label>Sort by</mat-label>
      <mat-select
        disableOptionCentering
        (selectionChange)="onSelect($event)"
        (focus)="sortPicklist.open()"
        [value]="sortValue"
        #sortPicklist
      >
        <div *ngIf="alphaSortDis === true">
          <ng-container *ngFor="let sort of sorting">
            <mat-option
              [value]="sort.value"
              [disabled]="
                (sort.value === 'nearest' && nearestToMeDisabled) ||
                (alphaSortDisabled &&
                  (sort.value === 'a to z last name' || sort.value === 'z to a last name'))
              "
            >
              {{ sort.viewValue }}
            </mat-option>
          </ng-container>
        </div>
        <div *ngIf="alphaSortDis === false">
          <ng-container *ngFor="let sort of sorting">
            <mat-option
              [value]="sort.value"
              [disabled]="sort.value === 'nearest' && nearestToMeDisabled"
            >
              {{ sort.viewValue }}
            </mat-option>
          </ng-container>
        </div>
      </mat-select>
    </mat-form-field>
  </div>
</div>
