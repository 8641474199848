import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { ProviderComponent } from './provider.component';
import { ProviderProfileComponent } from '../provider/provider-profile/provider-profile.component';
import { ProviderProfileModule } from '../provider/provider-profile/provider-profile.module';
import { ProviderExpertiseModule } from '../provider/provider-expertise/provider-expertise.module';
import { ProviderCredentialsModule } from '../provider/provider-credentials/provider-credentials.module';
import { ProviderRatingsCommentsModule } from '../provider/provider-ratings-comments/provider-ratings-comments.module';
import { ProviderBioModule } from '../provider/provider-bio/provider-bio.module';
import { ProviderLocationsModule } from '../provider/provider-locations/provider-locations.module';
import { ResultSummaryModule } from '../result-summary/result-summary.module';
import { ProviderPublicationsModule } from '../provider/provider-publications/provider-publications.module';
import { ProviderMapModule } from '../provider-results/provider-map/provider-map.module';
import { ProviderLgbtModule } from '../provider/provider-lgbt/provider-lgbt.module';
import { ReferralButtonModule } from '../referral/referral-button/referral-button.module';
import { ProviderVideodisclaimerModule } from './provider-videodisclaimer/provider-videodisclaimer.module';
import { ProfileBannerV2Component } from '../provider-v2/profile-banner-v2/profile-banner-v2.component';
import { ProfileAboutTabV2Component } from '../provider-v2/profile-about-tab-v2/profile-about-tab-v2.component';
import { ProfileLocationsTabV2Component } from '../profile-locations-tab-v2/profile-locations-tab-v2.component';
import { ProfileReviewsTabV2Component } from '../profile-reviews-tab-v2/profile-reviews-tab-v2.component';
import { ProfileReferralTabV2Component } from '../profile-referral-tab-v2/profile-referral-tab-v2.component';
import { AgeGroupsSceneV2Component } from '../provider-v2/age-groups-seen-v2/age-groups-scene-v2.component';
import { ProviderLanguageV2Component } from '../provider-v2/provider-languages-v2/provider-languages-v2.component';
import { ExpertiseV2Component } from '../provider-v2/provider-expertise-v2/expertise-v2.component';
import { ProviderCredentialsV2Component } from '../provider-v2/provider-credentials-v2/provider-credentials-v2.component';
import { AcceptedInsuranceV2Component } from '../provider-v2/accepted-insurance-v2/accepted-insurance-v2.component';
import { ProviderPublicationsV2Component } from '../provider-v2/provider-publications-v2/provider-publications-v2.component';

@NgModule({
  declarations: [ProviderComponent],
  exports: [ProviderComponent, ProviderProfileComponent],
  imports: [
    CommonModule,
    ProviderProfileModule,
    ProviderExpertiseModule,
    ProviderCredentialsModule,
    ProviderRatingsCommentsModule,
    ProviderBioModule,
    ProviderLocationsModule,
    ResultSummaryModule,
    ProviderPublicationsModule,
    ProviderMapModule,
    ProviderLgbtModule,
    ReferralButtonModule,
    ProviderVideodisclaimerModule,
    ProfileBannerV2Component,
    ProfileAboutTabV2Component,
    ProfileLocationsTabV2Component,
    ProfileReviewsTabV2Component,
    ProfileReferralTabV2Component,
    MatTabsModule,
    AgeGroupsSceneV2Component,
    ProviderLanguageV2Component,
    ExpertiseV2Component,
    ProviderCredentialsV2Component,
    AcceptedInsuranceV2Component,
    ProviderPublicationsV2Component,
  ],
})
export class ProviderModule {}
