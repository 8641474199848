import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SpecialtiesName } from './specialtiesName';
import { ProviderLocations } from './locations';

@Component({
  selector: 'engen-provider-result-v2',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './provider-result-v2.component.html',
  styleUrl: './provider-result-v2.component.scss',
})
export class ProviderResultV2Component implements OnInit {
  constructor(private router: Router) {
    this.router = router;
  }

  @Input() providerResult: any = undefined;
  fullName: string = undefined;
  providerSpecialty: SpecialtiesName[] = [];
  providerImage: string = undefined;
  acceptingNewPatients: any;
  ageGroupsSeen: string[];
  locations: ProviderLocations[] = [];

  ngOnInit(): void {
    console.log('Spawned provider result v2');
    console.log(this.providerResult);
    this.fullName = this.providerResult.provider.name.full;
    this.providerSpecialty = this.providerResult.provider.specialties;
    this.providerImage = this.providerResult.provider.image_url;
    this.acceptingNewPatients = this.providerResult.provider.accepting_new_patients;
    this.ageGroupsSeen = this.providerResult.provider.age_groups_seen;
    this.locations = this.providerResult.locations;
    console.log(this.locations);
  }

  navigateToProviderProfile() {
    this.router.navigate([this.fullName]);
  }
}
