export const providerMock = [
  {
    _metadata: {
      intent_classification: null,
      intent_matching_enabled: false,
      max_date_available: null,
      min_date_available: null,
      provider_count: 1,
      response_time: 32,
      slot_count: 0,
      total_providers: 1,
      tz_max_date_available: null,
      tz_min_date_available: null,
    },
    _result: [
      {
        availability: {
          facets: null,
          meta: {
            availability_density_best: null,
            max_date_available: null,
            min_date_available: null,
            slot_count: 0,
            tz_max_date_available: null,
            tz_min_date_available: null,
          },
          slots: [],
        },
        customer: 'ahn',
        locations: [
          {
            associated_marketable_location_ids: [],
            city: 'Butler',
            commercial_entity_name: null,
            contacts: [],
            coordinates: {
              lat: 40.852474,
              lon: -79.910496,
            },
            ehr_location_id: '10180025004',
            email: null,
            external_id: '10180025004',
            external_organization_id: null,
            facility_fee: false,
            fax: '724-482-1115',
            id: 4165624,
            name: 'AHN Cancer Institute at Butler',
            networks: [],
            office_hours: null,
            phone: '724-282-6175',
            primary_marketable_location_id: null,
            rank: 2,
            source_system: 'epic',
            source_system_type: 'epic',
            state: 'PA',
            street1: '160 Hollywood Drive',
            street2: '',
            suite: '',
            timezone: 'UTC',
            type: 'CLI',
            url: 'https://www.google.com/maps?q=160+Hollywood+Drive,+2nd+Floor,Butler,PA,16001',
            zip: '16001',
          },
          {
            associated_marketable_location_ids: [],
            city: 'Monaca',
            commercial_entity_name: null,
            contacts: [],
            coordinates: {
              lat: 40.679408,
              lon: -80.313039,
            },
            ehr_location_id: '10180024004',
            email: null,
            external_id: '10180024004',
            external_organization_id: null,
            facility_fee: false,
            fax: '724-773-0530',
            id: 4165456,
            name: 'AHN Cancer Institute at Beaver',
            networks: [],
            office_hours: null,
            phone: '724-671-1500',
            primary_marketable_location_id: null,
            rank: 2,
            source_system: 'epic',
            source_system_type: 'epic',
            state: 'PA',
            street1: '81 Wagner Road',
            street2: '',
            suite: '',
            timezone: 'UTC',
            type: 'CLI',
            url: 'https://www.google.com/maps/place/81+Wagner+Rd+2nd+Floor,+Monaca,+PA+15061/@40.678505,-80.3202508',
            zip: '15061',
          },
        ],
        match: null,
        provider: {
          about_the_provider:
            'Bushra Haq, MD   Bushra Haq, MD, is a medical oncologist with AHN Cancer Institute, specializing in colorectal cancer, pancreatic and gynecological malignancies, and hematologic malignancies and disorders. She provides patient-centered care for a wide array of cancers including breast, head and neck, lung, skin, ovarian, and prostate cancers.   Dr. Haq went to medical school at Baqai Medical University in Karachi Pakistan. She did her residency and her fellowship in hematology and oncology at West Penn Hospital in Pittsburgh, Pennsylvania.   Dr. Haq is certified by the American Board of Internal Medicine in both oncology and hematology and has been published for her oncologic work in various academic texts. She sees patients ages 18 and older.    ',
          academic_department_name: null,
          academic_title: null,
          accepting_new_patients: true,
          access_program_status: null,
          additional_services: null,
          additional_visit_types: null,
          age_groups_seen: ['Adult 21-65', 'Older Adult >65'],
          aha_id: null,
          always_available_provider: false,
          appointment_ehr_purposes: [],
          awards: null,
          board_certifications: [
            {
              board_name: 'American Board of Internal Medicine',
              board_specialty: 'BC0000065',
              certification_type: 'board',
              certifying_board: 'BC0000007',
              rank: 1,
              specialty_name: 'Medical Oncology',
              year_certified: 2009,
            },
            {
              board_name: 'American Board of Internal Medicine',
              board_specialty: 'BC0000061',
              certification_type: 'board',
              certifying_board: 'BC0000007',
              rank: 2,
              specialty_name: 'Hematology',
              year_certified: 2009,
            },
          ],
          book_online_override_url: null,
          clinic_location_url: null,
          clinical_contact: null,
          clinical_interests: null,
          clinical_keywords: {
            promoted: [],
            searchable: [
              {
                cui: 'C3681685',
                name: 'lymphedema physical therapy',
              },
              {
                cui: 'C3678052',
                name: 'chemotherapy iv infusion',
              },
              {
                cui: 'C3677197',
                name: 'rectal neuroendocrine tumor',
              },
              {
                cui: 'C3676907',
                name: 'neuroendocrine carcinoma',
              },
              {
                cui: 'C3676905',
                name: 'metastatic neuroendocrine tumors',
              },
              {
                cui: 'C3675883',
                name: 'relapsed myeloma',
              },
              {
                cui: 'C3675545',
                name: 'colon adenocarcinoma',
              },
              {
                cui: 'C3674420',
                name: 'neutrophilia',
              },
              {
                cui: 'C3674357',
                name: 'elevated transferrin saturation',
              },
              {
                cui: 'C3674355',
                name: 'elevated ferritin',
              },
              {
                cui: 'C3674345',
                name: 'upper extremity lymphedema',
              },
              {
                cui: 'C3674111',
                name: 'large cell neuroendocrine tumors',
              },
              {
                cui: 'C3673961',
                name: 'metastatic blood cancers',
              },
              {
                cui: 'C3673793',
                name: 'neuroendocrine tumor of the lung',
              },
              {
                cui: 'C3673678',
                name: 'generalized lymphatic anomaly',
              },
              {
                cui: 'C3673213',
                name: 'adenocarcinoma of the bladder',
              },
              {
                cui: 'C3673153',
                name: 'hormone receptor negative breast cancer',
              },
              {
                cui: 'C3672871',
                name: 'nut carcinoma',
              },
              {
                cui: 'C3672869',
                name: 'fibrolamellar carcinoma',
              },
              {
                cui: 'C3672843',
                name: 'ductal carcinoma in situ (dcis)',
              },
              {
                cui: 'C3672817',
                name: 'personal history of cancer',
              },
              {
                cui: 'C3672712',
                name: 'secondary cancer screening',
              },
              {
                cui: 'C3672708',
                name: 'long term side effects of cancer treatment',
              },
              {
                cui: 'C3672706',
                name: 'personal history of radiation therapy',
              },
              {
                cui: 'C3672704',
                name: 'personal history of chemotherapy',
              },
              {
                cui: 'C3672683',
                name: 'blood cancer chemotherapy',
              },
              {
                cui: 'C3672466',
                name: 'autoimmune neutropenia (ain)',
              },
              {
                cui: 'C3672464',
                name: 'evans syndrome',
              },
              {
                cui: 'C3672462',
                name: 'hemophilia inhibitors',
              },
              {
                cui: 'C3672460',
                name: 'factor thirteen deficiency',
              },
              {
                cui: 'C3672458',
                name: 'factor seven deficiency',
              },
              {
                cui: 'C3672451',
                name: 'hereditary elliptocytosis (he)',
              },
              {
                cui: 'C3672449',
                name: 'acquired elliptocytosis',
              },
              {
                cui: 'C3672429',
                name: 'prostate cancer chemotherapy',
              },
              {
                cui: 'C3672427',
                name: 'bladder cancer chemotherapy',
              },
              {
                cui: 'C3672425',
                name: 'testicular cancer chemotherapy',
              },
              {
                cui: 'C3672413',
                name: 'relapsed acute myelogenous leukemia (aml)',
              },
              {
                cui: 'C3671330',
                name: 'adolescent and young adult cancer (aya)',
              },
              {
                cui: 'C3671252',
                name: 'breast cancer biological targeted therapy',
              },
              {
                cui: 'C3670864',
                name: 'relapsed non hodgkin lymphoma',
              },
              {
                cui: 'C3670862',
                name: 'relapsed hodgkin lymphoma',
              },
              {
                cui: 'C3670860',
                name: 'relapsed acute lymphoblastic leukemia (all)',
              },
              {
                cui: 'C3670407',
                name: 'neutrophil disorders',
              },
              {
                cui: 'C3670332',
                name: 'blastic plasmacytoid dendritic cell neoplasm',
              },
              {
                cui: 'C3670218',
                name: 'disseminated intravascular coagulation',
              },
              {
                cui: 'C3669666',
                name: 'hormone receptor positive breast cancer',
              },
              {
                cui: 'C3669664',
                name: 'her2 positive breast cancer',
              },
              {
                cui: 'C3669267',
                name: 'carcinoma of unknown primary',
              },
              {
                cui: 'C3669036',
                name: 'lymphomatoid granulomatosis',
              },
              {
                cui: 'C3668882',
                name: 'intrathecal chemotherapy',
              },
              {
                cui: 'C3668878',
                name: 'maintenance chemotherapy',
              },
              {
                cui: 'C3668876',
                name: 'consolidation chemotherapy',
              },
              {
                cui: 'C3668874',
                name: 'induction chemotherapy',
              },
              {
                cui: 'C3668729',
                name: 'er negative breast cancer',
              },
              {
                cui: 'C3668560',
                name: 'pituitary cancer',
              },
              {
                cui: 'C3668547',
                name: 'chronic myelomonocytic leukemia',
              },
              {
                cui: 'C3667788',
                name: 'malignant adnexal tumor',
              },
              {
                cui: 'C3667507',
                name: 'peripheral t cell lymphoma',
              },
              {
                cui: 'C3667217',
                name: 'intraarterial chemotherapy of tumors',
              },
              {
                cui: 'C3666660',
                name: 'inflammatory breast cancer',
              },
              {
                cui: 'C3666657',
                name: 'recurrent breast cancer',
              },
              {
                cui: 'C3666622',
                name: 'brain metastases',
              },
              {
                cui: 'C3666604',
                name: 'coagulation disorders',
              },
              {
                cui: 'C3666603',
                name: 'bone marrow failure syndromes',
              },
              {
                cui: 'C3666441',
                name: 'waldenstrom macroglobulinemia',
              },
              {
                cui: 'C3666434',
                name: 'liver metastases',
              },
              {
                cui: 'C3666391',
                name: 'adrenal nodules',
              },
              {
                cui: 'C3666286',
                name: 'metastatic breast cancer',
              },
              {
                cui: 'C3665929',
                name: 'hodgkin lymphoma',
              },
              {
                cui: 'C3665410',
                name: 'triple negative breast cancer',
              },
              {
                cui: 'C3665317',
                name: 'cancer genetics',
              },
              {
                cui: 'C3665253',
                name: 'hemoglobinopathies',
              },
              {
                cui: 'C3664184',
                name: 'breast cancer therapy',
              },
              {
                cui: 'C3663791',
                name: 'thrombocytosis',
              },
              {
                cui: 'C3663787',
                name: "castleman's disease",
              },
              {
                cui: 'C3663395',
                name: 'acute blood loss anemia',
              },
              {
                cui: 'C3663225',
                name: 'blood disorders',
              },
              {
                cui: 'C3663152',
                name: 'lymphopenia',
              },
              {
                cui: 'C3663149',
                name: 'prostate cancer',
              },
              {
                cui: 'C3662982',
                name: 'early detection of cancer',
              },
              {
                cui: 'C3662682',
                name: 'von willebrand disease',
              },
              {
                cui: 'C3662585',
                name: 'adenocarcinoma of lung',
              },
              {
                cui: 'C3662580',
                name: 'acute myelogenous leukemia (aml)',
              },
              {
                cui: 'C3662579',
                name: 'acute lymphoblastic leukemia (all)',
              },
              {
                cui: 'C3662486',
                name: 'abdominal chemotherapy',
              },
              {
                cui: 'C3662368',
                name: 'breast cancer',
              },
              {
                cui: 'C3662343',
                name: 'multiple endocrine neoplasia type 2b',
              },
              {
                cui: 'C3662342',
                name: 'multiple endocrine neoplasia type 2a',
              },
              {
                cui: 'C3662341',
                name: 'multiple endocrine neoplasia type 2',
              },
              {
                cui: 'C3662340',
                name: 'multiple endocrine neoplasia type 1',
              },
              {
                cui: 'C3662313',
                name: 'papillary cancer of thyroid',
              },
              {
                cui: 'C3662312',
                name: 'medullary cancer of thyroid',
              },
              {
                cui: 'C3662311',
                name: 'follicular cancer of thyroid',
              },
              {
                cui: 'C3662296',
                name: 'poems syndrome',
              },
              {
                cui: 'C3662285',
                name: 'recurrent cervical cancer',
              },
              {
                cui: 'C3662215',
                name: 'squamous cancer of the lung',
              },
              {
                cui: 'C3662192',
                name: 'esophageal cancer',
              },
              {
                cui: 'C3662182',
                name: 'kasabach merritt phenomenon (kmp)',
              },
              {
                cui: 'C3662173',
                name: 'anemia of chronic disease',
              },
              {
                cui: 'C3662172',
                name: 'familial aplastic anemia',
              },
              {
                cui: 'C3662171',
                name: 'cooley anemia',
              },
              {
                cui: 'C3662170',
                name: 'pernicious anemia',
              },
              {
                cui: 'C3662169',
                name: 'hemolytic anemia',
              },
              {
                cui: 'C3662061',
                name: 'retroperitoneal mass',
              },
              {
                cui: 'C3662026',
                name: 'acute promyelocytic leukemia',
              },
              {
                cui: 'C3662025',
                name: 'prolymphocytic leukemia',
              },
              {
                cui: 'C3662022',
                name: 'primary myelofibrosis',
              },
              {
                cui: 'C3661985',
                name: 'oral complications of cancer',
              },
              {
                cui: 'C3661977',
                name: 'non hodgkin lymphoma',
              },
              {
                cui: 'C3661942',
                name: 'myeloproliferative diseases',
              },
              {
                cui: 'C3661903',
                name: 'lymphoid leukemia',
              },
              {
                cui: 'C3661902',
                name: 'lymphedema therapy',
              },
              {
                cui: 'C3661882',
                name: 'large granular lymphocytic leukemia',
              },
              {
                cui: 'C3661847',
                name: 'hematologic malignancies',
              },
              {
                cui: 'C3661846',
                name: 'head and neck cancer',
              },
              {
                cui: 'C3661735',
                name: 'cancer related fatigue',
              },
              {
                cui: 'C3661733',
                name: 'hereditary cancer risk',
              },
              {
                cui: 'C3661719',
                name: 'bone marrow disorders',
              },
              {
                cui: 'C3661714',
                name: 'blood cancer',
              },
              {
                cui: 'C3661690',
                name: 'anaplastic large cell lymphoma',
              },
              {
                cui: 'C3661681',
                name: 'adverse effects to anticoagulants',
              },
              {
                cui: 'C3661677',
                name: 'adult t cell leukemia',
              },
              {
                cui: 'C3661652',
                name: 'head and neck tumors',
              },
              {
                cui: 'C3661620',
                name: 'reduced intensity conditioning regimens',
              },
              {
                cui: 'C3661577',
                name: 'lymphoblastic lymphoma',
              },
              {
                cui: 'C3661548',
                name: 'germinoma',
              },
              {
                cui: 'C3661540',
                name: 'fanconi anemia',
              },
              {
                cui: 'C3661537',
                name: 'epithelioid hemangioendothelioma',
              },
              {
                cui: 'C3661530',
                name: 'diamond blackfan anemia',
              },
              {
                cui: 'C3661478',
                name: 'bladder cancer',
              },
              {
                cui: 'C3661406',
                name: 'cancer screening',
              },
              {
                cui: 'C3660859',
                name: 'thyroid cancer',
              },
              {
                cui: 'C3660848',
                name: 'thrombotic thrombocytopenic purpura',
              },
              {
                cui: 'C3660844',
                name: 'thrombophilia',
              },
              {
                cui: 'C3660843',
                name: 'thrombocytopenia',
              },
              {
                cui: 'C3660813',
                name: 'thalassemia',
              },
              {
                cui: 'C3660664',
                name: 'stomach cancer',
              },
              {
                cui: 'C3660612',
                name: 'splenomegaly',
              },
              {
                cui: 'C3660529',
                name: 'small cell lung cancer',
              },
              {
                cui: 'C3660464',
                name: 'side effects of cancer treatment',
              },
              {
                cui: 'C3660462',
                name: 'sickle cell disease',
              },
              {
                cui: 'C3660389',
                name: 'secondary polycythemia',
              },
              {
                cui: 'C3660328',
                name: 'sarcoma',
              },
              {
                cui: 'C3660247',
                name: 'rhabdomyosarcoma',
              },
              {
                cui: 'C3660108',
                name: 'kidney cancer',
              },
              {
                cui: 'C3659977',
                name: 'pulmonary metastases',
              },
              {
                cui: 'C3659820',
                name: 'post hemorrhagic anemia',
              },
              {
                cui: 'C3659779',
                name: 'polycythemia',
              },
              {
                cui: 'C3659699',
                name: 'petechiae',
              },
              {
                cui: 'C3659502',
                name: 'pancytopenia',
              },
              {
                cui: 'C3659488',
                name: 'pancreas cancer',
              },
              {
                cui: 'C3659304',
                name: 'non small cell lung cancer',
              },
              {
                cui: 'C3659282',
                name: 'neutropenia',
              },
              {
                cui: 'C3659259',
                name: 'neuroendocrine tumors',
              },
              {
                cui: 'C3659209',
                name: 'neoadjuvant chemotherapy',
              },
              {
                cui: 'C3659145',
                name: 'myelodysplastic syndrome',
              },
              {
                cui: 'C3659133',
                name: 'musculoskeletal tumor',
              },
              {
                cui: 'C3659112',
                name: 'multiple myeloma',
              },
              {
                cui: 'C3659110',
                name: 'multiple endocrine neoplasia',
              },
              {
                cui: 'C3658959',
                name: 'metastatic brain cancer',
              },
              {
                cui: 'C3658946',
                name: 'mesothelioma',
              },
              {
                cui: 'C3658939',
                name: 'merkel cell carcinoma',
              },
              {
                cui: 'C3658908',
                name: 'melanoma',
              },
              {
                cui: 'C3658808',
                name: 'gallbladder cancer',
              },
              {
                cui: 'C3658780',
                name: 'male breast cancer',
              },
              {
                cui: 'C3658752',
                name: 'lymphoma',
              },
              {
                cui: 'C3658750',
                name: 'lymphedema',
              },
              {
                cui: 'C3658746',
                name: 'lymphadenopathy',
              },
              {
                cui: 'C3658724',
                name: 'lung cancer',
              },
              {
                cui: 'C3658660',
                name: 'liver cancer',
              },
              {
                cui: 'C3658615',
                name: 'leukopenia',
              },
              {
                cui: 'C3658611',
                name: 'leukemia',
              },
              {
                cui: 'C3658557',
                name: 'large cell cancer of the lung',
              },
              {
                cui: 'C3658374',
                name: 'iron deficiency anemia',
              },
              {
                cui: 'C3658218',
                name: 'idiopathic thrombocytopenic purpura',
              },
              {
                cui: 'C3658139',
                name: 'hypercoagulable state',
              },
              {
                cui: 'C3658120',
                name: 'hurthle cell cancer',
              },
              {
                cui: 'C3658065',
                name: 'high eosinophil count',
              },
              {
                cui: 'C3658000',
                name: 'hemophilia',
              },
              {
                cui: 'C3657999',
                name: 'hemolytic uremic syndrome',
              },
              {
                cui: 'C3657976',
                name: 'hellp syndrome',
              },
              {
                cui: 'C3657927',
                name: 'hairy cell leukemia',
              },
              {
                cui: 'C3657847',
                name: 'germ cell tumor',
              },
              {
                cui: 'C3657589',
                name: 'factor nine deficiency',
              },
              {
                cui: 'C3657588',
                name: 'factor eight disorder',
              },
              {
                cui: 'C3657517',
                name: 'ewings sarcoma',
              },
              {
                cui: 'C3657479',
                name: 'er positive breast cancer',
              },
              {
                cui: 'C3657400',
                name: 'endometrial cancer',
              },
              {
                cui: 'C3657322',
                name: 'egfr mutation',
              },
              {
                cui: 'C3657044',
                name: 'cutaneous t cell lymphoma',
              },
              {
                cui: 'C3656732',
                name: 'colorectal malignancy',
              },
              {
                cui: 'C3656726',
                name: 'colon cancer chemotherapy',
              },
              {
                cui: 'C3656709',
                name: 'cns lymphoma',
              },
              {
                cui: 'C3656664',
                name: 'chronic myelocytic leukemia',
              },
              {
                cui: 'C3656663',
                name: 'chronic lymphocytic leukemia',
              },
              {
                cui: 'C3656638',
                name: 'chordoma',
              },
              {
                cui: 'C3656568',
                name: 'cervical cancer',
              },
              {
                cui: 'C3656453',
                name: 'carcinoid tumor',
              },
              {
                cui: 'C3656439',
                name: 'cancer survivor',
              },
              {
                cui: 'C3656436',
                name: 'cancer pain',
              },
              {
                cui: 'C3656432',
                name: 'skin cancer',
              },
              {
                cui: 'C3656427',
                name: 'cancer genomics',
              },
              {
                cui: 'C3656425',
                name: 'adjuvant chemotherapy',
              },
              {
                cui: 'C3656422',
                name: 'cancer chemotherapy',
              },
              {
                cui: 'C3656421',
                name: 'cancer',
              },
              {
                cui: 'C3656402',
                name: 'burkitt lymphoma',
              },
              {
                cui: 'C3656341',
                name: 'breast cancer follow up',
              },
              {
                cui: 'C3656337',
                name: 'breast cancer chemotherapy',
              },
              {
                cui: 'C3656278',
                name: 'blood clotting disorder',
              },
              {
                cui: 'C3656268',
                name: 'bleeding disorder',
              },
              {
                cui: 'C3656209',
                name: 'cholangiocarcinoma',
              },
              {
                cui: 'C3656156',
                name: 'basal cell carcinoma',
              },
              {
                cui: 'C3656120',
                name: 'b12 deficiency anemia',
              },
              {
                cui: 'C3655870',
                name: 'anemia',
              },
              {
                cui: 'C3655848',
                name: 'anal carcinoma in situ',
              },
              {
                cui: 'C3655847',
                name: 'anal cancer',
              },
              {
                cui: 'C3655844',
                name: 'amyloidosis',
              },
              {
                cui: 'C3655752',
                name: 'adrenal mass',
              },
              {
                cui: 'C3655751',
                name: 'adrenal neoplasm',
              },
            ],
          },
          clinical_trial_text: null,
          clinical_trials: [],
          contact_info: null,
          contacts: [
            {
              contact_type: 'phone',
              extension: null,
              subtype: null,
              value: '724-123-4567',
            },
            {
              contact_type: 'fax',
              extension: null,
              subtype: null,
              value: '412-123-4567',
            },
            {
              contact_type: 'clinical_contact_info',
              extension: null,
              subtype: null,
              value: null,
            },
          ],
          date_of_birth: null,
          dea_number: 'FH0005252',
          default_timezone: 'UTC',
          degrees: [
            {
              name: 'MD',
              source: 'MD',
              source_url: null,
            },
          ],
          department_name: null,
          departments: ['Providers'],
          direct_book_capable: false,
          direct_book_pmac: false,
          direct_book_pmc: true,
          division: null,
          ehr_platform: null,
          entity_type: null,
          external_id: '5A047914-0FA5-40B7-92FE-5366FFEB75DC',
          external_links: null,
          external_systems: [
            {
              provider_id: '1000224',
              source_system: 'epic',
              system_type: 'epic',
            },
          ],
          gender: 'Female',
          google_category: null,
          graduate_education: [],
          grants: [],
          gx: {
            max_age: 43800,
            min_age: 7665,
          },
          has_video_url: false,
          id: 3846532,
          image_object: null,
          image_url: null,
          institutes: null,
          insurance_accepted: [],
          internal_notes: null,
          is_primary_care: false,
          is_specialty_care: true,
          ksched_appointment_request_email: null,
          language_disclaimer: null,
          languages: ['Urdu', 'English'],
          leadership_title: 'President of Medicine',
          legal: [],
          lgbtq_inclusive: false,
          license: [],
          listings_syndication: null,
          logo: 'Display Logo',
          manually_created: null,
          marketing_campaign_name: null,
          media_mentions: [],
          medical_license: 'MD427892',
          multi_resource_scheduling: null,
          name: {
            first: 'Bushra',
            first_last: 'Bushra Haq',
            full: 'Bushra Haq',
            last: 'Haq',
            middle: null,
            preferred: 'Bushra',
          },
          name_pronunciation: null,
          near_term_availability: null,
          network_affiliations: [
            {
              name: 'Allegheny Valley Hospital',
              rank: null,
              type: 'Hospital',
            },
            {
              name: 'Canonsburg Hospital',
              rank: null,
              type: 'Hospital',
            },
            {
              name: 'West Penn Hospital',
              rank: null,
              type: 'Hospital',
            },
            {
              name: 'Wexford Hospital',
              rank: null,
              type: 'Hospital',
            },
          ],
          networks: ['Customer Network'],
          new_pt_online_booking: false,
          night_weekend_availability: false,
          notes: null,
          npi: '1780827857',
          office_hours_text: null,
          open_scheduling: false,
          personal_interests: null,
          philosophy_of_care: null,
          pmc_db_only_provider: null,
          pmc_db_only_provider_flag: false,
          practice_groups: [
            {
              has_info: false,
              id: 85217,
              is_primary_care: false,
              name: 'Providers',
            },
          ],
          professional_memberships: null,
          professional_statement: null,
          pronouns: null,
          provider_business_description: null,
          provider_email: null,
          provider_is_employed: true,
          provider_is_location: false,
          provider_title: null,
          provider_type: 'Physician',
          publication_text: null,
          publication_url: 'https://www.ncbi.nlm.nih.gov/pubmed?term=Haq%20B',
          publications: [],
          publish_to_brandify: null,
          rating: {
            average: 4.9,
            count: 304,
          },
          ratings: {},
          region: null,
          request_appointment_url: null,
          research_interest: null,
          reviews: {
            aggregate_ratings: {
              average_rating: 4.9,
              rating_count: 304,
              review_count: 22,
              sub_ratings: [
                {
                  average_rating: 4.9,
                  crawled_date: '2025-03-18',
                  metric: 'CP explanations of prob/condition',
                },
                {
                  average_rating: 4.9,
                  crawled_date: '2025-03-18',
                  metric: 'CP concern for questions/worries',
                },
                {
                  average_rating: 4.9,
                  crawled_date: '2025-03-18',
                  metric: 'Provider included you in decisions',
                },
                {
                  average_rating: 4.9,
                  crawled_date: '2025-03-18',
                  metric: 'Likelihood of recommending CP',
                },
                {
                  average_rating: 4.8,
                  crawled_date: '2025-03-18',
                  metric:
                    "Care provider's discussion of any proposed treatment (options, risks, benefits, etc.)",
                },
              ],
            },
            reviews: [
              {
                body: 'Very kind, professional & knowledgeable.',
                rating: 5,
                review_date: '2024-12-31',
                review_year: 2024,
                source_url: '',
              },
              {
                body: 'This staff and doctor are the best Doctor is very thorough',
                rating: 5,
                review_date: '2024-12-27',
                review_year: 2024,
                source_url: '',
              },
              {
                body: 'Everything is fine.',
                rating: 4.6,
                review_date: '2024-12-23',
                review_year: 2024,
                source_url: '',
              },
              {
                body: 'All comments are good. I could not be happier with this doctor or anyone in the office.',
                rating: 5,
                review_date: '2024-12-19',
                review_year: 2024,
                source_url: '',
              },
              {
                body: 'All good',
                rating: 5,
                review_date: '2024-10-21',
                review_year: 2024,
                source_url: '',
              },
              {
                body: 'One word describes Dr. Haq - "tremendous!"',
                rating: 5,
                review_date: '2024-10-21',
                review_year: 2024,
                source_url: '',
              },
              {
                body: 'Good',
                rating: 5,
                review_date: '2024-09-29',
                review_year: 2024,
                source_url: '',
              },
              {
                body: 'Dr Haq took her time with me.  I had to start a new medication and she explained it thoroughly.',
                rating: 5,
                review_date: '2024-09-15',
                review_year: 2024,
                source_url: '',
              },
              {
                body: 'Good info.',
                rating: 4.4,
                review_date: '2024-08-16',
                review_year: 2024,
                source_url: '',
              },
              {
                body: 'Good',
                rating: 5,
                review_date: '2024-08-08',
                review_year: 2024,
                source_url: '',
              },
              {
                body: 'I never dread this treatment',
                rating: 5,
                review_date: '2024-08-05',
                review_year: 2024,
                source_url: '',
              },
              {
                body: "Always concerned & helpful.  She's great!",
                rating: 5,
                review_date: '2024-08-05',
                review_year: 2024,
                source_url: '',
              },
              {
                body: 'She was always listening to me talk',
                rating: 5,
                review_date: '2024-08-01',
                review_year: 2024,
                source_url: '',
              },
              {
                body: 'Dr Haq is an outstanding physician.',
                rating: 5,
                review_date: '2024-07-29',
                review_year: 2024,
                source_url: '',
              },
              {
                body: 'Good',
                rating: 5,
                review_date: '2024-07-22',
                review_year: 2024,
                source_url: '',
              },
              {
                body: 'Good',
                rating: 5,
                review_date: '2024-07-21',
                review_year: 2024,
                source_url: '',
              },
              {
                body: 'Dr. Haq is very concerned, comforting and professional. She always welcomes questions and answers them in an understandable way.',
                rating: 5,
                review_date: '2024-07-16',
                review_year: 2024,
                source_url: '',
              },
              {
                body: 'She is very kind, caring and compassionate.',
                rating: 5,
                review_date: '2024-06-13',
                review_year: 2024,
                source_url: '',
              },
              {
                body: 'I have had very good experience with this doctors office and the staff and the doctor',
                rating: 5,
                review_date: '2024-05-08',
                review_year: 2024,
                source_url: '',
              },
              {
                body: "Love Dr. Haq...  She's amazing.",
                rating: 5,
                review_date: '2024-04-29',
                review_year: 2024,
                source_url: '',
              },
              {
                body: 'Dr Haq was exceptional.',
                rating: 5,
                review_date: '2024-04-06',
                review_year: 2024,
                source_url: '',
              },
              {
                body: 'Good',
                rating: 5,
                review_date: '2024-03-24',
                review_year: 2024,
                source_url: '',
              },
            ],
            source: 'Press Ganey',
            source_url: 'https://api1.consumerism.pressganey.com/api/bsr/comments',
          },
          sched_interval_duration_days: null,
          sched_interval_same_day_minute_delay: null,
          sched_interval_start_days: null,
          scheduling_notes: null,
          section: null,
          service_line: null,
          show_cta_modal: 'Yes',
          show_in_pmac: 'Yes',
          show_in_pmc: 'Yes',
          sort_field: '1',
          sort_preferences: {},
          source_specialty: 'Hematology,Medical Oncology',
          special_program: null,
          specialties: [
            {
              eui: 'E0000890',
              name: 'Hematology Oncology',
            },
          ],
          status_transitions: null,
          suffix: null,
          surgical_status: null,
          syndicate_to_google: false,
          system_id: null,
          telehealth: false,
          telehealth_badge: false,
          training: [
            {
              city: null,
              country: null,
              degree: null,
              field_of_study: null,
              graduation_date: null,
              graduation_year: '1998',
              name: 'Baqui Medical University Baqai Medical College',
              rank: null,
              state: null,
              street1: null,
              street2: null,
              type: 'Education',
              zip: null,
            },
            {
              city: null,
              country: null,
              degree: null,
              field_of_study: 'Internal Medicine',
              graduation_date: null,
              graduation_year: '2005',
              name: 'West Penn Hospital',
              rank: null,
              state: null,
              street1: null,
              street2: null,
              type: 'Residency',
              zip: null,
            },
            {
              city: null,
              country: null,
              degree: null,
              field_of_study: 'Internal Medicine',
              graduation_date: null,
              graduation_year: '2003',
              name: 'Conemaugh Health System',
              rank: null,
              state: null,
              street1: null,
              street2: null,
              type: 'Internship',
              zip: null,
            },
            {
              city: null,
              country: null,
              degree: null,
              field_of_study: 'Hematology/Oncology',
              graduation_date: null,
              graduation_year: '2009',
              name: 'West Penn Hospital',
              rank: null,
              state: null,
              street1: null,
              street2: null,
              type: 'Fellowship',
              zip: null,
            },
          ],
          urgent_care_location: false,
          video_url: null,
          video_visit: false,
          virtual_care: false,
          virtual_care_url: null,
          walkins_welcome: false,
          web_phone_number: null,
          xp_provider_group: null,
          years_in_practice: null,
        },
        provider_id: 3846532,
        sort: [
          {
            relevance: 0.0,
          },
          {
            shuffle: 831869207.0,
          },
        ],
      },
    ],
    facets: [],
    geocoded_location: {},
    global_availability: {},
    interpretation: [],
    messages: {
      distance_expansion: 'not used',
      nlp_actions: {},
      search_alerts: [],
    },
    suggestions: {},
    warnings: {
      informational: [],
      warnings: [],
    },
  },
];
