import { CommonModule } from '@angular/common';
import { ahnTextPath } from '../ahn-text/ahn-text.enum';
import { Component, Input, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { providerDetailV2 } from '../../pages/provider-detail-page/provider-detail_v2';

@Component({
  selector: 'engen-profile-referral-tab-v2',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './profile-referral-tab-v2.component.html',
  styleUrl: './profile-referral-tab-v2.component.scss',
})
export class ProfileReferralTabV2Component implements OnInit {
  @Input() provider: providerDetailV2;
  profileName: string;
  formPath: string;

  constructor() {}

  ngOnInit() {
    if (this.provider) {
      this.profileName =
        '/' + this.provider.provider.name.first + '-' + this.provider.provider.name.last;
      this.formPath = ahnTextPath.referralForm.toString().substring(1); // chop the slash off the enum for router encoding
    }
  }
}
