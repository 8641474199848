/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { isPlatformBrowser } from '@angular/common';
import { Component, OnInit, Input, Inject, PLATFORM_ID } from '@angular/core';
import { FadProviderDetailResponse } from '@dmx-fad-ui/fad/data-access';
import { environment } from '../../../../environments/environment';
import { LocationService } from '../../services/location/location-service';
import { StyleObservablesService } from '../../services/style-observables/style-observables.service';
import { CommentsAndRatings } from './provider-ratings-comments/commentsAndRatings';
import { SimpleUtilsService } from '@dmx-fad-ui/fad/services';
import { providerDetailV2 } from '../../pages/provider-detail-page/provider-detail_v2';
import { Expertise } from '../provider-v2/provider-expertise-v2/expertise';
import { Credentials } from '../provider-v2/provider-credentials-v2/credentials';
import { BoardCerts } from '../provider-v2/provider-credentials-v2/boardCerts';
import { AcceptedInsurance } from '../provider-v2/accepted-insurance-v2/acceptedInsurance';
import { ProviderContact } from '../provider-v2/profile-banner-v2/phoneNumber';
import { Locations } from '../profile-locations-tab-v2/locations';
//import { ProviderMap } from '../provider-results/provider-map-v2/map';

@Component({
  selector: 'atlas-provider',
  templateUrl: './provider.component.html',
  styleUrls: ['./provider.component.scss'],
})
export class ProviderComponent implements OnInit {
  @Input() providerDetails: FadProviderDetailResponse;
  @Input() providerDetails_V2?: providerDetailV2;

  @Input() providerName: string;
  commentsAndRatings: CommentsAndRatings;
  mapUrl: string;
  providerDetailsResults;
  providerExpertiseResults: any;
  providerLanguageResults: any;
  hasData: boolean = false;
  bio: string;
  youTubeVideoBioUrl: string;
  providerLocations: Array<string>;
  pubMedLinkUrl: string;
  boards: Array<string>;
  fellowships: Array<string>;
  jobTitles: Array<string>;
  residencies: Array<string>;
  schools: Array<string>;
  npi: string;
  visitTypes: string[];
  lgbtqAligned: boolean = false;
  referralsOn: boolean;
  showReferral: boolean = false;
  showReview: boolean = false;
  defaultMapHeight: string = '410';
  referralMapHeight: string = '240';
  mapHeight: string;
  displayName: string;
  videoFilterEnabled: boolean;
  checkProviderBio: boolean = true;
  isNewproviderProfileExperience = environment.isTabProviderPageEnabled;
  selectedTabindex: number = 0;
  isV2ProviderBanner: boolean = environment.kyruusFeatures.providerPage.isV2ProfileBanner;
  isV2AboutTab: boolean = environment.kyruusFeatures.providerPage.isV2AboutTab;
  isV2LocationTab: boolean = environment.kyruusFeatures.providerPage.isV2LocationTab;
  isV2ReviewsTab: boolean = environment.kyruusFeatures.providerPage.isV2ReviewsTab;
  isV2ReferralTab: boolean = environment.kyruusFeatures.providerPage.isV2ReferralTab;
  isUsingMockData: boolean = environment.kyruusFeatures.isUsingMockData;
  isUsingV2DetailEndpoints: boolean =
    environment.kyruusFeatures.providerPage.isUsingV2DetailEndpoint;
  aboutProvider: string = undefined;
  ageGroups: string[];
  spokenLanguages: string[];
  providerExpertise: Expertise[] = [];
  providerCredentials: Credentials[] = [];
  providerBoardCerts: BoardCerts[] = [];
  providerLeaderTitle: any;
  providerAcceptedInsurance: AcceptedInsurance[] = [];
  providerPublications: any;
  providerRatings: string[];
  providerComments: string[];
  providerFullName: string;
  providerSpecialty: string;
  providerPhoneNumber: ProviderContact[] = [];
  providerKyruusLocations: Locations[] = [];

  locationObj: any[] = [];
  coordinateObj: any[] = [];
  //providerMap: ProviderMap[];

  constructor(
    private locationService: LocationService,
    private simpleUtilsService: SimpleUtilsService,
    @Inject(PLATFORM_ID) private platformId: string,
  ) {
    this.locationService = locationService;
    this.referralsOn = environment.referralsOn;
    this.mapHeight = this.defaultMapHeight;
    this.videoFilterEnabled = environment.videoFilterEnabled;
  }

  ngOnInit(): void {
    console.log('PROVIDER DETAIL_V2 1');
    console.log(this.providerDetails);

    if (
      this.providerDetails &&
      this.isUsingMockData == false &&
      this.isUsingV2DetailEndpoints === false
    ) {
      console.log('PROVIDER DETAIL_V1 1');
      console.log(this.providerDetails);
      this.providerDetailsResults = this.providerDetails.payload.providerDetail;
      this.mapUrl = this.providerDetailsResults.mapURL;
      this.providerExpertiseResults = this.providerDetailsResults.conditions;
      this.providerLanguageResults = this.providerDetailsResults.languages;
      this.bio = this.providerDetailsResults.bio;
      this.providerDetailsResults = this.providerDetails.payload.providerDetail;
      this.mapUrl = this.providerDetailsResults.mapURL;
      this.providerExpertiseResults = this.providerDetailsResults.conditions;
      this.providerLanguageResults = this.providerDetailsResults.languages;
      this.residencies = this.providerDetailsResults.residencies;
      this.schools = this.providerDetailsResults.schools;
      this.boards = this.providerDetailsResults.boards;
      this.fellowships = this.providerDetailsResults.fellowships;
      this.jobTitles = this.providerDetailsResults.jobTitles;
      this.bio = this.providerDetailsResults.bio;

      if (this.bio === '<p></p>') {
        this.bio = '';
      }

      this.youTubeVideoBioUrl = this.providerDetailsResults.youTubeVideoBioUrl;

      if (
        this.bio == '' &&
        this.youTubeVideoBioUrl == '' &&
        this.providerLanguageResults.length == 0
      ) {
        this.checkProviderBio = false;
      }

      this.commentsAndRatings = this.providerDetails.payload.providerDetail.commentsAndRatings;
      this.pubMedLinkUrl = this.providerDetailsResults.pubMedLinkUrl;

      if (this.providerDetailsResults.locations) {
        this.providerLocations = this.getLocationResults(this.providerDetailsResults.locations);
      }

      this.lgbtqAligned = JSON.parse(this.providerDetailsResults.lgbtqAligned);

      if (this.providerDetailsResults.reviewRatingCount != 0) {
        this.showReview = true;
      }

      this.npi = this.providerDetailsResults.npi;
      this.visitTypes = this.providerDetailsResults.visitTypes;
      this.hasData = true;
      this.showReferral = this.parseTitles(
        this.providerDetailsResults.displayName,
        this.providerDetailsResults.outPatientEnabled,
      );
      if (this.showReferral && !this.isNewproviderProfileExperience) {
        this.mapHeight = this.referralMapHeight;
      }
      this.displayName = this.providerDetailsResults.displayName;
    } else if (this.providerDetails_V2) {
      if (this.providerDetails_V2.provider.reviews != 0) {
        this.showReview = true;
      }

      this.mapHeight = this.referralMapHeight;
      // this.providerKyruusLocations = this.locationObj.map(loc => ({ // Create structured objects for ProviderMapComponent
      //   position: { lat: loc.lat, lng: loc.long },
      //   title: loc.name || 'Location',
      //   icon: { url: '/assets/place.svg', labelOrigin: { x: 27, y: 19 } },
      //   label: { color: 'white', text: null },
      //   info: this.createInfoWindowContent(loc)
      // })) as ProviderMap[]; // Type assertion; now this.providerKyruusLocations is MapMarkerData[]
      // console.log('Provider Map:')
      // console.log(this.locationObj.)

      if (this.providerDetails_V2.provider.reviews != 0) {
        this.showReview = true;
      }

      this.showReferral = this.parseTitle2(this.providerDetails_V2.provider.open_scheduling);

      this.aboutProvider = this.providerDetails_V2.provider.about_the_provider;
      this.ageGroups = this.providerDetails_V2.provider.age_groups_seen;
      this.spokenLanguages = this.providerDetails_V2.provider.languages;
      this.providerExpertise = this.providerDetails_V2.provider.specialties;
      this.providerCredentials = this.providerDetails_V2.provider.training;
      this.providerDetails_V2.provider.board_certifications.forEach((bc) => {
        this.providerBoardCerts.push(
          new BoardCerts(bc.certification_type, bc.board_name, bc.specialty_name),
        );
      });
      this.providerBoardCerts.forEach((bc) => {
        this.providerCredentials.push(
          new Credentials(bc.certification_type, bc.board_name, bc.specialty_name),
        );
      });

      this.providerLeaderTitle = this.providerDetails_V2.provider.leadership_title;
      this.providerCredentials.push(new Credentials('Position', this.providerLeaderTitle));
      this.providerAcceptedInsurance = this.providerDetails_V2.provider.insurance_accepted;
      this.providerDetails_V2.locations.forEach((loc: any) => {
        console.log(loc);
        this.locationObj.push(loc);
      });
      this.providerKyruusLocations = this.locationObj;

      this.locationObj = this.locationObj.map((loc) => {
        return new Locations(loc.lat, loc.long, loc.coordinates);
      });
      console.log('New locations: ');
      console.log(this.locationObj);
      console.log(this.coordinateObj);
      console.log(this.providerKyruusLocations);

      this.providerPublications = this.providerDetails_V2.provider.publication_url;
      this.providerRatings = this.providerDetails_V2.provider.ratings;
      this.providerComments = this.providerDetails_V2.provider.comments;
      this.providerFullName = this.providerDetails_V2.provider.name.full;
      this.providerSpecialty = this.providerDetails_V2.provider.source_specialty;
      this.providerPhoneNumber = this.providerDetails_V2.provider.contacts;
    }

    this.simpleUtilsService.isShowProviderReviewsTab.subscribe((tabValue) => {
      if (tabValue) {
        this.selectedTabindex = 2;
      } else {
        this.selectedTabindex = 0;
      }
    });
  }

  createInfoWindowContent(loc: Locations): string {
    // Use Locations type for loc
    return `<div>
              <h3>${loc.name || 'Location'}</h3>
              <p>${loc.street1}, ${loc.city}, ${loc.state} ${loc.zip}</p>
              <p>Phone: ${loc.phone}</p>
              <p>Fax: ${loc.fax}</p>
              <a href="${loc.url}" target="_blank">Website</a> </div>`;
  }

  parseTitles(displayName: string, outpatientEnabled: string): boolean {
    // Necessary because outpatient enabled comes back as a string 'false'

    if (displayName.includes(', MD') || displayName.includes(', DO')) {
      if (outpatientEnabled !== 'false') {
        return true;
      }
    }

    return false;
  }

  //TODO determine if open_scheduling is appropriate bool, or if we need to addtionally check for MD, DO names from degrees
  parseTitle2(open_scheduling: boolean) {
    // if ( (open_scheduling === false)  ) {
    // return true;
    // }
    // return false;
    return false;
  }

  getLocationResults(locationResultsArray) {
    const locations: Array<any> = [];
    const pracBuildings = locationResultsArray;
    pracBuildings.forEach((item) => {
      const providerPracticeBuildingObj =
        this.locationService.transformPracticeBuildingStringToObject(item);
      locations.push(providerPracticeBuildingObj);
    });
    return locations;
  }

  isBrowser() {
    return (
      isPlatformBrowser(this.platformId) &&
      'google' in window &&
      typeof google === 'object' &&
      typeof google.maps === 'object'
    );
  }
}
