export const search_result_mock = {
  _metadata: {
    intent_classification: null,
    intent_matching_enabled: false,
    max_date_available: null,
    min_date_available: null,
    provider_count: 10,
    response_time: 66,
    slot_count: 0,
    total_providers: 101,
    tz_max_date_available: null,
    tz_min_date_available: null,
  },
  _result: [
    {
      availability: {
        facets: null,
        meta: {
          availability_density_best: null,
          max_date_available: null,
          min_date_available: null,
          slot_count: 0,
          tz_max_date_available: null,
          tz_min_date_available: null,
        },
        slots: [],
      },
      customer: 'ahn',
      locations: [
        {
          associated_marketable_location_ids: [],
          city: 'Pittsburgh',
          commercial_entity_name: null,
          contacts: [],
          coordinates: { lat: 40.457054, lon: -80.003626 },
          ehr_location_id: '10457005122',
          email: null,
          external_id: '10457005122',
          external_organization_id: null,
          facility_fee: false,
          fax: '412-359-6494',
          id: 4165473,
          location_page_override_url: null,
          name: 'AGH McGinnis Cardiovascular Institute',
          networks: [],
          office_hours: null,
          phone: '412-359-3550',
          primary_marketable_location_id: null,
          rank: 1,
          source_system: 'epic',
          source_system_type: 'epic',
          state: 'PA',
          street1: '320 East North Avenue',
          street2: '',
          suite: '',
          timezone: 'UTC',
          type: 'CLI',
          zip: '15212',
        },
      ],
      match: {
        highlight: ['<em>Cardiology</em>'],
        score: 13,
        type: 'Specialty Exact Match',
      },
      provider: {
        about_the_provider:
          'Margaret Grimm, PA-C     Margaret Grimm, PA-C, is a physician assistant with AHN Cardiovascular Institute. She specializes in general cardiology, seeing patients with coronary, artery disease, high, blood pressure, high cholesterol, atrial fibrillation, heart, failure, and valvular abnormalities, as well as post-procedural care of TAVR patients.    She did her Bachelor of Science at University of Wisconsin \u2014 Madison and her Master of Physician Assistant Studies at University of Wisconsin \u2014 La Crosse. Margaret Grimm has additional clinical training at Mayo Clinic.    She is certified by the National Commission on Certification of Physician Assistants and is a member of the American Association of Physician Assistants. Margaret values providing evidence-based, patient-focused care. She strives to communicate effectively, provide education, and allow for shared decision-making in patient care to promote overall cardiovascular health. Margaret Grimm welcomes patients ages 18 and older.  ',
        academic_department_name: null,
        academic_title: null,
        accepting_new_patients: true,
        access_program_status: null,
        additional_services: null,
        additional_visit_types: null,
        age_groups_seen: ['Adult 21-65', 'Older Adult >65'],
        aha_id: null,
        always_available_provider: false,
        appointment_ehr_purposes: [],
        awards: null,
        board_certifications: [],
        book_online_override_url: null,
        clinic_location_url: null,
        clinical_contact: null,
        clinical_interests: null,
        clinical_keywords: {
          promoted: [],
          searchable: [
            { cui: 'C3680038', name: 'cardiogenic syncope' },
            { cui: 'C3679884', name: 'familial hypertension' },
            {
              cui: 'C3679866',
              name: 'myocardial infarction with non-obstructive coronary artery disease',
            },
            { cui: 'C3675846', name: 'chest pain with exercise' },
            { cui: 'C3675086', name: 'arrhythmia' },
            { cui: 'C3673288', name: 'echocardiogram' },
            { cui: 'C3673286', name: 'familial amyloidosis' },
            { cui: 'C3673279', name: 'familial cardiomyopathy' },
            { cui: 'C3672710', name: 'heart failure screening' },
            { cui: 'C3672616', name: 'microvascular dysfunction' },
            { cui: 'C3671643', name: 'inflammatory heart diseases' },
            { cui: 'C3671321', name: 'cardiac arrest' },
            {
              cui: 'C3671144',
              name: 'anomalous left coronary artery from the pulmonary artery (alcapa)',
            },
            { cui: 'C3671143', name: 'anomalous coronary artery (aca) repair' },
            { cui: 'C3670717', name: 'arteriosclerosis' },
            { cui: 'C3670342', name: 'multi system inflammatory syndrome' },
            { cui: 'C3670163', name: 'exercise induced arrhythmia' },
            { cui: 'C3670161', name: 'inappropriate sinus tachycardia' },
            { cui: 'C3670159', name: 'exercise induced syncope' },
            { cui: 'C3669688', name: 'variant angina' },
            { cui: 'C3669687', name: 'vasospastic angina' },
            { cui: 'C3669635', name: 'left ventricular dysfunction' },
            { cui: 'C3669491', name: 'heart valve disease' },
            { cui: 'C3669489', name: 'coronary artery calcification' },
            { cui: 'C3669485', name: 'cardiopulmonary disease' },
            {
              cui: 'C3667902',
              name: 'sports related cardiovascular assessment',
            },
            { cui: 'C3667658', name: 'valvular heart disease' },
            { cui: 'C3666968', name: 'left ventricular hypertrophy' },
            {
              cui: 'C3666782',
              name: 'arrhythmogenic right ventricular cardiomyopathy',
            },
            { cui: 'C3666443', name: 'heart disease in women' },
            { cui: 'C3666436', name: 'tachycardia bradycardia syndrome' },
            { cui: 'C3666374', name: 'single ventricle defect' },
            { cui: 'C3666371', name: 'fibromuscular dysplasia' },
            { cui: 'C3666368', name: 'aortic valve sclerosis' },
            { cui: 'C3666282', name: 'left heart failure' },
            { cui: 'C3666222', name: 'cardiac insufficiency' },
            { cui: 'C3666190', name: 'abnormal coronary angiography' },
            {
              cui: 'C3666122',
              name: 'transposition of the great arteries (tga)',
            },
            { cui: 'C3665557', name: 'prosthetic heart valves' },
            { cui: 'C3665458', name: 'heart disease' },
            { cui: 'C3665328', name: 'aortic stenosis' },
            { cui: 'C3665320', name: 'edema' },
            { cui: 'C3665204', name: 'electrocardiogram (ekg)' },
            { cui: 'C3663857', name: 'heart health' },
            { cui: 'C3663837', name: 'cardiovascular care' },
            { cui: 'C3663709', name: 'peripheral edema' },
            { cui: 'C3663107', name: 'exercise intolerance' },
            { cui: 'C3663039', name: 'pre excitation syndrome' },
            { cui: 'C3663037', name: 'premature atrial contraction' },
            { cui: 'C3662984', name: 'atrioventricular septal defect' },
            { cui: 'C3662828', name: 'cardiorenal syndrome' },
            { cui: 'C3662822', name: 'hypertensive urgency' },
            { cui: 'C3662821', name: 'uncontrolled hypertension' },
            { cui: 'C3662626', name: 'pericardial effusion' },
            { cui: 'C3662574', name: 'valvular endocarditis' },
            { cui: 'C3662573', name: 'acute bacterial endocarditis' },
            { cui: 'C3662519', name: 'abnormal stress test' },
            { cui: 'C3662501', name: 'abnormal electrocardiogram (ekg)' },
            { cui: 'C3662218', name: 'genetic cardiomyopathy' },
            { cui: 'C3662064', name: 'right heart failure' },
            { cui: 'C3662020', name: 'preoperative evaluation' },
            { cui: 'C3661991', name: 'orthopnea' },
            { cui: 'C3661932', name: 'mitral valve regurgitation' },
            { cui: 'C3661746', name: 'cholesterol management' },
            { cui: 'C3661737', name: 'cardiac tumor' },
            { cui: 'C3661490', name: 'atrial septal defect (asd)' },
            { cui: 'C3661407', name: 'cardiovascular disease' },
            { cui: 'C3661356', name: 'wolff-parkinson-white syndrome' },
            { cui: 'C3661263', name: 'vasovagal syncope' },
            { cui: 'C3661240', name: 'valve stenosis' },
            { cui: 'C3661043', name: 'type 2 second degree heart block' },
            { cui: 'C3661041', name: 'type 1 second degree heart block' },
            { cui: 'C3661005', name: 'tricuspid valve disease' },
            { cui: 'C3661001', name: 'tricuspid regurgitation' },
            { cui: 'C3660977', name: 'transposition of great vessels' },
            { cui: 'C3660846', name: 'thrombosis' },
            { cui: 'C3660762', name: 'tachycardia' },
            { cui: 'C3660745', name: 'syncope' },
            { cui: 'C3660724', name: 'supraventricular tachycardia' },
            { cui: 'C3660711', name: 'family history of sudden cardiac death' },
            { cui: 'C3660692', name: 'subaortic stenosis' },
            { cui: 'C3660461', name: 'sick sinus syndrome' },
            { cui: 'C3660384', name: 'secondary hypertension' },
            { cui: 'C3660274', name: 'right ventricular hypertrophy' },
            { cui: 'C3660267', name: 'right bundle branch block' },
            { cui: 'C3660255', name: 'rheumatic mitral stenosis' },
            { cui: 'C3660254', name: 'rheumatic mitral regurgitation' },
            { cui: 'C3660252', name: 'rheumatic heart disease' },
            { cui: 'C3660251', name: 'rheumatic fever' },
            { cui: 'C3660250', name: 'rheumatic diseases of pulmonary valve' },
            { cui: 'C3660249', name: 'rheumatic aortic stenosis' },
            { cui: 'C3660248', name: 'rheumatic aortic regurgitation' },
            { cui: 'C3660130', name: 'renovascular hypertension' },
            { cui: 'C3660081', name: 'refractory angina' },
            { cui: 'C3659982', name: 'pulmonary valve stenosis' },
            { cui: 'C3659981', name: 'pulmonary valve insufficiency' },
            { cui: 'C3659980', name: 'pulmonary valve disorders' },
            { cui: 'C3659968', name: 'pulmonary edema' },
            { cui: 'C3659964', name: 'pulmonary artery stenosis' },
            { cui: 'C3659957', name: 'pulmonary arterial hypertension' },
            { cui: 'C3659882', name: 'prinzmetals angina' },
            { cui: 'C3659873', name: 'preventative cardiology' },
            { cui: 'C3659861', name: 'premature ventricular contractions' },
            { cui: 'C3659857', name: 'premature beats' },
            { cui: 'C3659846', name: 'postural tachycardia syndrome' },
            { cui: 'C3659641', name: 'pericarditis' },
            { cui: 'C3659567', name: 'patent foramen ovale' },
            { cui: 'C3659566', name: 'patent ductus arteriosus' },
            {
              cui: 'C3659544',
              name: 'partial anomalous pulmonary venous connection (papvc)',
            },
            { cui: 'C3659542', name: 'paroxysmal ventricular tachycardia' },
            { cui: 'C3659541', name: 'paroxysmal tachycardia' },
            {
              cui: 'C3659540',
              name: 'paroxysmal supraventricular tachycardia',
            },
            { cui: 'C3659479', name: 'palpitations' },
            { cui: 'C3659421', name: 'orthostatic hypotension' },
            { cui: 'C3659153', name: 'myocarditis' },
            { cui: 'C3659151', name: 'myocardial infarction (mi)' },
            { cui: 'C3659022', name: 'mitral valve stenosis' },
            { cui: 'C3659019', name: 'mitral valve prolapse' },
            { cui: 'C3659017', name: 'mitral valve disease' },
            { cui: 'C3659016', name: 'mitral regurgitation' },
            { cui: 'C3658858', name: 'maternal hypertension' },
            { cui: 'C3658843', name: 'marfan syndrome' },
            { cui: 'C3658797', name: 'malignant hypertension' },
            { cui: 'C3658586', name: 'left bundle branch block' },
            { cui: 'C3658565', name: 'laser atherectomy' },
            { cui: 'C3658378', name: 'ischemic cardiomyopathy' },
            { cui: 'C3658358', name: 'intravascular ultrasound' },
            { cui: 'C3658326', name: 'intra aortic balloon pump' },
            { cui: 'C3658272', name: 'infiltrative disorders of the heart' },
            { cui: 'C3658242', name: 'implantable defibrillator' },
            {
              cui: 'C3658215',
              name: 'idiopathic hypertrophic subaortic stenosis',
            },
            { cui: 'C3658165', name: 'hypertrophic cardiomyopathy' },
            { cui: 'C3658158', name: 'hypertension' },
            { cui: 'C3657969', name: 'heart racing' },
            { cui: 'C3657968', name: 'heart problems' },
            { cui: 'C3657967', name: 'heart murmur' },
            { cui: 'C3657669', name: 'first degree heart block' },
            { cui: 'C3657612', name: 'family history of heart disease' },
            { cui: 'C3657597', name: 'familial arrhythmias' },
            { cui: 'C3657307', name: 'echocardiography' },
            { cui: 'C3657284', name: 'dyspnea on exertion' },
            { cui: 'C3657151', name: 'diabetic cardiomyopathy' },
            { cui: 'C3656923', name: 'coronary calcium scoring' },
            { cui: 'C3656921', name: 'coronary artery disease' },
            { cui: 'C3656919', name: 'anomalies of the coronary artery' },
            { cui: 'C3656844', name: 'congestive heart failure (chf)' },
            { cui: 'C3656765', name: 'conduction defects' },
            { cui: 'C3656673', name: 'chronic total occlusion' },
            { cui: 'C3656633', name: 'cholesterol disorders' },
            { cui: 'C3656603', name: 'chest pain' },
            { cui: 'C3656479', name: 'cardiomyopathy' },
            { cui: 'C3656478', name: 'cardiomegaly' },
            { cui: 'C3656477', name: 'cardiac sarcoma' },
            { cui: 'C3656475', name: 'cardiac risk assessment' },
            { cui: 'C3656473', name: 'cardiac mri' },
            { cui: 'C3656470', name: 'cardiac genetics' },
            { cui: 'C3656465', name: 'cardiac conduction defects' },
            { cui: 'C3656463', name: 'cardiac arrhythmias' },
            { cui: 'C3656462', name: 'cardiac angina' },
            { cui: 'C3656319', name: 'bradycardia' },
            { cui: 'C3656205', name: 'bicuspid aortic valve' },
            { cui: 'C3656132', name: 'bacterial endocarditis' },
            { cui: 'C3656101', name: 'autoimmune pericarditis' },
            { cui: 'C3656080', name: 'atrioventricular reentrant tachycardia' },
            {
              cui: 'C3656079',
              name: 'atrioventricular nodal reentrant tachycardia',
            },
            { cui: 'C3656078', name: 'atrioventricular block' },
            { cui: 'C3656077', name: 'atrial tachycardia' },
            { cui: 'C3656075', name: 'atrial flutter' },
            { cui: 'C3656073', name: 'atrial fibrillation' },
            { cui: 'C3656068', name: 'atherosclerosis of the aorta' },
            { cui: 'C3656066', name: 'atherosclerosis' },
            { cui: 'C3655970', name: 'aortic valve disease' },
            { cui: 'C3655969', name: 'aortic diseases' },
            { cui: 'C3655967', name: 'aortic coarctation' },
            { cui: 'C3655957', name: 'anticoagulation' },
            { cui: 'C3655845', name: 'primary systemic amyloidosis' },
            { cui: 'C3655844', name: 'amyloidosis' },
          ],
        },
        clinical_trial_text: null,
        clinical_trials: [],
        contact_info: null,
        contacts: [
          {
            contact_type: 'phone',
            extension: null,
            subtype: null,
            value: null,
          },
          { contact_type: 'fax', extension: null, subtype: null, value: null },
          {
            contact_type: 'clinical_contact_info',
            extension: null,
            subtype: null,
            value: null,
          },
        ],
        date_of_birth: null,
        dea_number: 'MG6836223',
        default_timezone: 'UTC',
        degrees: [{ name: 'PA-C', source: 'PA-C', source_url: null }],
        department_name: null,
        departments: ['Providers'],
        direct_book_capable: false,
        direct_book_pmac: false,
        direct_book_pmc: true,
        division: null,
        ehr_platform: null,
        entity_type: null,
        external_id: '7AA02AE8-44D6-44DD-A3F0-81EE207F9515',
        external_links: null,
        external_systems: [
          {
            provider_id: '1094371',
            source_system: 'epic',
            system_type: 'epic',
          },
        ],
        gender: 'Female',
        google_category: null,
        graduate_education: [],
        grants: [],
        gx: { max_age: 43800, min_age: 7665 },
        has_video_url: false,
        id: 3847737,
        image_object: null,
        image_url:
          'https://cdn-images.kyruus.com/providermatch/ahn/photos/200/grimm-margaret-1932737517.jpg',
        institutes: null,
        insurance_accepted: [],
        internal_notes: null,
        is_primary_care: true,
        is_specialty_care: true,
        ksched_appointment_request_email: null,
        language_disclaimer: null,
        languages: ['English'],
        leadership_title: null,
        legal: [],
        lgbtq_inclusive: false,
        license: [],
        listings_syndication: null,
        logo: 'Display Logo',
        manually_created: null,
        marketing_campaign_name: null,
        media_mentions: [],
        medical_license: 'MA062636',
        multi_resource_scheduling: null,
        name: {
          first: 'Margaret',
          first_last: 'Margaret Grimm',
          full: 'Margaret May Grimm',
          last: 'Grimm',
          middle: 'May',
          preferred: 'Margaret',
        },
        name_pronunciation: null,
        near_term_availability: null,
        network_affiliations: [
          { name: 'Allegheny General Hospital', rank: null, type: 'Hospital' },
        ],
        networks: ['Customer Network'],
        new_pt_online_booking: false,
        night_weekend_availability: false,
        notes: null,
        npi: '1932737517',
        office_hours_text: null,
        open_scheduling: false,
        personal_interests: null,
        philosophy_of_care: null,
        pmc_db_only_provider: null,
        pmc_db_only_provider_flag: false,
        practice_groups: [
          {
            has_info: false,
            id: 85217,
            is_primary_care: false,
            name: 'Providers',
          },
        ],
        professional_memberships: null,
        professional_statement: null,
        pronouns: null,
        provider_business_description: null,
        provider_email: null,
        provider_is_employed: true,
        provider_is_location: false,
        provider_title: null,
        provider_type: 'Physician Assistant (PA)',
        publication_text: null,
        publication_url: null,
        publications: [],
        publish_to_brandify: null,
        rating: {},
        ratings: {},
        region: null,
        request_appointment_url: null,
        research_interest: null,
        reviews: null,
        sched_interval_duration_days: null,
        sched_interval_same_day_minute_delay: null,
        sched_interval_start_days: null,
        scheduling_notes: null,
        section: null,
        service_line: null,
        show_cta_modal: 'Yes',
        show_in_pmac: 'Yes',
        show_in_pmc: 'Yes',
        sort_field: '1',
        sort_preferences: {},
        source_specialty: 'Cardiovascular Disease',
        special_program: null,
        specialties: [{ eui: 'E0000008', name: 'Cardiology' }],
        status_transitions: null,
        suffix: null,
        surgical_status: null,
        syndicate_to_google: false,
        system_id: null,
        telehealth: false,
        telehealth_badge: false,
        training: [
          {
            city: null,
            country: null,
            degree: null,
            field_of_study: 'Physician Assistant',
            graduation_date: null,
            graduation_year: '2021',
            name: 'University of Wisconsin - La Crosse',
            rank: null,
            state: null,
            street1: null,
            street2: null,
            type: 'Education',
            zip: null,
          },
        ],
        urgent_care_location: false,
        video_url: null,
        video_visit: false,
        virtual_care: false,
        virtual_care_url: null,
        walkins_welcome: false,
        web_phone_number: null,
        xp_provider_group: null,
        years_in_practice: null,
      },
      provider_id: 3847737,
      sort: [{ relevance: 13.0 }, { shuffle: 5388037.0 }],
    },
    {
      availability: {
        facets: null,
        meta: {
          availability_density_best: null,
          max_date_available: null,
          min_date_available: null,
          slot_count: 0,
          tz_max_date_available: null,
          tz_min_date_available: null,
        },
        slots: [],
      },
      customer: 'ahn',
      locations: [],
      match: {
        highlight: ['<em>Cardiology</em>'],
        score: 13,
        type: 'Specialty Exact Match',
      },
      provider: {
        about_the_provider: null,
        academic_department_name: null,
        academic_title: null,
        accepting_new_patients: true,
        access_program_status: null,
        additional_services: null,
        additional_visit_types: null,
        age_groups_seen: ['Adult 21-65', 'Older Adult >65'],
        aha_id: null,
        always_available_provider: false,
        appointment_ehr_purposes: [],
        awards: null,
        board_certifications: [
          {
            board_name: 'American Board of Internal Medicine',
            board_specialty: 'BC0000055',
            certification_type: 'board',
            certifying_board: 'BC0000007',
            rank: 1,
            specialty_name: 'Cardiovascular Disease',
            year_certified: 1989,
          },
        ],
        book_online_override_url: null,
        clinic_location_url: null,
        clinical_contact: null,
        clinical_interests: null,
        clinical_keywords: {
          promoted: [],
          searchable: [
            { cui: 'C3680038', name: 'cardiogenic syncope' },
            { cui: 'C3679884', name: 'familial hypertension' },
            {
              cui: 'C3679866',
              name: 'myocardial infarction with non-obstructive coronary artery disease',
            },
            { cui: 'C3675846', name: 'chest pain with exercise' },
            { cui: 'C3675086', name: 'arrhythmia' },
            { cui: 'C3673288', name: 'echocardiogram' },
            { cui: 'C3673286', name: 'familial amyloidosis' },
            { cui: 'C3673279', name: 'familial cardiomyopathy' },
            { cui: 'C3672710', name: 'heart failure screening' },
            { cui: 'C3672616', name: 'microvascular dysfunction' },
            { cui: 'C3671643', name: 'inflammatory heart diseases' },
            { cui: 'C3671321', name: 'cardiac arrest' },
            {
              cui: 'C3671144',
              name: 'anomalous left coronary artery from the pulmonary artery (alcapa)',
            },
            { cui: 'C3671143', name: 'anomalous coronary artery (aca) repair' },
            { cui: 'C3670717', name: 'arteriosclerosis' },
            { cui: 'C3670342', name: 'multi system inflammatory syndrome' },
            { cui: 'C3670163', name: 'exercise induced arrhythmia' },
            { cui: 'C3670161', name: 'inappropriate sinus tachycardia' },
            { cui: 'C3670159', name: 'exercise induced syncope' },
            { cui: 'C3669688', name: 'variant angina' },
            { cui: 'C3669687', name: 'vasospastic angina' },
            { cui: 'C3669635', name: 'left ventricular dysfunction' },
            { cui: 'C3669491', name: 'heart valve disease' },
            { cui: 'C3669489', name: 'coronary artery calcification' },
            { cui: 'C3669485', name: 'cardiopulmonary disease' },
            {
              cui: 'C3667902',
              name: 'sports related cardiovascular assessment',
            },
            { cui: 'C3667658', name: 'valvular heart disease' },
            { cui: 'C3666968', name: 'left ventricular hypertrophy' },
            {
              cui: 'C3666782',
              name: 'arrhythmogenic right ventricular cardiomyopathy',
            },
            { cui: 'C3666443', name: 'heart disease in women' },
            { cui: 'C3666436', name: 'tachycardia bradycardia syndrome' },
            { cui: 'C3666374', name: 'single ventricle defect' },
            { cui: 'C3666371', name: 'fibromuscular dysplasia' },
            { cui: 'C3666368', name: 'aortic valve sclerosis' },
            { cui: 'C3666282', name: 'left heart failure' },
            { cui: 'C3666222', name: 'cardiac insufficiency' },
            { cui: 'C3666190', name: 'abnormal coronary angiography' },
            {
              cui: 'C3666122',
              name: 'transposition of the great arteries (tga)',
            },
            { cui: 'C3665557', name: 'prosthetic heart valves' },
            { cui: 'C3665458', name: 'heart disease' },
            { cui: 'C3665328', name: 'aortic stenosis' },
            { cui: 'C3665320', name: 'edema' },
            { cui: 'C3665204', name: 'electrocardiogram (ekg)' },
            { cui: 'C3663857', name: 'heart health' },
            { cui: 'C3663837', name: 'cardiovascular care' },
            { cui: 'C3663709', name: 'peripheral edema' },
            { cui: 'C3663107', name: 'exercise intolerance' },
            { cui: 'C3663039', name: 'pre excitation syndrome' },
            { cui: 'C3663037', name: 'premature atrial contraction' },
            { cui: 'C3662984', name: 'atrioventricular septal defect' },
            { cui: 'C3662828', name: 'cardiorenal syndrome' },
            { cui: 'C3662822', name: 'hypertensive urgency' },
            { cui: 'C3662821', name: 'uncontrolled hypertension' },
            { cui: 'C3662626', name: 'pericardial effusion' },
            { cui: 'C3662574', name: 'valvular endocarditis' },
            { cui: 'C3662573', name: 'acute bacterial endocarditis' },
            { cui: 'C3662519', name: 'abnormal stress test' },
            { cui: 'C3662501', name: 'abnormal electrocardiogram (ekg)' },
            { cui: 'C3662218', name: 'genetic cardiomyopathy' },
            { cui: 'C3662064', name: 'right heart failure' },
            { cui: 'C3662020', name: 'preoperative evaluation' },
            { cui: 'C3661991', name: 'orthopnea' },
            { cui: 'C3661932', name: 'mitral valve regurgitation' },
            { cui: 'C3661746', name: 'cholesterol management' },
            { cui: 'C3661737', name: 'cardiac tumor' },
            { cui: 'C3661490', name: 'atrial septal defect (asd)' },
            { cui: 'C3661407', name: 'cardiovascular disease' },
            { cui: 'C3661356', name: 'wolff-parkinson-white syndrome' },
            { cui: 'C3661263', name: 'vasovagal syncope' },
            { cui: 'C3661240', name: 'valve stenosis' },
            { cui: 'C3661043', name: 'type 2 second degree heart block' },
            { cui: 'C3661041', name: 'type 1 second degree heart block' },
            { cui: 'C3661005', name: 'tricuspid valve disease' },
            { cui: 'C3661001', name: 'tricuspid regurgitation' },
            { cui: 'C3660977', name: 'transposition of great vessels' },
            { cui: 'C3660846', name: 'thrombosis' },
            { cui: 'C3660762', name: 'tachycardia' },
            { cui: 'C3660745', name: 'syncope' },
            { cui: 'C3660724', name: 'supraventricular tachycardia' },
            { cui: 'C3660711', name: 'family history of sudden cardiac death' },
            { cui: 'C3660692', name: 'subaortic stenosis' },
            { cui: 'C3660461', name: 'sick sinus syndrome' },
            { cui: 'C3660384', name: 'secondary hypertension' },
            { cui: 'C3660274', name: 'right ventricular hypertrophy' },
            { cui: 'C3660267', name: 'right bundle branch block' },
            { cui: 'C3660255', name: 'rheumatic mitral stenosis' },
            { cui: 'C3660254', name: 'rheumatic mitral regurgitation' },
            { cui: 'C3660252', name: 'rheumatic heart disease' },
            { cui: 'C3660251', name: 'rheumatic fever' },
            { cui: 'C3660250', name: 'rheumatic diseases of pulmonary valve' },
            { cui: 'C3660249', name: 'rheumatic aortic stenosis' },
            { cui: 'C3660248', name: 'rheumatic aortic regurgitation' },
            { cui: 'C3660130', name: 'renovascular hypertension' },
            { cui: 'C3660081', name: 'refractory angina' },
            { cui: 'C3659982', name: 'pulmonary valve stenosis' },
            { cui: 'C3659981', name: 'pulmonary valve insufficiency' },
            { cui: 'C3659980', name: 'pulmonary valve disorders' },
            { cui: 'C3659968', name: 'pulmonary edema' },
            { cui: 'C3659964', name: 'pulmonary artery stenosis' },
            { cui: 'C3659957', name: 'pulmonary arterial hypertension' },
            { cui: 'C3659882', name: 'prinzmetals angina' },
            { cui: 'C3659873', name: 'preventative cardiology' },
            { cui: 'C3659861', name: 'premature ventricular contractions' },
            { cui: 'C3659857', name: 'premature beats' },
            { cui: 'C3659846', name: 'postural tachycardia syndrome' },
            { cui: 'C3659641', name: 'pericarditis' },
            { cui: 'C3659567', name: 'patent foramen ovale' },
            { cui: 'C3659566', name: 'patent ductus arteriosus' },
            {
              cui: 'C3659544',
              name: 'partial anomalous pulmonary venous connection (papvc)',
            },
            { cui: 'C3659542', name: 'paroxysmal ventricular tachycardia' },
            { cui: 'C3659541', name: 'paroxysmal tachycardia' },
            {
              cui: 'C3659540',
              name: 'paroxysmal supraventricular tachycardia',
            },
            { cui: 'C3659479', name: 'palpitations' },
            { cui: 'C3659421', name: 'orthostatic hypotension' },
            { cui: 'C3659153', name: 'myocarditis' },
            { cui: 'C3659151', name: 'myocardial infarction (mi)' },
            { cui: 'C3659022', name: 'mitral valve stenosis' },
            { cui: 'C3659019', name: 'mitral valve prolapse' },
            { cui: 'C3659017', name: 'mitral valve disease' },
            { cui: 'C3659016', name: 'mitral regurgitation' },
            { cui: 'C3658858', name: 'maternal hypertension' },
            { cui: 'C3658843', name: 'marfan syndrome' },
            { cui: 'C3658797', name: 'malignant hypertension' },
            { cui: 'C3658586', name: 'left bundle branch block' },
            { cui: 'C3658565', name: 'laser atherectomy' },
            { cui: 'C3658378', name: 'ischemic cardiomyopathy' },
            { cui: 'C3658358', name: 'intravascular ultrasound' },
            { cui: 'C3658326', name: 'intra aortic balloon pump' },
            { cui: 'C3658272', name: 'infiltrative disorders of the heart' },
            { cui: 'C3658242', name: 'implantable defibrillator' },
            {
              cui: 'C3658215',
              name: 'idiopathic hypertrophic subaortic stenosis',
            },
            { cui: 'C3658165', name: 'hypertrophic cardiomyopathy' },
            { cui: 'C3658158', name: 'hypertension' },
            { cui: 'C3657969', name: 'heart racing' },
            { cui: 'C3657968', name: 'heart problems' },
            { cui: 'C3657967', name: 'heart murmur' },
            { cui: 'C3657669', name: 'first degree heart block' },
            { cui: 'C3657612', name: 'family history of heart disease' },
            { cui: 'C3657597', name: 'familial arrhythmias' },
            { cui: 'C3657307', name: 'echocardiography' },
            { cui: 'C3657284', name: 'dyspnea on exertion' },
            { cui: 'C3657151', name: 'diabetic cardiomyopathy' },
            { cui: 'C3656923', name: 'coronary calcium scoring' },
            { cui: 'C3656921', name: 'coronary artery disease' },
            { cui: 'C3656919', name: 'anomalies of the coronary artery' },
            { cui: 'C3656844', name: 'congestive heart failure (chf)' },
            { cui: 'C3656765', name: 'conduction defects' },
            { cui: 'C3656673', name: 'chronic total occlusion' },
            { cui: 'C3656633', name: 'cholesterol disorders' },
            { cui: 'C3656603', name: 'chest pain' },
            { cui: 'C3656479', name: 'cardiomyopathy' },
            { cui: 'C3656478', name: 'cardiomegaly' },
            { cui: 'C3656477', name: 'cardiac sarcoma' },
            { cui: 'C3656475', name: 'cardiac risk assessment' },
            { cui: 'C3656473', name: 'cardiac mri' },
            { cui: 'C3656470', name: 'cardiac genetics' },
            { cui: 'C3656465', name: 'cardiac conduction defects' },
            { cui: 'C3656463', name: 'cardiac arrhythmias' },
            { cui: 'C3656462', name: 'cardiac angina' },
            { cui: 'C3656319', name: 'bradycardia' },
            { cui: 'C3656205', name: 'bicuspid aortic valve' },
            { cui: 'C3656132', name: 'bacterial endocarditis' },
            { cui: 'C3656101', name: 'autoimmune pericarditis' },
            { cui: 'C3656080', name: 'atrioventricular reentrant tachycardia' },
            {
              cui: 'C3656079',
              name: 'atrioventricular nodal reentrant tachycardia',
            },
            { cui: 'C3656078', name: 'atrioventricular block' },
            { cui: 'C3656077', name: 'atrial tachycardia' },
            { cui: 'C3656075', name: 'atrial flutter' },
            { cui: 'C3656073', name: 'atrial fibrillation' },
            { cui: 'C3656068', name: 'atherosclerosis of the aorta' },
            { cui: 'C3656066', name: 'atherosclerosis' },
            { cui: 'C3655970', name: 'aortic valve disease' },
            { cui: 'C3655969', name: 'aortic diseases' },
            { cui: 'C3655967', name: 'aortic coarctation' },
            { cui: 'C3655957', name: 'anticoagulation' },
            { cui: 'C3655845', name: 'primary systemic amyloidosis' },
            { cui: 'C3655844', name: 'amyloidosis' },
          ],
        },
        clinical_trial_text: null,
        clinical_trials: [],
        contact_info: null,
        contacts: [
          {
            contact_type: 'phone',
            extension: null,
            subtype: null,
            value: null,
          },
          { contact_type: 'fax', extension: null, subtype: null, value: null },
          {
            contact_type: 'clinical_contact_info',
            extension: null,
            subtype: null,
            value: null,
          },
        ],
        date_of_birth: null,
        dea_number: 'AG1748334',
        default_timezone: 'UTC',
        degrees: [{ name: 'MD', source: 'MD', source_url: null }],
        department_name: null,
        departments: ['Providers'],
        direct_book_capable: false,
        direct_book_pmac: false,
        direct_book_pmc: false,
        division: null,
        ehr_platform: null,
        entity_type: null,
        external_id: '27B57310-CD3C-45BB-913D-CB212D04592D',
        external_links: null,
        external_systems: null,
        gender: 'Male',
        google_category: null,
        graduate_education: [],
        grants: [],
        gx: { max_age: 43800, min_age: 7665 },
        has_video_url: false,
        id: 3845353,
        image_object: null,
        image_url: null,
        institutes: null,
        insurance_accepted: [],
        internal_notes: null,
        is_primary_care: false,
        is_specialty_care: true,
        ksched_appointment_request_email: null,
        language_disclaimer: null,
        languages: ['English'],
        leadership_title: null,
        legal: [],
        lgbtq_inclusive: false,
        license: [],
        listings_syndication: null,
        logo: null,
        manually_created: null,
        marketing_campaign_name: null,
        media_mentions: [],
        medical_license: 'MD041758E',
        multi_resource_scheduling: null,
        name: {
          first: 'Jeffrey',
          first_last: 'Jeffrey Garrett',
          full: 'Jeffrey S Garrett',
          last: 'Garrett',
          middle: 'S',
          preferred: 'Jeffrey S',
        },
        name_pronunciation: null,
        near_term_availability: null,
        network_affiliations: [
          { name: 'West Penn Hospital', rank: null, type: 'Hospital' },
          { name: 'Forbes Hospital', rank: null, type: 'Hospital' },
        ],
        networks: ['Affiliated'],
        new_pt_online_booking: false,
        night_weekend_availability: false,
        notes: null,
        npi: '1861456527',
        office_hours_text: null,
        open_scheduling: false,
        personal_interests: null,
        philosophy_of_care: null,
        pmc_db_only_provider: null,
        pmc_db_only_provider_flag: false,
        practice_groups: [
          {
            has_info: false,
            id: 85217,
            is_primary_care: false,
            name: 'Providers',
          },
        ],
        professional_memberships: null,
        professional_statement: null,
        pronouns: null,
        provider_business_description: null,
        provider_email: null,
        provider_is_employed: false,
        provider_is_location: false,
        provider_title: null,
        provider_type: 'Physician',
        publication_text: null,
        publication_url: null,
        publications: [],
        publish_to_brandify: null,
        rating: {},
        ratings: {},
        region: null,
        request_appointment_url: null,
        research_interest: null,
        reviews: null,
        sched_interval_duration_days: null,
        sched_interval_same_day_minute_delay: null,
        sched_interval_start_days: null,
        scheduling_notes: null,
        section: null,
        service_line: null,
        show_cta_modal: 'Yes',
        show_in_pmac: 'No',
        show_in_pmc: 'Yes',
        sort_field: '2',
        sort_preferences: {},
        source_specialty: 'Cardiovascular Disease',
        special_program: null,
        specialties: [{ eui: 'E0000008', name: 'Cardiology' }],
        status_transitions: null,
        suffix: null,
        surgical_status: null,
        syndicate_to_google: false,
        system_id: null,
        telehealth: false,
        telehealth_badge: false,
        training: [
          {
            city: null,
            country: null,
            degree: null,
            field_of_study: null,
            graduation_date: null,
            graduation_year: '1981',
            name: 'New York University School of Medicine',
            rank: null,
            state: null,
            street1: null,
            street2: null,
            type: 'Education',
            zip: null,
          },
          {
            city: null,
            country: null,
            degree: null,
            field_of_study: 'Internal Medicine',
            graduation_date: null,
            graduation_year: '1984',
            name: 'New York Medical Center',
            rank: null,
            state: null,
            street1: null,
            street2: null,
            type: 'Residency',
            zip: null,
          },
          {
            city: null,
            country: null,
            degree: null,
            field_of_study: 'Cardiovascular Disease (Cardiology)',
            graduation_date: null,
            graduation_year: '1987',
            name: 'University of California, San Francisco, School of Medicine',
            rank: null,
            state: null,
            street1: null,
            street2: null,
            type: 'Fellowship',
            zip: null,
          },
        ],
        urgent_care_location: false,
        video_url: null,
        video_visit: false,
        virtual_care: false,
        virtual_care_url: null,
        walkins_welcome: false,
        web_phone_number: null,
        xp_provider_group: null,
        years_in_practice: null,
      },
      provider_id: 3845353,
      sort: [{ relevance: 13.0 }, { shuffle: 22220429.0 }],
    },
    {
      availability: {
        facets: null,
        meta: {
          availability_density_best: null,
          max_date_available: null,
          min_date_available: null,
          slot_count: 0,
          tz_max_date_available: null,
          tz_min_date_available: null,
        },
        slots: [],
      },
      customer: 'ahn',
      locations: [
        {
          associated_marketable_location_ids: [],
          city: 'Natrona Heights',
          commercial_entity_name: null,
          contacts: [],
          coordinates: { lat: 40.622567, lon: -79.723499 },
          ehr_location_id: '10457081306',
          email: null,
          external_id: '10457081306',
          external_organization_id: null,
          facility_fee: false,
          fax: '724-226-2415',
          id: 4165484,
          location_page_override_url: null,
          name: 'Tri-County Cardiology',
          networks: [],
          office_hours: null,
          phone: '724-226-3345',
          primary_marketable_location_id: null,
          rank: 1,
          source_system: 'epic',
          source_system_type: 'epic',
          state: 'PA',
          street1: '1624 Pacific Avenue',
          street2: '',
          suite: '',
          timezone: 'UTC',
          type: 'CLI',
          zip: '15065',
        },
      ],
      match: {
        highlight: ['<em>Cardiology</em>'],
        score: 13,
        type: 'Specialty Exact Match',
      },
      provider: {
        about_the_provider:
          'Kristi Toth is a certified physician assistant (PA-C) who specializes in cardiology. She has special clinical interests in cardio-oncology and heart failure. Kristi has expertise in central and arterial line insertion, intubations, chest tubes, and temporary dialysis catheter insertion. She sees patients 18 and older and is dedicated to a patient-centered approach to care.  Kristi earned a master\u2019s degree in physician assistant studies from Duquesne University. She is certified by the National Commission on Certification of Physician Assistants. Kristi also has Advanced Cardiovascular Life Support certification.  ',
        academic_department_name: null,
        academic_title: null,
        accepting_new_patients: true,
        access_program_status: null,
        additional_services: null,
        additional_visit_types: null,
        age_groups_seen: ['Adult 21-65', 'Older Adult >65'],
        aha_id: null,
        always_available_provider: false,
        appointment_ehr_purposes: [],
        awards: null,
        board_certifications: [],
        book_online_override_url: null,
        clinic_location_url: null,
        clinical_contact: null,
        clinical_interests: null,
        clinical_keywords: {
          promoted: [],
          searchable: [
            { cui: 'C3680038', name: 'cardiogenic syncope' },
            { cui: 'C3679884', name: 'familial hypertension' },
            {
              cui: 'C3679866',
              name: 'myocardial infarction with non-obstructive coronary artery disease',
            },
            { cui: 'C3675846', name: 'chest pain with exercise' },
            { cui: 'C3675086', name: 'arrhythmia' },
            { cui: 'C3673288', name: 'echocardiogram' },
            { cui: 'C3673286', name: 'familial amyloidosis' },
            { cui: 'C3673279', name: 'familial cardiomyopathy' },
            { cui: 'C3672710', name: 'heart failure screening' },
            { cui: 'C3672616', name: 'microvascular dysfunction' },
            { cui: 'C3671643', name: 'inflammatory heart diseases' },
            { cui: 'C3671321', name: 'cardiac arrest' },
            {
              cui: 'C3671144',
              name: 'anomalous left coronary artery from the pulmonary artery (alcapa)',
            },
            { cui: 'C3671143', name: 'anomalous coronary artery (aca) repair' },
            { cui: 'C3670717', name: 'arteriosclerosis' },
            { cui: 'C3670342', name: 'multi system inflammatory syndrome' },
            { cui: 'C3670163', name: 'exercise induced arrhythmia' },
            { cui: 'C3670161', name: 'inappropriate sinus tachycardia' },
            { cui: 'C3670159', name: 'exercise induced syncope' },
            { cui: 'C3669688', name: 'variant angina' },
            { cui: 'C3669687', name: 'vasospastic angina' },
            { cui: 'C3669635', name: 'left ventricular dysfunction' },
            { cui: 'C3669491', name: 'heart valve disease' },
            { cui: 'C3669489', name: 'coronary artery calcification' },
            { cui: 'C3669485', name: 'cardiopulmonary disease' },
            {
              cui: 'C3667902',
              name: 'sports related cardiovascular assessment',
            },
            { cui: 'C3667658', name: 'valvular heart disease' },
            { cui: 'C3666968', name: 'left ventricular hypertrophy' },
            {
              cui: 'C3666782',
              name: 'arrhythmogenic right ventricular cardiomyopathy',
            },
            { cui: 'C3666443', name: 'heart disease in women' },
            { cui: 'C3666436', name: 'tachycardia bradycardia syndrome' },
            { cui: 'C3666374', name: 'single ventricle defect' },
            { cui: 'C3666371', name: 'fibromuscular dysplasia' },
            { cui: 'C3666368', name: 'aortic valve sclerosis' },
            { cui: 'C3666282', name: 'left heart failure' },
            { cui: 'C3666222', name: 'cardiac insufficiency' },
            { cui: 'C3666190', name: 'abnormal coronary angiography' },
            {
              cui: 'C3666122',
              name: 'transposition of the great arteries (tga)',
            },
            { cui: 'C3665557', name: 'prosthetic heart valves' },
            { cui: 'C3665458', name: 'heart disease' },
            { cui: 'C3665328', name: 'aortic stenosis' },
            { cui: 'C3665320', name: 'edema' },
            { cui: 'C3665204', name: 'electrocardiogram (ekg)' },
            { cui: 'C3663857', name: 'heart health' },
            { cui: 'C3663837', name: 'cardiovascular care' },
            { cui: 'C3663709', name: 'peripheral edema' },
            { cui: 'C3663107', name: 'exercise intolerance' },
            { cui: 'C3663039', name: 'pre excitation syndrome' },
            { cui: 'C3663037', name: 'premature atrial contraction' },
            { cui: 'C3662984', name: 'atrioventricular septal defect' },
            { cui: 'C3662828', name: 'cardiorenal syndrome' },
            { cui: 'C3662822', name: 'hypertensive urgency' },
            { cui: 'C3662821', name: 'uncontrolled hypertension' },
            { cui: 'C3662626', name: 'pericardial effusion' },
            { cui: 'C3662574', name: 'valvular endocarditis' },
            { cui: 'C3662573', name: 'acute bacterial endocarditis' },
            { cui: 'C3662519', name: 'abnormal stress test' },
            { cui: 'C3662501', name: 'abnormal electrocardiogram (ekg)' },
            { cui: 'C3662218', name: 'genetic cardiomyopathy' },
            { cui: 'C3662064', name: 'right heart failure' },
            { cui: 'C3662020', name: 'preoperative evaluation' },
            { cui: 'C3661991', name: 'orthopnea' },
            { cui: 'C3661932', name: 'mitral valve regurgitation' },
            { cui: 'C3661746', name: 'cholesterol management' },
            { cui: 'C3661737', name: 'cardiac tumor' },
            { cui: 'C3661490', name: 'atrial septal defect (asd)' },
            { cui: 'C3661407', name: 'cardiovascular disease' },
            { cui: 'C3661356', name: 'wolff-parkinson-white syndrome' },
            { cui: 'C3661263', name: 'vasovagal syncope' },
            { cui: 'C3661240', name: 'valve stenosis' },
            { cui: 'C3661043', name: 'type 2 second degree heart block' },
            { cui: 'C3661041', name: 'type 1 second degree heart block' },
            { cui: 'C3661005', name: 'tricuspid valve disease' },
            { cui: 'C3661001', name: 'tricuspid regurgitation' },
            { cui: 'C3660977', name: 'transposition of great vessels' },
            { cui: 'C3660846', name: 'thrombosis' },
            { cui: 'C3660762', name: 'tachycardia' },
            { cui: 'C3660745', name: 'syncope' },
            { cui: 'C3660724', name: 'supraventricular tachycardia' },
            { cui: 'C3660711', name: 'family history of sudden cardiac death' },
            { cui: 'C3660692', name: 'subaortic stenosis' },
            { cui: 'C3660461', name: 'sick sinus syndrome' },
            { cui: 'C3660384', name: 'secondary hypertension' },
            { cui: 'C3660274', name: 'right ventricular hypertrophy' },
            { cui: 'C3660267', name: 'right bundle branch block' },
            { cui: 'C3660255', name: 'rheumatic mitral stenosis' },
            { cui: 'C3660254', name: 'rheumatic mitral regurgitation' },
            { cui: 'C3660252', name: 'rheumatic heart disease' },
            { cui: 'C3660251', name: 'rheumatic fever' },
            { cui: 'C3660250', name: 'rheumatic diseases of pulmonary valve' },
            { cui: 'C3660249', name: 'rheumatic aortic stenosis' },
            { cui: 'C3660248', name: 'rheumatic aortic regurgitation' },
            { cui: 'C3660130', name: 'renovascular hypertension' },
            { cui: 'C3660081', name: 'refractory angina' },
            { cui: 'C3659982', name: 'pulmonary valve stenosis' },
            { cui: 'C3659981', name: 'pulmonary valve insufficiency' },
            { cui: 'C3659980', name: 'pulmonary valve disorders' },
            { cui: 'C3659968', name: 'pulmonary edema' },
            { cui: 'C3659964', name: 'pulmonary artery stenosis' },
            { cui: 'C3659957', name: 'pulmonary arterial hypertension' },
            { cui: 'C3659882', name: 'prinzmetals angina' },
            { cui: 'C3659873', name: 'preventative cardiology' },
            { cui: 'C3659861', name: 'premature ventricular contractions' },
            { cui: 'C3659857', name: 'premature beats' },
            { cui: 'C3659846', name: 'postural tachycardia syndrome' },
            { cui: 'C3659641', name: 'pericarditis' },
            { cui: 'C3659567', name: 'patent foramen ovale' },
            { cui: 'C3659566', name: 'patent ductus arteriosus' },
            {
              cui: 'C3659544',
              name: 'partial anomalous pulmonary venous connection (papvc)',
            },
            { cui: 'C3659542', name: 'paroxysmal ventricular tachycardia' },
            { cui: 'C3659541', name: 'paroxysmal tachycardia' },
            {
              cui: 'C3659540',
              name: 'paroxysmal supraventricular tachycardia',
            },
            { cui: 'C3659479', name: 'palpitations' },
            { cui: 'C3659421', name: 'orthostatic hypotension' },
            { cui: 'C3659153', name: 'myocarditis' },
            { cui: 'C3659151', name: 'myocardial infarction (mi)' },
            { cui: 'C3659022', name: 'mitral valve stenosis' },
            { cui: 'C3659019', name: 'mitral valve prolapse' },
            { cui: 'C3659017', name: 'mitral valve disease' },
            { cui: 'C3659016', name: 'mitral regurgitation' },
            { cui: 'C3658858', name: 'maternal hypertension' },
            { cui: 'C3658843', name: 'marfan syndrome' },
            { cui: 'C3658797', name: 'malignant hypertension' },
            { cui: 'C3658586', name: 'left bundle branch block' },
            { cui: 'C3658565', name: 'laser atherectomy' },
            { cui: 'C3658378', name: 'ischemic cardiomyopathy' },
            { cui: 'C3658358', name: 'intravascular ultrasound' },
            { cui: 'C3658326', name: 'intra aortic balloon pump' },
            { cui: 'C3658272', name: 'infiltrative disorders of the heart' },
            { cui: 'C3658242', name: 'implantable defibrillator' },
            {
              cui: 'C3658215',
              name: 'idiopathic hypertrophic subaortic stenosis',
            },
            { cui: 'C3658165', name: 'hypertrophic cardiomyopathy' },
            { cui: 'C3658158', name: 'hypertension' },
            { cui: 'C3657969', name: 'heart racing' },
            { cui: 'C3657968', name: 'heart problems' },
            { cui: 'C3657967', name: 'heart murmur' },
            { cui: 'C3657669', name: 'first degree heart block' },
            { cui: 'C3657612', name: 'family history of heart disease' },
            { cui: 'C3657597', name: 'familial arrhythmias' },
            { cui: 'C3657307', name: 'echocardiography' },
            { cui: 'C3657284', name: 'dyspnea on exertion' },
            { cui: 'C3657151', name: 'diabetic cardiomyopathy' },
            { cui: 'C3656923', name: 'coronary calcium scoring' },
            { cui: 'C3656921', name: 'coronary artery disease' },
            { cui: 'C3656919', name: 'anomalies of the coronary artery' },
            { cui: 'C3656844', name: 'congestive heart failure (chf)' },
            { cui: 'C3656765', name: 'conduction defects' },
            { cui: 'C3656673', name: 'chronic total occlusion' },
            { cui: 'C3656633', name: 'cholesterol disorders' },
            { cui: 'C3656603', name: 'chest pain' },
            { cui: 'C3656479', name: 'cardiomyopathy' },
            { cui: 'C3656478', name: 'cardiomegaly' },
            { cui: 'C3656477', name: 'cardiac sarcoma' },
            { cui: 'C3656475', name: 'cardiac risk assessment' },
            { cui: 'C3656473', name: 'cardiac mri' },
            { cui: 'C3656470', name: 'cardiac genetics' },
            { cui: 'C3656465', name: 'cardiac conduction defects' },
            { cui: 'C3656463', name: 'cardiac arrhythmias' },
            { cui: 'C3656462', name: 'cardiac angina' },
            { cui: 'C3656319', name: 'bradycardia' },
            { cui: 'C3656205', name: 'bicuspid aortic valve' },
            { cui: 'C3656132', name: 'bacterial endocarditis' },
            { cui: 'C3656101', name: 'autoimmune pericarditis' },
            { cui: 'C3656080', name: 'atrioventricular reentrant tachycardia' },
            {
              cui: 'C3656079',
              name: 'atrioventricular nodal reentrant tachycardia',
            },
            { cui: 'C3656078', name: 'atrioventricular block' },
            { cui: 'C3656077', name: 'atrial tachycardia' },
            { cui: 'C3656075', name: 'atrial flutter' },
            { cui: 'C3656073', name: 'atrial fibrillation' },
            { cui: 'C3656068', name: 'atherosclerosis of the aorta' },
            { cui: 'C3656066', name: 'atherosclerosis' },
            { cui: 'C3655970', name: 'aortic valve disease' },
            { cui: 'C3655969', name: 'aortic diseases' },
            { cui: 'C3655967', name: 'aortic coarctation' },
            { cui: 'C3655957', name: 'anticoagulation' },
            { cui: 'C3655845', name: 'primary systemic amyloidosis' },
            { cui: 'C3655844', name: 'amyloidosis' },
          ],
        },
        clinical_trial_text: null,
        clinical_trials: [],
        contact_info: null,
        contacts: [
          {
            contact_type: 'phone',
            extension: null,
            subtype: null,
            value: null,
          },
          { contact_type: 'fax', extension: null, subtype: null, value: null },
          {
            contact_type: 'clinical_contact_info',
            extension: null,
            subtype: null,
            value: null,
          },
        ],
        date_of_birth: null,
        dea_number: 'MI4576851',
        default_timezone: 'UTC',
        degrees: [{ name: 'PA-C', source: 'PA-C', source_url: null }],
        department_name: null,
        departments: ['Providers'],
        direct_book_capable: false,
        direct_book_pmac: false,
        direct_book_pmc: true,
        division: null,
        ehr_platform: null,
        entity_type: null,
        external_id: '2E30C020-0D28-4604-8F16-0D5F07833EB8',
        external_links: null,
        external_systems: [
          {
            provider_id: '1017789',
            source_system: 'epic',
            system_type: 'epic',
          },
        ],
        gender: 'Female',
        google_category: null,
        graduate_education: [],
        grants: [],
        gx: { max_age: 43800, min_age: 7665 },
        has_video_url: false,
        id: 3847790,
        image_object: null,
        image_url:
          'https://cdn-images.kyruus.com/providermatch/ahn/photos/200/toth-kristi-1255843207.jpg',
        institutes: null,
        insurance_accepted: [],
        internal_notes: null,
        is_primary_care: true,
        is_specialty_care: true,
        ksched_appointment_request_email: null,
        language_disclaimer: null,
        languages: ['English'],
        leadership_title: null,
        legal: [],
        lgbtq_inclusive: false,
        license: [],
        listings_syndication: null,
        logo: 'Display Logo',
        manually_created: null,
        marketing_campaign_name: null,
        media_mentions: [],
        medical_license: 'MA059542',
        multi_resource_scheduling: null,
        name: {
          first: 'Kristi',
          first_last: 'Kristi Toth',
          full: 'Kristi Leigh Toth',
          last: 'Toth',
          middle: 'Leigh',
          preferred: 'Kristi L',
        },
        name_pronunciation: null,
        near_term_availability: null,
        network_affiliations: [{ name: 'Allegheny Valley Hospital', rank: null, type: 'Hospital' }],
        networks: ['Customer Network'],
        new_pt_online_booking: false,
        night_weekend_availability: false,
        notes: null,
        npi: '1255843207',
        office_hours_text: null,
        open_scheduling: false,
        personal_interests: null,
        philosophy_of_care: null,
        pmc_db_only_provider: null,
        pmc_db_only_provider_flag: false,
        practice_groups: [
          {
            has_info: false,
            id: 85217,
            is_primary_care: false,
            name: 'Providers',
          },
        ],
        professional_memberships: null,
        professional_statement: null,
        pronouns: null,
        provider_business_description: null,
        provider_email: null,
        provider_is_employed: true,
        provider_is_location: false,
        provider_title: null,
        provider_type: 'Physician Assistant (PA)',
        publication_text: null,
        publication_url: null,
        publications: [],
        publish_to_brandify: null,
        rating: {},
        ratings: {},
        region: null,
        request_appointment_url: null,
        research_interest: null,
        reviews: null,
        sched_interval_duration_days: null,
        sched_interval_same_day_minute_delay: null,
        sched_interval_start_days: null,
        scheduling_notes: null,
        section: null,
        service_line: null,
        show_cta_modal: 'Yes',
        show_in_pmac: 'Yes',
        show_in_pmc: 'Yes',
        sort_field: '1',
        sort_preferences: {},
        source_specialty: 'Cardiovascular Disease',
        special_program: null,
        specialties: [{ eui: 'E0000008', name: 'Cardiology' }],
        status_transitions: null,
        suffix: null,
        surgical_status: null,
        syndicate_to_google: false,
        system_id: null,
        telehealth: false,
        telehealth_badge: false,
        training: [
          {
            city: null,
            country: null,
            degree: null,
            field_of_study: null,
            graduation_date: null,
            graduation_year: '2017',
            name: 'Duquesne University',
            rank: null,
            state: null,
            street1: null,
            street2: null,
            type: 'Education',
            zip: null,
          },
        ],
        urgent_care_location: false,
        video_url: null,
        video_visit: false,
        virtual_care: false,
        virtual_care_url: null,
        walkins_welcome: false,
        web_phone_number: null,
        xp_provider_group: null,
        years_in_practice: null,
      },
      provider_id: 3847790,
      sort: [{ relevance: 13.0 }, { shuffle: 41534034.0 }],
    },
    {
      availability: {
        facets: null,
        meta: {
          availability_density_best: null,
          max_date_available: null,
          min_date_available: null,
          slot_count: 0,
          tz_max_date_available: null,
          tz_min_date_available: null,
        },
        slots: [],
      },
      customer: 'ahn',
      locations: [
        {
          associated_marketable_location_ids: [],
          city: 'Monroeville',
          commercial_entity_name: null,
          contacts: [],
          coordinates: { lat: 40.427054, lon: -79.752531 },
          ehr_location_id: '10457095304',
          email: null,
          external_id: '10457095304',
          external_organization_id: null,
          facility_fee: false,
          fax: '412-373-4595',
          id: 4165523,
          name: 'Tri-County Cardiology',
          networks: [],
          office_hours: null,
          phone: '412-373-6666',
          primary_marketable_location_id: null,
          rank: 1,
          source_system: 'epic',
          source_system_type: 'epic',
          state: 'PA',
          street1: '2550 Mosside Boulevard',
          street2: '',
          suite: '',
          timezone: 'UTC',
          type: 'CLI',
          url: null,
          zip: '15146',
        },
        {
          associated_marketable_location_ids: [],
          city: 'Pittsburgh',
          commercial_entity_name: null,
          contacts: [],
          coordinates: { lat: 40.44615, lon: -80.177455 },
          ehr_location_id: '10457000004',
          email: null,
          external_id: '10457000004',
          external_organization_id: null,
          facility_fee: false,
          fax: '412-264-8999',
          id: 4165524,
          name: 'Cardiovascular Institute',
          networks: [],
          office_hours: null,
          phone: '412-264-9500',
          primary_marketable_location_id: null,
          rank: 2,
          source_system: 'epic',
          source_system_type: 'epic',
          state: 'PA',
          street1: '200 Quinn Drive',
          street2: 'AHN N FAYETTE HEALTH AND WELLNESS PAVILION',
          suite: 'Suite 210',
          timezone: 'UTC',
          type: 'CLI',
          url: null,
          zip: '15275',
        },
      ],
      match: {
        highlight: ['<em>Cardiology</em>'],
        score: 13,
        type: 'Specialty Exact Match',
      },
      provider: {
        about_the_provider:
          'Lauren Lasko is an adult nurse practitioner specializing in cardiovascular care. She specializes in coronary artery disease, hypertension, cardiac heart failure, and myocardial infarctions. Her goal is to always focus on the patient and to provide great communication. She earned her Bachelor of Science in nursing and her Master of Science in nursing, both from Carlow University in Pittsburgh, Pennsylvania.   Lauren is board-certified for adult geriatric acute care by the American Academy of Nurse Practitioners (AANP). She is a member of AANP and Sigma Theta Tau. She sees patients ages 18 and older and speaks English. ',
        academic_department_name: null,
        academic_title: null,
        accepting_new_patients: true,
        access_program_status: null,
        additional_services: null,
        additional_visit_types: null,
        age_groups_seen: ['Adult 21-65', 'Older Adult >65'],
        aha_id: null,
        always_available_provider: false,
        appointment_ehr_purposes: [],
        awards: null,
        board_certifications: [],
        book_online_override_url: null,
        clinic_location_url: null,
        clinical_contact: null,
        clinical_interests: null,
        clinical_keywords: {
          promoted: [],
          searchable: [
            { cui: 'C3680038', name: 'cardiogenic syncope' },
            { cui: 'C3679884', name: 'familial hypertension' },
            {
              cui: 'C3679866',
              name: 'myocardial infarction with non-obstructive coronary artery disease',
            },
            { cui: 'C3675846', name: 'chest pain with exercise' },
            { cui: 'C3675086', name: 'arrhythmia' },
            { cui: 'C3673288', name: 'echocardiogram' },
            { cui: 'C3673286', name: 'familial amyloidosis' },
            { cui: 'C3673279', name: 'familial cardiomyopathy' },
            { cui: 'C3672710', name: 'heart failure screening' },
            { cui: 'C3672616', name: 'microvascular dysfunction' },
            { cui: 'C3671643', name: 'inflammatory heart diseases' },
            { cui: 'C3671321', name: 'cardiac arrest' },
            {
              cui: 'C3671144',
              name: 'anomalous left coronary artery from the pulmonary artery (alcapa)',
            },
            { cui: 'C3671143', name: 'anomalous coronary artery (aca) repair' },
            { cui: 'C3670717', name: 'arteriosclerosis' },
            { cui: 'C3670342', name: 'multi system inflammatory syndrome' },
            { cui: 'C3670163', name: 'exercise induced arrhythmia' },
            { cui: 'C3670161', name: 'inappropriate sinus tachycardia' },
            { cui: 'C3670159', name: 'exercise induced syncope' },
            { cui: 'C3669688', name: 'variant angina' },
            { cui: 'C3669687', name: 'vasospastic angina' },
            { cui: 'C3669635', name: 'left ventricular dysfunction' },
            { cui: 'C3669491', name: 'heart valve disease' },
            { cui: 'C3669489', name: 'coronary artery calcification' },
            { cui: 'C3669485', name: 'cardiopulmonary disease' },
            {
              cui: 'C3667902',
              name: 'sports related cardiovascular assessment',
            },
            { cui: 'C3667658', name: 'valvular heart disease' },
            { cui: 'C3666968', name: 'left ventricular hypertrophy' },
            {
              cui: 'C3666782',
              name: 'arrhythmogenic right ventricular cardiomyopathy',
            },
            { cui: 'C3666443', name: 'heart disease in women' },
            { cui: 'C3666436', name: 'tachycardia bradycardia syndrome' },
            { cui: 'C3666374', name: 'single ventricle defect' },
            { cui: 'C3666371', name: 'fibromuscular dysplasia' },
            { cui: 'C3666368', name: 'aortic valve sclerosis' },
            { cui: 'C3666282', name: 'left heart failure' },
            { cui: 'C3666222', name: 'cardiac insufficiency' },
            { cui: 'C3666190', name: 'abnormal coronary angiography' },
            {
              cui: 'C3666122',
              name: 'transposition of the great arteries (tga)',
            },
            { cui: 'C3665557', name: 'prosthetic heart valves' },
            { cui: 'C3665458', name: 'heart disease' },
            { cui: 'C3665328', name: 'aortic stenosis' },
            { cui: 'C3665320', name: 'edema' },
            { cui: 'C3665204', name: 'electrocardiogram (ekg)' },
            { cui: 'C3663857', name: 'heart health' },
            { cui: 'C3663837', name: 'cardiovascular care' },
            { cui: 'C3663709', name: 'peripheral edema' },
            { cui: 'C3663107', name: 'exercise intolerance' },
            { cui: 'C3663039', name: 'pre excitation syndrome' },
            { cui: 'C3663037', name: 'premature atrial contraction' },
            { cui: 'C3662984', name: 'atrioventricular septal defect' },
            { cui: 'C3662828', name: 'cardiorenal syndrome' },
            { cui: 'C3662822', name: 'hypertensive urgency' },
            { cui: 'C3662821', name: 'uncontrolled hypertension' },
            { cui: 'C3662626', name: 'pericardial effusion' },
            { cui: 'C3662574', name: 'valvular endocarditis' },
            { cui: 'C3662573', name: 'acute bacterial endocarditis' },
            { cui: 'C3662519', name: 'abnormal stress test' },
            { cui: 'C3662501', name: 'abnormal electrocardiogram (ekg)' },
            { cui: 'C3662218', name: 'genetic cardiomyopathy' },
            { cui: 'C3662064', name: 'right heart failure' },
            { cui: 'C3662020', name: 'preoperative evaluation' },
            { cui: 'C3661991', name: 'orthopnea' },
            { cui: 'C3661932', name: 'mitral valve regurgitation' },
            { cui: 'C3661746', name: 'cholesterol management' },
            { cui: 'C3661737', name: 'cardiac tumor' },
            { cui: 'C3661490', name: 'atrial septal defect (asd)' },
            { cui: 'C3661407', name: 'cardiovascular disease' },
            { cui: 'C3661356', name: 'wolff-parkinson-white syndrome' },
            { cui: 'C3661263', name: 'vasovagal syncope' },
            { cui: 'C3661240', name: 'valve stenosis' },
            { cui: 'C3661043', name: 'type 2 second degree heart block' },
            { cui: 'C3661041', name: 'type 1 second degree heart block' },
            { cui: 'C3661005', name: 'tricuspid valve disease' },
            { cui: 'C3661001', name: 'tricuspid regurgitation' },
            { cui: 'C3660977', name: 'transposition of great vessels' },
            { cui: 'C3660846', name: 'thrombosis' },
            { cui: 'C3660762', name: 'tachycardia' },
            { cui: 'C3660745', name: 'syncope' },
            { cui: 'C3660724', name: 'supraventricular tachycardia' },
            { cui: 'C3660711', name: 'family history of sudden cardiac death' },
            { cui: 'C3660692', name: 'subaortic stenosis' },
            { cui: 'C3660461', name: 'sick sinus syndrome' },
            { cui: 'C3660384', name: 'secondary hypertension' },
            { cui: 'C3660274', name: 'right ventricular hypertrophy' },
            { cui: 'C3660267', name: 'right bundle branch block' },
            { cui: 'C3660255', name: 'rheumatic mitral stenosis' },
            { cui: 'C3660254', name: 'rheumatic mitral regurgitation' },
            { cui: 'C3660252', name: 'rheumatic heart disease' },
            { cui: 'C3660251', name: 'rheumatic fever' },
            { cui: 'C3660250', name: 'rheumatic diseases of pulmonary valve' },
            { cui: 'C3660249', name: 'rheumatic aortic stenosis' },
            { cui: 'C3660248', name: 'rheumatic aortic regurgitation' },
            { cui: 'C3660130', name: 'renovascular hypertension' },
            { cui: 'C3660081', name: 'refractory angina' },
            { cui: 'C3659982', name: 'pulmonary valve stenosis' },
            { cui: 'C3659981', name: 'pulmonary valve insufficiency' },
            { cui: 'C3659980', name: 'pulmonary valve disorders' },
            { cui: 'C3659968', name: 'pulmonary edema' },
            { cui: 'C3659964', name: 'pulmonary artery stenosis' },
            { cui: 'C3659957', name: 'pulmonary arterial hypertension' },
            { cui: 'C3659882', name: 'prinzmetals angina' },
            { cui: 'C3659873', name: 'preventative cardiology' },
            { cui: 'C3659861', name: 'premature ventricular contractions' },
            { cui: 'C3659857', name: 'premature beats' },
            { cui: 'C3659846', name: 'postural tachycardia syndrome' },
            { cui: 'C3659641', name: 'pericarditis' },
            { cui: 'C3659567', name: 'patent foramen ovale' },
            { cui: 'C3659566', name: 'patent ductus arteriosus' },
            {
              cui: 'C3659544',
              name: 'partial anomalous pulmonary venous connection (papvc)',
            },
            { cui: 'C3659542', name: 'paroxysmal ventricular tachycardia' },
            { cui: 'C3659541', name: 'paroxysmal tachycardia' },
            {
              cui: 'C3659540',
              name: 'paroxysmal supraventricular tachycardia',
            },
            { cui: 'C3659479', name: 'palpitations' },
            { cui: 'C3659421', name: 'orthostatic hypotension' },
            { cui: 'C3659153', name: 'myocarditis' },
            { cui: 'C3659151', name: 'myocardial infarction (mi)' },
            { cui: 'C3659022', name: 'mitral valve stenosis' },
            { cui: 'C3659019', name: 'mitral valve prolapse' },
            { cui: 'C3659017', name: 'mitral valve disease' },
            { cui: 'C3659016', name: 'mitral regurgitation' },
            { cui: 'C3658858', name: 'maternal hypertension' },
            { cui: 'C3658843', name: 'marfan syndrome' },
            { cui: 'C3658797', name: 'malignant hypertension' },
            { cui: 'C3658586', name: 'left bundle branch block' },
            { cui: 'C3658565', name: 'laser atherectomy' },
            { cui: 'C3658378', name: 'ischemic cardiomyopathy' },
            { cui: 'C3658358', name: 'intravascular ultrasound' },
            { cui: 'C3658326', name: 'intra aortic balloon pump' },
            { cui: 'C3658272', name: 'infiltrative disorders of the heart' },
            { cui: 'C3658242', name: 'implantable defibrillator' },
            {
              cui: 'C3658215',
              name: 'idiopathic hypertrophic subaortic stenosis',
            },
            { cui: 'C3658165', name: 'hypertrophic cardiomyopathy' },
            { cui: 'C3658158', name: 'hypertension' },
            { cui: 'C3657969', name: 'heart racing' },
            { cui: 'C3657968', name: 'heart problems' },
            { cui: 'C3657967', name: 'heart murmur' },
            { cui: 'C3657669', name: 'first degree heart block' },
            { cui: 'C3657612', name: 'family history of heart disease' },
            { cui: 'C3657597', name: 'familial arrhythmias' },
            { cui: 'C3657307', name: 'echocardiography' },
            { cui: 'C3657284', name: 'dyspnea on exertion' },
            { cui: 'C3657151', name: 'diabetic cardiomyopathy' },
            { cui: 'C3656923', name: 'coronary calcium scoring' },
            { cui: 'C3656921', name: 'coronary artery disease' },
            { cui: 'C3656919', name: 'anomalies of the coronary artery' },
            { cui: 'C3656844', name: 'congestive heart failure (chf)' },
            { cui: 'C3656765', name: 'conduction defects' },
            { cui: 'C3656673', name: 'chronic total occlusion' },
            { cui: 'C3656633', name: 'cholesterol disorders' },
            { cui: 'C3656603', name: 'chest pain' },
            { cui: 'C3656479', name: 'cardiomyopathy' },
            { cui: 'C3656478', name: 'cardiomegaly' },
            { cui: 'C3656477', name: 'cardiac sarcoma' },
            { cui: 'C3656475', name: 'cardiac risk assessment' },
            { cui: 'C3656473', name: 'cardiac mri' },
            { cui: 'C3656470', name: 'cardiac genetics' },
            { cui: 'C3656465', name: 'cardiac conduction defects' },
            { cui: 'C3656463', name: 'cardiac arrhythmias' },
            { cui: 'C3656462', name: 'cardiac angina' },
            { cui: 'C3656319', name: 'bradycardia' },
            { cui: 'C3656205', name: 'bicuspid aortic valve' },
            { cui: 'C3656132', name: 'bacterial endocarditis' },
            { cui: 'C3656101', name: 'autoimmune pericarditis' },
            { cui: 'C3656080', name: 'atrioventricular reentrant tachycardia' },
            {
              cui: 'C3656079',
              name: 'atrioventricular nodal reentrant tachycardia',
            },
            { cui: 'C3656078', name: 'atrioventricular block' },
            { cui: 'C3656077', name: 'atrial tachycardia' },
            { cui: 'C3656075', name: 'atrial flutter' },
            { cui: 'C3656073', name: 'atrial fibrillation' },
            { cui: 'C3656068', name: 'atherosclerosis of the aorta' },
            { cui: 'C3656066', name: 'atherosclerosis' },
            { cui: 'C3655970', name: 'aortic valve disease' },
            { cui: 'C3655969', name: 'aortic diseases' },
            { cui: 'C3655967', name: 'aortic coarctation' },
            { cui: 'C3655957', name: 'anticoagulation' },
            { cui: 'C3655845', name: 'primary systemic amyloidosis' },
            { cui: 'C3655844', name: 'amyloidosis' },
          ],
        },
        clinical_trial_text: null,
        clinical_trials: [],
        contact_info: null,
        contacts: [
          {
            contact_type: 'phone',
            extension: null,
            subtype: null,
            value: null,
          },
          { contact_type: 'fax', extension: null, subtype: null, value: null },
          {
            contact_type: 'clinical_contact_info',
            extension: null,
            subtype: null,
            value: null,
          },
        ],
        date_of_birth: null,
        dea_number: 'ML5070367',
        default_timezone: 'UTC',
        degrees: [{ name: 'CRNP', source: 'CRNP', source_url: null }],
        department_name: null,
        departments: ['Providers'],
        direct_book_capable: false,
        direct_book_pmac: false,
        direct_book_pmc: false,
        division: null,
        ehr_platform: null,
        entity_type: null,
        external_id: '1974CA7F-1BEE-4402-AA26-7463F6C624F7',
        external_links: null,
        external_systems: [
          {
            provider_id: '1087183',
            source_system: 'epic',
            system_type: 'epic',
          },
        ],
        gender: 'Female',
        google_category: null,
        graduate_education: [],
        grants: [],
        gx: { max_age: 43800, min_age: 7665 },
        has_video_url: false,
        id: 3844394,
        image_object: null,
        image_url: null,
        institutes: null,
        insurance_accepted: [],
        internal_notes: null,
        is_primary_care: true,
        is_specialty_care: true,
        ksched_appointment_request_email: null,
        language_disclaimer: null,
        languages: ['English'],
        leadership_title: null,
        legal: [],
        lgbtq_inclusive: false,
        license: [],
        listings_syndication: null,
        logo: 'Display Logo',
        manually_created: null,
        marketing_campaign_name: null,
        media_mentions: [],
        medical_license: 'SP019298',
        multi_resource_scheduling: null,
        name: {
          first: 'Lauren',
          first_last: 'Lauren DeSalle',
          full: 'Lauren Marie DeSalle',
          last: 'DeSalle',
          middle: 'Marie',
          preferred: 'Lauren M',
        },
        name_pronunciation: null,
        near_term_availability: null,
        network_affiliations: [
          { name: 'Allegheny General Hospital', rank: null, type: 'Hospital' },
          { name: 'Forbes Hospital', rank: null, type: 'Hospital' },
        ],
        networks: ['Customer Network'],
        new_pt_online_booking: false,
        night_weekend_availability: false,
        notes: null,
        npi: '1558846485',
        office_hours_text: null,
        open_scheduling: false,
        personal_interests: null,
        philosophy_of_care: null,
        pmc_db_only_provider: null,
        pmc_db_only_provider_flag: false,
        practice_groups: [
          {
            has_info: false,
            id: 85217,
            is_primary_care: false,
            name: 'Providers',
          },
        ],
        professional_memberships: null,
        professional_statement: null,
        pronouns: null,
        provider_business_description: null,
        provider_email: null,
        provider_is_employed: true,
        provider_is_location: false,
        provider_title: null,
        provider_type: 'Nurse Practitioner (NP)',
        publication_text: null,
        publication_url: null,
        publications: [],
        publish_to_brandify: null,
        rating: {},
        ratings: {},
        region: null,
        request_appointment_url: null,
        research_interest: null,
        reviews: null,
        sched_interval_duration_days: null,
        sched_interval_same_day_minute_delay: null,
        sched_interval_start_days: null,
        scheduling_notes: null,
        section: null,
        service_line: null,
        show_cta_modal: 'Yes',
        show_in_pmac: 'Yes',
        show_in_pmc: 'Yes',
        sort_field: '1',
        sort_preferences: {},
        source_specialty: 'Cardiovascular Disease',
        special_program: null,
        specialties: [{ eui: 'E0000008', name: 'Cardiology' }],
        status_transitions: null,
        suffix: null,
        surgical_status: null,
        syndicate_to_google: false,
        system_id: null,
        telehealth: false,
        telehealth_badge: false,
        training: [
          {
            city: null,
            country: null,
            degree: null,
            field_of_study: null,
            graduation_date: null,
            graduation_year: '2018',
            name: 'Carlow University',
            rank: null,
            state: null,
            street1: null,
            street2: null,
            type: 'Education',
            zip: null,
          },
        ],
        urgent_care_location: false,
        video_url: null,
        video_visit: false,
        virtual_care: false,
        virtual_care_url: null,
        walkins_welcome: false,
        web_phone_number: null,
        xp_provider_group: null,
        years_in_practice: null,
      },
      provider_id: 3844394,
      sort: [{ relevance: 13.0 }, { shuffle: 54229615.0 }],
    },
    {
      availability: {
        facets: null,
        meta: {
          availability_density_best: null,
          max_date_available: null,
          min_date_available: null,
          slot_count: 0,
          tz_max_date_available: null,
          tz_min_date_available: null,
        },
        slots: [],
      },
      customer: 'ahn',
      locations: [],
      match: {
        highlight: ['<em>Cardiology</em>'],
        score: 13,
        type: 'Specialty Exact Match',
      },
      provider: {
        about_the_provider: null,
        academic_department_name: null,
        academic_title: null,
        accepting_new_patients: true,
        access_program_status: null,
        additional_services: null,
        additional_visit_types: null,
        age_groups_seen: ['Adult 21-65', 'Older Adult >65'],
        aha_id: null,
        always_available_provider: false,
        appointment_ehr_purposes: [],
        awards: null,
        board_certifications: [],
        book_online_override_url: null,
        clinic_location_url: null,
        clinical_contact: null,
        clinical_interests: null,
        clinical_keywords: {
          promoted: [],
          searchable: [
            { cui: 'C3680038', name: 'cardiogenic syncope' },
            { cui: 'C3679884', name: 'familial hypertension' },
            {
              cui: 'C3679866',
              name: 'myocardial infarction with non-obstructive coronary artery disease',
            },
            { cui: 'C3675846', name: 'chest pain with exercise' },
            { cui: 'C3675086', name: 'arrhythmia' },
            { cui: 'C3673288', name: 'echocardiogram' },
            { cui: 'C3673286', name: 'familial amyloidosis' },
            { cui: 'C3673279', name: 'familial cardiomyopathy' },
            { cui: 'C3672710', name: 'heart failure screening' },
            { cui: 'C3672616', name: 'microvascular dysfunction' },
            { cui: 'C3671643', name: 'inflammatory heart diseases' },
            { cui: 'C3671321', name: 'cardiac arrest' },
            {
              cui: 'C3671144',
              name: 'anomalous left coronary artery from the pulmonary artery (alcapa)',
            },
            { cui: 'C3671143', name: 'anomalous coronary artery (aca) repair' },
            { cui: 'C3670717', name: 'arteriosclerosis' },
            { cui: 'C3670342', name: 'multi system inflammatory syndrome' },
            { cui: 'C3670163', name: 'exercise induced arrhythmia' },
            { cui: 'C3670161', name: 'inappropriate sinus tachycardia' },
            { cui: 'C3670159', name: 'exercise induced syncope' },
            { cui: 'C3669688', name: 'variant angina' },
            { cui: 'C3669687', name: 'vasospastic angina' },
            { cui: 'C3669635', name: 'left ventricular dysfunction' },
            { cui: 'C3669491', name: 'heart valve disease' },
            { cui: 'C3669489', name: 'coronary artery calcification' },
            { cui: 'C3669485', name: 'cardiopulmonary disease' },
            {
              cui: 'C3667902',
              name: 'sports related cardiovascular assessment',
            },
            { cui: 'C3667658', name: 'valvular heart disease' },
            { cui: 'C3666968', name: 'left ventricular hypertrophy' },
            {
              cui: 'C3666782',
              name: 'arrhythmogenic right ventricular cardiomyopathy',
            },
            { cui: 'C3666443', name: 'heart disease in women' },
            { cui: 'C3666436', name: 'tachycardia bradycardia syndrome' },
            { cui: 'C3666374', name: 'single ventricle defect' },
            { cui: 'C3666371', name: 'fibromuscular dysplasia' },
            { cui: 'C3666368', name: 'aortic valve sclerosis' },
            { cui: 'C3666282', name: 'left heart failure' },
            { cui: 'C3666222', name: 'cardiac insufficiency' },
            { cui: 'C3666190', name: 'abnormal coronary angiography' },
            {
              cui: 'C3666122',
              name: 'transposition of the great arteries (tga)',
            },
            { cui: 'C3665557', name: 'prosthetic heart valves' },
            { cui: 'C3665458', name: 'heart disease' },
            { cui: 'C3665328', name: 'aortic stenosis' },
            { cui: 'C3665320', name: 'edema' },
            { cui: 'C3665204', name: 'electrocardiogram (ekg)' },
            { cui: 'C3663857', name: 'heart health' },
            { cui: 'C3663837', name: 'cardiovascular care' },
            { cui: 'C3663709', name: 'peripheral edema' },
            { cui: 'C3663107', name: 'exercise intolerance' },
            { cui: 'C3663039', name: 'pre excitation syndrome' },
            { cui: 'C3663037', name: 'premature atrial contraction' },
            { cui: 'C3662984', name: 'atrioventricular septal defect' },
            { cui: 'C3662828', name: 'cardiorenal syndrome' },
            { cui: 'C3662822', name: 'hypertensive urgency' },
            { cui: 'C3662821', name: 'uncontrolled hypertension' },
            { cui: 'C3662626', name: 'pericardial effusion' },
            { cui: 'C3662574', name: 'valvular endocarditis' },
            { cui: 'C3662573', name: 'acute bacterial endocarditis' },
            { cui: 'C3662519', name: 'abnormal stress test' },
            { cui: 'C3662501', name: 'abnormal electrocardiogram (ekg)' },
            { cui: 'C3662218', name: 'genetic cardiomyopathy' },
            { cui: 'C3662064', name: 'right heart failure' },
            { cui: 'C3662020', name: 'preoperative evaluation' },
            { cui: 'C3661991', name: 'orthopnea' },
            { cui: 'C3661932', name: 'mitral valve regurgitation' },
            { cui: 'C3661746', name: 'cholesterol management' },
            { cui: 'C3661737', name: 'cardiac tumor' },
            { cui: 'C3661490', name: 'atrial septal defect (asd)' },
            { cui: 'C3661407', name: 'cardiovascular disease' },
            { cui: 'C3661356', name: 'wolff-parkinson-white syndrome' },
            { cui: 'C3661263', name: 'vasovagal syncope' },
            { cui: 'C3661240', name: 'valve stenosis' },
            { cui: 'C3661043', name: 'type 2 second degree heart block' },
            { cui: 'C3661041', name: 'type 1 second degree heart block' },
            { cui: 'C3661005', name: 'tricuspid valve disease' },
            { cui: 'C3661001', name: 'tricuspid regurgitation' },
            { cui: 'C3660977', name: 'transposition of great vessels' },
            { cui: 'C3660846', name: 'thrombosis' },
            { cui: 'C3660762', name: 'tachycardia' },
            { cui: 'C3660745', name: 'syncope' },
            { cui: 'C3660724', name: 'supraventricular tachycardia' },
            { cui: 'C3660711', name: 'family history of sudden cardiac death' },
            { cui: 'C3660692', name: 'subaortic stenosis' },
            { cui: 'C3660461', name: 'sick sinus syndrome' },
            { cui: 'C3660384', name: 'secondary hypertension' },
            { cui: 'C3660274', name: 'right ventricular hypertrophy' },
            { cui: 'C3660267', name: 'right bundle branch block' },
            { cui: 'C3660255', name: 'rheumatic mitral stenosis' },
            { cui: 'C3660254', name: 'rheumatic mitral regurgitation' },
            { cui: 'C3660252', name: 'rheumatic heart disease' },
            { cui: 'C3660251', name: 'rheumatic fever' },
            { cui: 'C3660250', name: 'rheumatic diseases of pulmonary valve' },
            { cui: 'C3660249', name: 'rheumatic aortic stenosis' },
            { cui: 'C3660248', name: 'rheumatic aortic regurgitation' },
            { cui: 'C3660130', name: 'renovascular hypertension' },
            { cui: 'C3660081', name: 'refractory angina' },
            { cui: 'C3659982', name: 'pulmonary valve stenosis' },
            { cui: 'C3659981', name: 'pulmonary valve insufficiency' },
            { cui: 'C3659980', name: 'pulmonary valve disorders' },
            { cui: 'C3659968', name: 'pulmonary edema' },
            { cui: 'C3659964', name: 'pulmonary artery stenosis' },
            { cui: 'C3659957', name: 'pulmonary arterial hypertension' },
            { cui: 'C3659882', name: 'prinzmetals angina' },
            { cui: 'C3659873', name: 'preventative cardiology' },
            { cui: 'C3659861', name: 'premature ventricular contractions' },
            { cui: 'C3659857', name: 'premature beats' },
            { cui: 'C3659846', name: 'postural tachycardia syndrome' },
            { cui: 'C3659641', name: 'pericarditis' },
            { cui: 'C3659567', name: 'patent foramen ovale' },
            { cui: 'C3659566', name: 'patent ductus arteriosus' },
            {
              cui: 'C3659544',
              name: 'partial anomalous pulmonary venous connection (papvc)',
            },
            { cui: 'C3659542', name: 'paroxysmal ventricular tachycardia' },
            { cui: 'C3659541', name: 'paroxysmal tachycardia' },
            {
              cui: 'C3659540',
              name: 'paroxysmal supraventricular tachycardia',
            },
            { cui: 'C3659479', name: 'palpitations' },
            { cui: 'C3659421', name: 'orthostatic hypotension' },
            { cui: 'C3659153', name: 'myocarditis' },
            { cui: 'C3659151', name: 'myocardial infarction (mi)' },
            { cui: 'C3659022', name: 'mitral valve stenosis' },
            { cui: 'C3659019', name: 'mitral valve prolapse' },
            { cui: 'C3659017', name: 'mitral valve disease' },
            { cui: 'C3659016', name: 'mitral regurgitation' },
            { cui: 'C3658858', name: 'maternal hypertension' },
            { cui: 'C3658843', name: 'marfan syndrome' },
            { cui: 'C3658797', name: 'malignant hypertension' },
            { cui: 'C3658586', name: 'left bundle branch block' },
            { cui: 'C3658565', name: 'laser atherectomy' },
            { cui: 'C3658378', name: 'ischemic cardiomyopathy' },
            { cui: 'C3658358', name: 'intravascular ultrasound' },
            { cui: 'C3658326', name: 'intra aortic balloon pump' },
            { cui: 'C3658272', name: 'infiltrative disorders of the heart' },
            { cui: 'C3658242', name: 'implantable defibrillator' },
            {
              cui: 'C3658215',
              name: 'idiopathic hypertrophic subaortic stenosis',
            },
            { cui: 'C3658165', name: 'hypertrophic cardiomyopathy' },
            { cui: 'C3658158', name: 'hypertension' },
            { cui: 'C3657969', name: 'heart racing' },
            { cui: 'C3657968', name: 'heart problems' },
            { cui: 'C3657967', name: 'heart murmur' },
            { cui: 'C3657669', name: 'first degree heart block' },
            { cui: 'C3657612', name: 'family history of heart disease' },
            { cui: 'C3657597', name: 'familial arrhythmias' },
            { cui: 'C3657307', name: 'echocardiography' },
            { cui: 'C3657284', name: 'dyspnea on exertion' },
            { cui: 'C3657151', name: 'diabetic cardiomyopathy' },
            { cui: 'C3656923', name: 'coronary calcium scoring' },
            { cui: 'C3656921', name: 'coronary artery disease' },
            { cui: 'C3656919', name: 'anomalies of the coronary artery' },
            { cui: 'C3656844', name: 'congestive heart failure (chf)' },
            { cui: 'C3656765', name: 'conduction defects' },
            { cui: 'C3656673', name: 'chronic total occlusion' },
            { cui: 'C3656633', name: 'cholesterol disorders' },
            { cui: 'C3656603', name: 'chest pain' },
            { cui: 'C3656479', name: 'cardiomyopathy' },
            { cui: 'C3656478', name: 'cardiomegaly' },
            { cui: 'C3656477', name: 'cardiac sarcoma' },
            { cui: 'C3656475', name: 'cardiac risk assessment' },
            { cui: 'C3656473', name: 'cardiac mri' },
            { cui: 'C3656470', name: 'cardiac genetics' },
            { cui: 'C3656465', name: 'cardiac conduction defects' },
            { cui: 'C3656463', name: 'cardiac arrhythmias' },
            { cui: 'C3656462', name: 'cardiac angina' },
            { cui: 'C3656319', name: 'bradycardia' },
            { cui: 'C3656205', name: 'bicuspid aortic valve' },
            { cui: 'C3656132', name: 'bacterial endocarditis' },
            { cui: 'C3656101', name: 'autoimmune pericarditis' },
            { cui: 'C3656080', name: 'atrioventricular reentrant tachycardia' },
            {
              cui: 'C3656079',
              name: 'atrioventricular nodal reentrant tachycardia',
            },
            { cui: 'C3656078', name: 'atrioventricular block' },
            { cui: 'C3656077', name: 'atrial tachycardia' },
            { cui: 'C3656075', name: 'atrial flutter' },
            { cui: 'C3656073', name: 'atrial fibrillation' },
            { cui: 'C3656068', name: 'atherosclerosis of the aorta' },
            { cui: 'C3656066', name: 'atherosclerosis' },
            { cui: 'C3655970', name: 'aortic valve disease' },
            { cui: 'C3655969', name: 'aortic diseases' },
            { cui: 'C3655967', name: 'aortic coarctation' },
            { cui: 'C3655957', name: 'anticoagulation' },
            { cui: 'C3655845', name: 'primary systemic amyloidosis' },
            { cui: 'C3655844', name: 'amyloidosis' },
          ],
        },
        clinical_trial_text: null,
        clinical_trials: [],
        contact_info: null,
        contacts: [
          {
            contact_type: 'phone',
            extension: null,
            subtype: null,
            value: null,
          },
          { contact_type: 'fax', extension: null, subtype: null, value: null },
          {
            contact_type: 'clinical_contact_info',
            extension: null,
            subtype: null,
            value: null,
          },
        ],
        date_of_birth: null,
        dea_number: 'MM1925188',
        default_timezone: 'UTC',
        degrees: [{ name: 'CRNP', source: 'CRNP', source_url: null }],
        department_name: null,
        departments: ['Providers'],
        direct_book_capable: false,
        direct_book_pmac: false,
        direct_book_pmc: false,
        division: null,
        ehr_platform: null,
        entity_type: null,
        external_id: 'F50BA23F-40FC-4B9C-A864-DD5A2346E7FE',
        external_links: null,
        external_systems: null,
        gender: 'Female',
        google_category: null,
        graduate_education: [],
        grants: [],
        gx: { max_age: 43800, min_age: 7665 },
        has_video_url: false,
        id: 3844663,
        image_object: null,
        image_url: null,
        institutes: null,
        insurance_accepted: [],
        internal_notes: null,
        is_primary_care: true,
        is_specialty_care: true,
        ksched_appointment_request_email: null,
        language_disclaimer: null,
        languages: ['English'],
        leadership_title: null,
        legal: [],
        lgbtq_inclusive: false,
        license: [],
        listings_syndication: null,
        logo: 'Display Logo',
        manually_created: null,
        marketing_campaign_name: null,
        media_mentions: [],
        medical_license: 'SP009140',
        multi_resource_scheduling: null,
        name: {
          first: 'Cheryl',
          first_last: 'Cheryl Marbella',
          full: 'Cheryl Blasko Marbella',
          last: 'Marbella',
          middle: 'Blasko',
          preferred: 'Cheryl Blasko',
        },
        name_pronunciation: null,
        near_term_availability: null,
        network_affiliations: [{ name: 'Jefferson Hospital', rank: null, type: 'Hospital' }],
        networks: ['Customer Network'],
        new_pt_online_booking: false,
        night_weekend_availability: false,
        notes: null,
        npi: '1437234705',
        office_hours_text: null,
        open_scheduling: false,
        personal_interests: null,
        philosophy_of_care: null,
        pmc_db_only_provider: null,
        pmc_db_only_provider_flag: false,
        practice_groups: [
          {
            has_info: false,
            id: 85217,
            is_primary_care: false,
            name: 'Providers',
          },
        ],
        professional_memberships: null,
        professional_statement: null,
        pronouns: null,
        provider_business_description: null,
        provider_email: null,
        provider_is_employed: true,
        provider_is_location: false,
        provider_title: null,
        provider_type: 'Nurse Practitioner (NP)',
        publication_text: null,
        publication_url: null,
        publications: [],
        publish_to_brandify: null,
        rating: {},
        ratings: {},
        region: null,
        request_appointment_url: null,
        research_interest: null,
        reviews: null,
        sched_interval_duration_days: null,
        sched_interval_same_day_minute_delay: null,
        sched_interval_start_days: null,
        scheduling_notes: null,
        section: null,
        service_line: null,
        show_cta_modal: 'Yes',
        show_in_pmac: 'Yes',
        show_in_pmc: 'Yes',
        sort_field: '1',
        sort_preferences: {},
        source_specialty: 'Cardiovascular Disease',
        special_program: null,
        specialties: [{ eui: 'E0000008', name: 'Cardiology' }],
        status_transitions: null,
        suffix: null,
        surgical_status: null,
        syndicate_to_google: false,
        system_id: null,
        telehealth: false,
        telehealth_badge: false,
        training: [
          {
            city: null,
            country: null,
            degree: null,
            field_of_study: null,
            graduation_date: null,
            graduation_year: '2006',
            name: 'University of Pittsburgh',
            rank: null,
            state: null,
            street1: null,
            street2: null,
            type: 'Education',
            zip: null,
          },
        ],
        urgent_care_location: false,
        video_url: null,
        video_visit: false,
        virtual_care: false,
        virtual_care_url: null,
        walkins_welcome: false,
        web_phone_number: null,
        xp_provider_group: null,
        years_in_practice: null,
      },
      provider_id: 3844663,
      sort: [{ relevance: 13.0 }, { shuffle: 65553290.0 }],
    },
    {
      availability: {
        facets: null,
        meta: {
          availability_density_best: null,
          max_date_available: null,
          min_date_available: null,
          slot_count: 0,
          tz_max_date_available: null,
          tz_min_date_available: null,
        },
        slots: [],
      },
      customer: 'ahn',
      locations: [],
      match: {
        highlight: ['<em>Cardiology</em>'],
        score: 13,
        type: 'Specialty Exact Match',
      },
      provider: {
        about_the_provider: null,
        academic_department_name: null,
        academic_title: null,
        accepting_new_patients: true,
        access_program_status: null,
        additional_services: null,
        additional_visit_types: null,
        age_groups_seen: ['Adult 21-65', 'Older Adult >65'],
        aha_id: null,
        always_available_provider: false,
        appointment_ehr_purposes: [],
        awards: null,
        board_certifications: [],
        book_online_override_url: null,
        clinic_location_url: null,
        clinical_contact: null,
        clinical_interests: null,
        clinical_keywords: {
          promoted: [],
          searchable: [
            { cui: 'C3680038', name: 'cardiogenic syncope' },
            { cui: 'C3679884', name: 'familial hypertension' },
            {
              cui: 'C3679866',
              name: 'myocardial infarction with non-obstructive coronary artery disease',
            },
            { cui: 'C3675846', name: 'chest pain with exercise' },
            { cui: 'C3675086', name: 'arrhythmia' },
            { cui: 'C3673288', name: 'echocardiogram' },
            { cui: 'C3673286', name: 'familial amyloidosis' },
            { cui: 'C3673279', name: 'familial cardiomyopathy' },
            { cui: 'C3672710', name: 'heart failure screening' },
            { cui: 'C3672616', name: 'microvascular dysfunction' },
            { cui: 'C3671643', name: 'inflammatory heart diseases' },
            { cui: 'C3671321', name: 'cardiac arrest' },
            {
              cui: 'C3671144',
              name: 'anomalous left coronary artery from the pulmonary artery (alcapa)',
            },
            { cui: 'C3671143', name: 'anomalous coronary artery (aca) repair' },
            { cui: 'C3670717', name: 'arteriosclerosis' },
            { cui: 'C3670342', name: 'multi system inflammatory syndrome' },
            { cui: 'C3670163', name: 'exercise induced arrhythmia' },
            { cui: 'C3670161', name: 'inappropriate sinus tachycardia' },
            { cui: 'C3670159', name: 'exercise induced syncope' },
            { cui: 'C3669688', name: 'variant angina' },
            { cui: 'C3669687', name: 'vasospastic angina' },
            { cui: 'C3669635', name: 'left ventricular dysfunction' },
            { cui: 'C3669491', name: 'heart valve disease' },
            { cui: 'C3669489', name: 'coronary artery calcification' },
            { cui: 'C3669485', name: 'cardiopulmonary disease' },
            {
              cui: 'C3667902',
              name: 'sports related cardiovascular assessment',
            },
            { cui: 'C3667658', name: 'valvular heart disease' },
            { cui: 'C3666968', name: 'left ventricular hypertrophy' },
            {
              cui: 'C3666782',
              name: 'arrhythmogenic right ventricular cardiomyopathy',
            },
            { cui: 'C3666443', name: 'heart disease in women' },
            { cui: 'C3666436', name: 'tachycardia bradycardia syndrome' },
            { cui: 'C3666374', name: 'single ventricle defect' },
            { cui: 'C3666371', name: 'fibromuscular dysplasia' },
            { cui: 'C3666368', name: 'aortic valve sclerosis' },
            { cui: 'C3666282', name: 'left heart failure' },
            { cui: 'C3666222', name: 'cardiac insufficiency' },
            { cui: 'C3666190', name: 'abnormal coronary angiography' },
            {
              cui: 'C3666122',
              name: 'transposition of the great arteries (tga)',
            },
            { cui: 'C3665557', name: 'prosthetic heart valves' },
            { cui: 'C3665458', name: 'heart disease' },
            { cui: 'C3665328', name: 'aortic stenosis' },
            { cui: 'C3665320', name: 'edema' },
            { cui: 'C3665204', name: 'electrocardiogram (ekg)' },
            { cui: 'C3663857', name: 'heart health' },
            { cui: 'C3663837', name: 'cardiovascular care' },
            { cui: 'C3663709', name: 'peripheral edema' },
            { cui: 'C3663107', name: 'exercise intolerance' },
            { cui: 'C3663039', name: 'pre excitation syndrome' },
            { cui: 'C3663037', name: 'premature atrial contraction' },
            { cui: 'C3662984', name: 'atrioventricular septal defect' },
            { cui: 'C3662828', name: 'cardiorenal syndrome' },
            { cui: 'C3662822', name: 'hypertensive urgency' },
            { cui: 'C3662821', name: 'uncontrolled hypertension' },
            { cui: 'C3662626', name: 'pericardial effusion' },
            { cui: 'C3662574', name: 'valvular endocarditis' },
            { cui: 'C3662573', name: 'acute bacterial endocarditis' },
            { cui: 'C3662519', name: 'abnormal stress test' },
            { cui: 'C3662501', name: 'abnormal electrocardiogram (ekg)' },
            { cui: 'C3662218', name: 'genetic cardiomyopathy' },
            { cui: 'C3662064', name: 'right heart failure' },
            { cui: 'C3662020', name: 'preoperative evaluation' },
            { cui: 'C3661991', name: 'orthopnea' },
            { cui: 'C3661932', name: 'mitral valve regurgitation' },
            { cui: 'C3661746', name: 'cholesterol management' },
            { cui: 'C3661737', name: 'cardiac tumor' },
            { cui: 'C3661490', name: 'atrial septal defect (asd)' },
            { cui: 'C3661407', name: 'cardiovascular disease' },
            { cui: 'C3661356', name: 'wolff-parkinson-white syndrome' },
            { cui: 'C3661263', name: 'vasovagal syncope' },
            { cui: 'C3661240', name: 'valve stenosis' },
            { cui: 'C3661043', name: 'type 2 second degree heart block' },
            { cui: 'C3661041', name: 'type 1 second degree heart block' },
            { cui: 'C3661005', name: 'tricuspid valve disease' },
            { cui: 'C3661001', name: 'tricuspid regurgitation' },
            { cui: 'C3660977', name: 'transposition of great vessels' },
            { cui: 'C3660846', name: 'thrombosis' },
            { cui: 'C3660762', name: 'tachycardia' },
            { cui: 'C3660745', name: 'syncope' },
            { cui: 'C3660724', name: 'supraventricular tachycardia' },
            { cui: 'C3660711', name: 'family history of sudden cardiac death' },
            { cui: 'C3660692', name: 'subaortic stenosis' },
            { cui: 'C3660461', name: 'sick sinus syndrome' },
            { cui: 'C3660384', name: 'secondary hypertension' },
            { cui: 'C3660274', name: 'right ventricular hypertrophy' },
            { cui: 'C3660267', name: 'right bundle branch block' },
            { cui: 'C3660255', name: 'rheumatic mitral stenosis' },
            { cui: 'C3660254', name: 'rheumatic mitral regurgitation' },
            { cui: 'C3660252', name: 'rheumatic heart disease' },
            { cui: 'C3660251', name: 'rheumatic fever' },
            { cui: 'C3660250', name: 'rheumatic diseases of pulmonary valve' },
            { cui: 'C3660249', name: 'rheumatic aortic stenosis' },
            { cui: 'C3660248', name: 'rheumatic aortic regurgitation' },
            { cui: 'C3660130', name: 'renovascular hypertension' },
            { cui: 'C3660081', name: 'refractory angina' },
            { cui: 'C3659982', name: 'pulmonary valve stenosis' },
            { cui: 'C3659981', name: 'pulmonary valve insufficiency' },
            { cui: 'C3659980', name: 'pulmonary valve disorders' },
            { cui: 'C3659968', name: 'pulmonary edema' },
            { cui: 'C3659964', name: 'pulmonary artery stenosis' },
            { cui: 'C3659957', name: 'pulmonary arterial hypertension' },
            { cui: 'C3659882', name: 'prinzmetals angina' },
            { cui: 'C3659873', name: 'preventative cardiology' },
            { cui: 'C3659861', name: 'premature ventricular contractions' },
            { cui: 'C3659857', name: 'premature beats' },
            { cui: 'C3659846', name: 'postural tachycardia syndrome' },
            { cui: 'C3659641', name: 'pericarditis' },
            { cui: 'C3659567', name: 'patent foramen ovale' },
            { cui: 'C3659566', name: 'patent ductus arteriosus' },
            {
              cui: 'C3659544',
              name: 'partial anomalous pulmonary venous connection (papvc)',
            },
            { cui: 'C3659542', name: 'paroxysmal ventricular tachycardia' },
            { cui: 'C3659541', name: 'paroxysmal tachycardia' },
            {
              cui: 'C3659540',
              name: 'paroxysmal supraventricular tachycardia',
            },
            { cui: 'C3659479', name: 'palpitations' },
            { cui: 'C3659421', name: 'orthostatic hypotension' },
            { cui: 'C3659153', name: 'myocarditis' },
            { cui: 'C3659151', name: 'myocardial infarction (mi)' },
            { cui: 'C3659022', name: 'mitral valve stenosis' },
            { cui: 'C3659019', name: 'mitral valve prolapse' },
            { cui: 'C3659017', name: 'mitral valve disease' },
            { cui: 'C3659016', name: 'mitral regurgitation' },
            { cui: 'C3658858', name: 'maternal hypertension' },
            { cui: 'C3658843', name: 'marfan syndrome' },
            { cui: 'C3658797', name: 'malignant hypertension' },
            { cui: 'C3658586', name: 'left bundle branch block' },
            { cui: 'C3658565', name: 'laser atherectomy' },
            { cui: 'C3658378', name: 'ischemic cardiomyopathy' },
            { cui: 'C3658358', name: 'intravascular ultrasound' },
            { cui: 'C3658326', name: 'intra aortic balloon pump' },
            { cui: 'C3658272', name: 'infiltrative disorders of the heart' },
            { cui: 'C3658242', name: 'implantable defibrillator' },
            {
              cui: 'C3658215',
              name: 'idiopathic hypertrophic subaortic stenosis',
            },
            { cui: 'C3658165', name: 'hypertrophic cardiomyopathy' },
            { cui: 'C3658158', name: 'hypertension' },
            { cui: 'C3657969', name: 'heart racing' },
            { cui: 'C3657968', name: 'heart problems' },
            { cui: 'C3657967', name: 'heart murmur' },
            { cui: 'C3657669', name: 'first degree heart block' },
            { cui: 'C3657612', name: 'family history of heart disease' },
            { cui: 'C3657597', name: 'familial arrhythmias' },
            { cui: 'C3657307', name: 'echocardiography' },
            { cui: 'C3657284', name: 'dyspnea on exertion' },
            { cui: 'C3657151', name: 'diabetic cardiomyopathy' },
            { cui: 'C3656923', name: 'coronary calcium scoring' },
            { cui: 'C3656921', name: 'coronary artery disease' },
            { cui: 'C3656919', name: 'anomalies of the coronary artery' },
            { cui: 'C3656844', name: 'congestive heart failure (chf)' },
            { cui: 'C3656765', name: 'conduction defects' },
            { cui: 'C3656673', name: 'chronic total occlusion' },
            { cui: 'C3656633', name: 'cholesterol disorders' },
            { cui: 'C3656603', name: 'chest pain' },
            { cui: 'C3656479', name: 'cardiomyopathy' },
            { cui: 'C3656478', name: 'cardiomegaly' },
            { cui: 'C3656477', name: 'cardiac sarcoma' },
            { cui: 'C3656475', name: 'cardiac risk assessment' },
            { cui: 'C3656473', name: 'cardiac mri' },
            { cui: 'C3656470', name: 'cardiac genetics' },
            { cui: 'C3656465', name: 'cardiac conduction defects' },
            { cui: 'C3656463', name: 'cardiac arrhythmias' },
            { cui: 'C3656462', name: 'cardiac angina' },
            { cui: 'C3656319', name: 'bradycardia' },
            { cui: 'C3656205', name: 'bicuspid aortic valve' },
            { cui: 'C3656132', name: 'bacterial endocarditis' },
            { cui: 'C3656101', name: 'autoimmune pericarditis' },
            { cui: 'C3656080', name: 'atrioventricular reentrant tachycardia' },
            {
              cui: 'C3656079',
              name: 'atrioventricular nodal reentrant tachycardia',
            },
            { cui: 'C3656078', name: 'atrioventricular block' },
            { cui: 'C3656077', name: 'atrial tachycardia' },
            { cui: 'C3656075', name: 'atrial flutter' },
            { cui: 'C3656073', name: 'atrial fibrillation' },
            { cui: 'C3656068', name: 'atherosclerosis of the aorta' },
            { cui: 'C3656066', name: 'atherosclerosis' },
            { cui: 'C3655970', name: 'aortic valve disease' },
            { cui: 'C3655969', name: 'aortic diseases' },
            { cui: 'C3655967', name: 'aortic coarctation' },
            { cui: 'C3655957', name: 'anticoagulation' },
            { cui: 'C3655845', name: 'primary systemic amyloidosis' },
            { cui: 'C3655844', name: 'amyloidosis' },
          ],
        },
        clinical_trial_text: null,
        clinical_trials: [],
        contact_info: null,
        contacts: [
          {
            contact_type: 'phone',
            extension: null,
            subtype: null,
            value: null,
          },
          { contact_type: 'fax', extension: null, subtype: null, value: null },
          {
            contact_type: 'clinical_contact_info',
            extension: null,
            subtype: null,
            value: null,
          },
        ],
        date_of_birth: null,
        dea_number: 'MH2670087',
        default_timezone: 'UTC',
        degrees: [{ name: 'CRNP', source: 'CRNP', source_url: null }],
        department_name: null,
        departments: ['Providers'],
        direct_book_capable: false,
        direct_book_pmac: false,
        direct_book_pmc: false,
        division: null,
        ehr_platform: null,
        entity_type: null,
        external_id: '443360F9-9C81-499F-969B-1D5504AD844E',
        external_links: null,
        external_systems: null,
        gender: 'Female',
        google_category: null,
        graduate_education: [],
        grants: [],
        gx: { max_age: 43800, min_age: 7665 },
        has_video_url: false,
        id: 3844356,
        image_object: null,
        image_url: null,
        institutes: null,
        insurance_accepted: [],
        internal_notes: null,
        is_primary_care: true,
        is_specialty_care: true,
        ksched_appointment_request_email: null,
        language_disclaimer: null,
        languages: ['English'],
        leadership_title: null,
        legal: [],
        lgbtq_inclusive: false,
        license: [],
        listings_syndication: null,
        logo: 'Display Logo',
        manually_created: null,
        marketing_campaign_name: null,
        media_mentions: [],
        medical_license: 'SP012055',
        multi_resource_scheduling: null,
        name: {
          first: 'Sharon',
          first_last: 'Sharon Hunt',
          full: 'Sharon Rene Hunt',
          last: 'Hunt',
          middle: 'Rene',
          preferred: 'Sharon Rene',
        },
        name_pronunciation: null,
        near_term_availability: null,
        network_affiliations: [{ name: 'Jefferson Hospital', rank: null, type: 'Hospital' }],
        networks: ['Customer Network'],
        new_pt_online_booking: false,
        night_weekend_availability: false,
        notes: null,
        npi: '1518228469',
        office_hours_text: null,
        open_scheduling: false,
        personal_interests: null,
        philosophy_of_care: null,
        pmc_db_only_provider: null,
        pmc_db_only_provider_flag: false,
        practice_groups: [
          {
            has_info: false,
            id: 85217,
            is_primary_care: false,
            name: 'Providers',
          },
        ],
        professional_memberships: null,
        professional_statement: null,
        pronouns: null,
        provider_business_description: null,
        provider_email: null,
        provider_is_employed: true,
        provider_is_location: false,
        provider_title: null,
        provider_type: 'Nurse Practitioner (NP)',
        publication_text: null,
        publication_url: null,
        publications: [],
        publish_to_brandify: null,
        rating: {},
        ratings: {},
        region: null,
        request_appointment_url: null,
        research_interest: null,
        reviews: null,
        sched_interval_duration_days: null,
        sched_interval_same_day_minute_delay: null,
        sched_interval_start_days: null,
        scheduling_notes: null,
        section: null,
        service_line: null,
        show_cta_modal: 'Yes',
        show_in_pmac: 'Yes',
        show_in_pmc: 'Yes',
        sort_field: '1',
        sort_preferences: {},
        source_specialty: 'Cardiovascular Disease',
        special_program: null,
        specialties: [{ eui: 'E0000008', name: 'Cardiology' }],
        status_transitions: null,
        suffix: null,
        surgical_status: null,
        syndicate_to_google: false,
        system_id: null,
        telehealth: false,
        telehealth_badge: false,
        training: [
          {
            city: null,
            country: null,
            degree: null,
            field_of_study: null,
            graduation_date: null,
            graduation_year: '2011',
            name: 'Carlow University',
            rank: null,
            state: null,
            street1: null,
            street2: null,
            type: 'Education',
            zip: null,
          },
        ],
        urgent_care_location: false,
        video_url: null,
        video_visit: false,
        virtual_care: false,
        virtual_care_url: null,
        walkins_welcome: false,
        web_phone_number: null,
        xp_provider_group: null,
        years_in_practice: null,
      },
      provider_id: 3844356,
      sort: [{ relevance: 13.0 }, { shuffle: 66383127.0 }],
    },
    {
      availability: {
        facets: null,
        meta: {
          availability_density_best: null,
          max_date_available: null,
          min_date_available: null,
          slot_count: 0,
          tz_max_date_available: null,
          tz_min_date_available: null,
        },
        slots: [],
      },
      customer: 'ahn',
      locations: [],
      match: {
        highlight: ['<em>Cardiology</em>'],
        score: 13,
        type: 'Specialty Exact Match',
      },
      provider: {
        about_the_provider: null,
        academic_department_name: null,
        academic_title: null,
        accepting_new_patients: true,
        access_program_status: null,
        additional_services: null,
        additional_visit_types: null,
        age_groups_seen: ['Adult 21-65', 'Older Adult >65'],
        aha_id: null,
        always_available_provider: false,
        appointment_ehr_purposes: [],
        awards: null,
        board_certifications: [
          {
            board_name: 'American Board of Internal Medicine',
            board_specialty: 'BC0000055',
            certification_type: 'board',
            certifying_board: 'BC0000007',
            rank: 1,
            specialty_name: 'Cardiovascular Disease',
            year_certified: 1989,
          },
        ],
        book_online_override_url: null,
        clinic_location_url: null,
        clinical_contact: null,
        clinical_interests: null,
        clinical_keywords: {
          promoted: [],
          searchable: [
            { cui: 'C3680038', name: 'cardiogenic syncope' },
            { cui: 'C3679884', name: 'familial hypertension' },
            {
              cui: 'C3679866',
              name: 'myocardial infarction with non-obstructive coronary artery disease',
            },
            { cui: 'C3675846', name: 'chest pain with exercise' },
            { cui: 'C3675086', name: 'arrhythmia' },
            { cui: 'C3673288', name: 'echocardiogram' },
            { cui: 'C3673286', name: 'familial amyloidosis' },
            { cui: 'C3673279', name: 'familial cardiomyopathy' },
            { cui: 'C3672710', name: 'heart failure screening' },
            { cui: 'C3672616', name: 'microvascular dysfunction' },
            { cui: 'C3671643', name: 'inflammatory heart diseases' },
            { cui: 'C3671321', name: 'cardiac arrest' },
            {
              cui: 'C3671144',
              name: 'anomalous left coronary artery from the pulmonary artery (alcapa)',
            },
            { cui: 'C3671143', name: 'anomalous coronary artery (aca) repair' },
            { cui: 'C3670717', name: 'arteriosclerosis' },
            { cui: 'C3670342', name: 'multi system inflammatory syndrome' },
            { cui: 'C3670163', name: 'exercise induced arrhythmia' },
            { cui: 'C3670161', name: 'inappropriate sinus tachycardia' },
            { cui: 'C3670159', name: 'exercise induced syncope' },
            { cui: 'C3669688', name: 'variant angina' },
            { cui: 'C3669687', name: 'vasospastic angina' },
            { cui: 'C3669635', name: 'left ventricular dysfunction' },
            { cui: 'C3669491', name: 'heart valve disease' },
            { cui: 'C3669489', name: 'coronary artery calcification' },
            { cui: 'C3669485', name: 'cardiopulmonary disease' },
            {
              cui: 'C3667902',
              name: 'sports related cardiovascular assessment',
            },
            { cui: 'C3667658', name: 'valvular heart disease' },
            { cui: 'C3666968', name: 'left ventricular hypertrophy' },
            {
              cui: 'C3666782',
              name: 'arrhythmogenic right ventricular cardiomyopathy',
            },
            { cui: 'C3666443', name: 'heart disease in women' },
            { cui: 'C3666436', name: 'tachycardia bradycardia syndrome' },
            { cui: 'C3666374', name: 'single ventricle defect' },
            { cui: 'C3666371', name: 'fibromuscular dysplasia' },
            { cui: 'C3666368', name: 'aortic valve sclerosis' },
            { cui: 'C3666282', name: 'left heart failure' },
            { cui: 'C3666222', name: 'cardiac insufficiency' },
            { cui: 'C3666190', name: 'abnormal coronary angiography' },
            {
              cui: 'C3666122',
              name: 'transposition of the great arteries (tga)',
            },
            { cui: 'C3665557', name: 'prosthetic heart valves' },
            { cui: 'C3665458', name: 'heart disease' },
            { cui: 'C3665328', name: 'aortic stenosis' },
            { cui: 'C3665320', name: 'edema' },
            { cui: 'C3665204', name: 'electrocardiogram (ekg)' },
            { cui: 'C3663857', name: 'heart health' },
            { cui: 'C3663837', name: 'cardiovascular care' },
            { cui: 'C3663709', name: 'peripheral edema' },
            { cui: 'C3663107', name: 'exercise intolerance' },
            { cui: 'C3663039', name: 'pre excitation syndrome' },
            { cui: 'C3663037', name: 'premature atrial contraction' },
            { cui: 'C3662984', name: 'atrioventricular septal defect' },
            { cui: 'C3662828', name: 'cardiorenal syndrome' },
            { cui: 'C3662822', name: 'hypertensive urgency' },
            { cui: 'C3662821', name: 'uncontrolled hypertension' },
            { cui: 'C3662626', name: 'pericardial effusion' },
            { cui: 'C3662574', name: 'valvular endocarditis' },
            { cui: 'C3662573', name: 'acute bacterial endocarditis' },
            { cui: 'C3662519', name: 'abnormal stress test' },
            { cui: 'C3662501', name: 'abnormal electrocardiogram (ekg)' },
            { cui: 'C3662218', name: 'genetic cardiomyopathy' },
            { cui: 'C3662064', name: 'right heart failure' },
            { cui: 'C3662020', name: 'preoperative evaluation' },
            { cui: 'C3661991', name: 'orthopnea' },
            { cui: 'C3661932', name: 'mitral valve regurgitation' },
            { cui: 'C3661746', name: 'cholesterol management' },
            { cui: 'C3661737', name: 'cardiac tumor' },
            { cui: 'C3661490', name: 'atrial septal defect (asd)' },
            { cui: 'C3661407', name: 'cardiovascular disease' },
            { cui: 'C3661356', name: 'wolff-parkinson-white syndrome' },
            { cui: 'C3661263', name: 'vasovagal syncope' },
            { cui: 'C3661240', name: 'valve stenosis' },
            { cui: 'C3661043', name: 'type 2 second degree heart block' },
            { cui: 'C3661041', name: 'type 1 second degree heart block' },
            { cui: 'C3661005', name: 'tricuspid valve disease' },
            { cui: 'C3661001', name: 'tricuspid regurgitation' },
            { cui: 'C3660977', name: 'transposition of great vessels' },
            { cui: 'C3660846', name: 'thrombosis' },
            { cui: 'C3660762', name: 'tachycardia' },
            { cui: 'C3660745', name: 'syncope' },
            { cui: 'C3660724', name: 'supraventricular tachycardia' },
            { cui: 'C3660711', name: 'family history of sudden cardiac death' },
            { cui: 'C3660692', name: 'subaortic stenosis' },
            { cui: 'C3660461', name: 'sick sinus syndrome' },
            { cui: 'C3660384', name: 'secondary hypertension' },
            { cui: 'C3660274', name: 'right ventricular hypertrophy' },
            { cui: 'C3660267', name: 'right bundle branch block' },
            { cui: 'C3660255', name: 'rheumatic mitral stenosis' },
            { cui: 'C3660254', name: 'rheumatic mitral regurgitation' },
            { cui: 'C3660252', name: 'rheumatic heart disease' },
            { cui: 'C3660251', name: 'rheumatic fever' },
            { cui: 'C3660250', name: 'rheumatic diseases of pulmonary valve' },
            { cui: 'C3660249', name: 'rheumatic aortic stenosis' },
            { cui: 'C3660248', name: 'rheumatic aortic regurgitation' },
            { cui: 'C3660130', name: 'renovascular hypertension' },
            { cui: 'C3660081', name: 'refractory angina' },
            { cui: 'C3659982', name: 'pulmonary valve stenosis' },
            { cui: 'C3659981', name: 'pulmonary valve insufficiency' },
            { cui: 'C3659980', name: 'pulmonary valve disorders' },
            { cui: 'C3659968', name: 'pulmonary edema' },
            { cui: 'C3659964', name: 'pulmonary artery stenosis' },
            { cui: 'C3659957', name: 'pulmonary arterial hypertension' },
            { cui: 'C3659882', name: 'prinzmetals angina' },
            { cui: 'C3659873', name: 'preventative cardiology' },
            { cui: 'C3659861', name: 'premature ventricular contractions' },
            { cui: 'C3659857', name: 'premature beats' },
            { cui: 'C3659846', name: 'postural tachycardia syndrome' },
            { cui: 'C3659641', name: 'pericarditis' },
            { cui: 'C3659567', name: 'patent foramen ovale' },
            { cui: 'C3659566', name: 'patent ductus arteriosus' },
            {
              cui: 'C3659544',
              name: 'partial anomalous pulmonary venous connection (papvc)',
            },
            { cui: 'C3659542', name: 'paroxysmal ventricular tachycardia' },
            { cui: 'C3659541', name: 'paroxysmal tachycardia' },
            {
              cui: 'C3659540',
              name: 'paroxysmal supraventricular tachycardia',
            },
            { cui: 'C3659479', name: 'palpitations' },
            { cui: 'C3659421', name: 'orthostatic hypotension' },
            { cui: 'C3659153', name: 'myocarditis' },
            { cui: 'C3659151', name: 'myocardial infarction (mi)' },
            { cui: 'C3659022', name: 'mitral valve stenosis' },
            { cui: 'C3659019', name: 'mitral valve prolapse' },
            { cui: 'C3659017', name: 'mitral valve disease' },
            { cui: 'C3659016', name: 'mitral regurgitation' },
            { cui: 'C3658858', name: 'maternal hypertension' },
            { cui: 'C3658843', name: 'marfan syndrome' },
            { cui: 'C3658797', name: 'malignant hypertension' },
            { cui: 'C3658586', name: 'left bundle branch block' },
            { cui: 'C3658565', name: 'laser atherectomy' },
            { cui: 'C3658378', name: 'ischemic cardiomyopathy' },
            { cui: 'C3658358', name: 'intravascular ultrasound' },
            { cui: 'C3658326', name: 'intra aortic balloon pump' },
            { cui: 'C3658272', name: 'infiltrative disorders of the heart' },
            { cui: 'C3658242', name: 'implantable defibrillator' },
            {
              cui: 'C3658215',
              name: 'idiopathic hypertrophic subaortic stenosis',
            },
            { cui: 'C3658165', name: 'hypertrophic cardiomyopathy' },
            { cui: 'C3658158', name: 'hypertension' },
            { cui: 'C3657969', name: 'heart racing' },
            { cui: 'C3657968', name: 'heart problems' },
            { cui: 'C3657967', name: 'heart murmur' },
            { cui: 'C3657669', name: 'first degree heart block' },
            { cui: 'C3657612', name: 'family history of heart disease' },
            { cui: 'C3657597', name: 'familial arrhythmias' },
            { cui: 'C3657307', name: 'echocardiography' },
            { cui: 'C3657284', name: 'dyspnea on exertion' },
            { cui: 'C3657151', name: 'diabetic cardiomyopathy' },
            { cui: 'C3656923', name: 'coronary calcium scoring' },
            { cui: 'C3656921', name: 'coronary artery disease' },
            { cui: 'C3656919', name: 'anomalies of the coronary artery' },
            { cui: 'C3656844', name: 'congestive heart failure (chf)' },
            { cui: 'C3656765', name: 'conduction defects' },
            { cui: 'C3656673', name: 'chronic total occlusion' },
            { cui: 'C3656633', name: 'cholesterol disorders' },
            { cui: 'C3656603', name: 'chest pain' },
            { cui: 'C3656479', name: 'cardiomyopathy' },
            { cui: 'C3656478', name: 'cardiomegaly' },
            { cui: 'C3656477', name: 'cardiac sarcoma' },
            { cui: 'C3656475', name: 'cardiac risk assessment' },
            { cui: 'C3656473', name: 'cardiac mri' },
            { cui: 'C3656470', name: 'cardiac genetics' },
            { cui: 'C3656465', name: 'cardiac conduction defects' },
            { cui: 'C3656463', name: 'cardiac arrhythmias' },
            { cui: 'C3656462', name: 'cardiac angina' },
            { cui: 'C3656319', name: 'bradycardia' },
            { cui: 'C3656205', name: 'bicuspid aortic valve' },
            { cui: 'C3656132', name: 'bacterial endocarditis' },
            { cui: 'C3656101', name: 'autoimmune pericarditis' },
            { cui: 'C3656080', name: 'atrioventricular reentrant tachycardia' },
            {
              cui: 'C3656079',
              name: 'atrioventricular nodal reentrant tachycardia',
            },
            { cui: 'C3656078', name: 'atrioventricular block' },
            { cui: 'C3656077', name: 'atrial tachycardia' },
            { cui: 'C3656075', name: 'atrial flutter' },
            { cui: 'C3656073', name: 'atrial fibrillation' },
            { cui: 'C3656068', name: 'atherosclerosis of the aorta' },
            { cui: 'C3656066', name: 'atherosclerosis' },
            { cui: 'C3655970', name: 'aortic valve disease' },
            { cui: 'C3655969', name: 'aortic diseases' },
            { cui: 'C3655967', name: 'aortic coarctation' },
            { cui: 'C3655957', name: 'anticoagulation' },
            { cui: 'C3655845', name: 'primary systemic amyloidosis' },
            { cui: 'C3655844', name: 'amyloidosis' },
          ],
        },
        clinical_trial_text: null,
        clinical_trials: [],
        contact_info: null,
        contacts: [
          {
            contact_type: 'phone',
            extension: null,
            subtype: null,
            value: null,
          },
          { contact_type: 'fax', extension: null, subtype: null, value: null },
          {
            contact_type: 'clinical_contact_info',
            extension: null,
            subtype: null,
            value: null,
          },
        ],
        date_of_birth: null,
        dea_number: 'BS0245678',
        default_timezone: 'UTC',
        degrees: [{ name: 'MD', source: 'MD', source_url: null }],
        department_name: null,
        departments: ['Providers'],
        direct_book_capable: false,
        direct_book_pmac: false,
        direct_book_pmc: false,
        division: null,
        ehr_platform: null,
        entity_type: null,
        external_id: 'DBB7F7E0-7760-49C5-8B75-6D0DB7CCAC7B',
        external_links: null,
        external_systems: null,
        gender: 'Male',
        google_category: null,
        graduate_education: [],
        grants: [],
        gx: { max_age: 43800, min_age: 7665 },
        has_video_url: false,
        id: 3845838,
        image_object: null,
        image_url: null,
        institutes: null,
        insurance_accepted: [],
        internal_notes: null,
        is_primary_care: false,
        is_specialty_care: true,
        ksched_appointment_request_email: null,
        language_disclaimer: null,
        languages: ['English'],
        leadership_title: null,
        legal: [],
        lgbtq_inclusive: false,
        license: [],
        listings_syndication: null,
        logo: null,
        manually_created: null,
        marketing_campaign_name: null,
        media_mentions: [],
        medical_license: 'MD034676E',
        multi_resource_scheduling: null,
        name: {
          first: 'Gregory',
          first_last: 'Gregory Smith',
          full: 'Gregory Thomas Smith',
          last: 'Smith',
          middle: 'Thomas',
          preferred: 'Gregory Thomas',
        },
        name_pronunciation: null,
        near_term_availability: null,
        network_affiliations: [
          { name: 'Allegheny General Hospital', rank: null, type: 'Hospital' },
          { name: 'Wexford Hospital', rank: null, type: 'Hospital' },
        ],
        networks: ['Affiliated'],
        new_pt_online_booking: false,
        night_weekend_availability: false,
        notes: null,
        npi: '1083608947',
        office_hours_text: null,
        open_scheduling: false,
        personal_interests: null,
        philosophy_of_care: null,
        pmc_db_only_provider: null,
        pmc_db_only_provider_flag: false,
        practice_groups: [
          {
            has_info: false,
            id: 85217,
            is_primary_care: false,
            name: 'Providers',
          },
        ],
        professional_memberships: null,
        professional_statement: null,
        pronouns: null,
        provider_business_description: null,
        provider_email: null,
        provider_is_employed: false,
        provider_is_location: false,
        provider_title: null,
        provider_type: 'Physician',
        publication_text: null,
        publication_url: null,
        publications: [],
        publish_to_brandify: null,
        rating: {},
        ratings: {},
        region: null,
        request_appointment_url: null,
        research_interest: null,
        reviews: null,
        sched_interval_duration_days: null,
        sched_interval_same_day_minute_delay: null,
        sched_interval_start_days: null,
        scheduling_notes: null,
        section: null,
        service_line: null,
        show_cta_modal: 'Yes',
        show_in_pmac: 'No',
        show_in_pmc: 'Yes',
        sort_field: '2',
        sort_preferences: {},
        source_specialty: 'Cardiovascular Disease',
        special_program: null,
        specialties: [{ eui: 'E0000008', name: 'Cardiology' }],
        status_transitions: null,
        suffix: null,
        surgical_status: null,
        syndicate_to_google: false,
        system_id: null,
        telehealth: false,
        telehealth_badge: false,
        training: [
          {
            city: null,
            country: null,
            degree: null,
            field_of_study: null,
            graduation_date: null,
            graduation_year: '1982',
            name: 'Jefferson Medical College of Thomas Jefferson University',
            rank: null,
            state: null,
            street1: null,
            street2: null,
            type: 'Education',
            zip: null,
          },
          {
            city: null,
            country: null,
            degree: null,
            field_of_study: 'Internal Medicine',
            graduation_date: null,
            graduation_year: '1987',
            name: 'Allegheny General Hospital',
            rank: null,
            state: null,
            street1: null,
            street2: null,
            type: 'Residency',
            zip: null,
          },
          {
            city: null,
            country: null,
            degree: null,
            field_of_study: 'Internal Medicine',
            graduation_date: null,
            graduation_year: '1983',
            name: 'John H. Stroger Jr. Hospital of Cook County',
            rank: null,
            state: null,
            street1: null,
            street2: null,
            type: 'Internship',
            zip: null,
          },
          {
            city: null,
            country: null,
            degree: null,
            field_of_study: 'Cardiovascular Disease (Cardiology)',
            graduation_date: null,
            graduation_year: '1990',
            name: 'Allegheny General Hospital',
            rank: null,
            state: null,
            street1: null,
            street2: null,
            type: 'Fellowship',
            zip: null,
          },
        ],
        urgent_care_location: false,
        video_url: null,
        video_visit: false,
        virtual_care: false,
        virtual_care_url: null,
        walkins_welcome: false,
        web_phone_number: null,
        xp_provider_group: null,
        years_in_practice: null,
      },
      provider_id: 3845838,
      sort: [{ relevance: 13.0 }, { shuffle: 69217281.0 }],
    },
    {
      availability: {
        facets: null,
        meta: {
          availability_density_best: null,
          max_date_available: null,
          min_date_available: null,
          slot_count: 0,
          tz_max_date_available: null,
          tz_min_date_available: null,
        },
        slots: [],
      },
      customer: 'ahn',
      locations: [
        {
          associated_marketable_location_ids: [],
          city: 'Wexford',
          commercial_entity_name: null,
          contacts: [],
          coordinates: { lat: 40.634467, lon: -80.062502 },
          ehr_location_id: '10820010',
          email: null,
          external_id: '10820010',
          external_organization_id: null,
          facility_fee: false,
          fax: '878-332-4468',
          id: 4165472,
          location_page_override_url: null,
          name: 'Allegheny Clinic Cardiology',
          networks: [],
          office_hours: null,
          phone: '878-332-4214',
          primary_marketable_location_id: null,
          rank: 1,
          source_system: 'epic',
          source_system_type: 'epic',
          state: 'PA',
          street1: '12311 Perry Highway',
          street2: '',
          suite: 'Suite 2251',
          timezone: 'UTC',
          type: 'CLI',
          zip: '15090',
        },
      ],
      match: {
        highlight: ['<em>Cardiology</em>'],
        score: 13,
        type: 'Specialty Exact Match',
      },
      provider: {
        about_the_provider:
          "Angela M Ravotti, CRNP Angela M Ravotti, CRNP, graduated from Carlow University in 2016 with a Bachelor of Science in Nursing and worked as a bedside nurse at Allegheny General Hospital for 5 years prior to graduating from Carlow University's Nurse Practitioner program in 2021.  She has been working with the Cardiovascular Institute at Wexford Health + Wellness Pavilion since March 2022.  ",
        academic_department_name: null,
        academic_title: null,
        accepting_new_patients: true,
        access_program_status: null,
        additional_services: null,
        additional_visit_types: null,
        age_groups_seen: ['Adult 21-65', 'Older Adult >65'],
        aha_id: null,
        always_available_provider: false,
        appointment_ehr_purposes: [],
        awards: null,
        board_certifications: [],
        book_online_override_url: null,
        clinic_location_url: null,
        clinical_contact: null,
        clinical_interests: null,
        clinical_keywords: {
          promoted: [],
          searchable: [
            { cui: 'C3680038', name: 'cardiogenic syncope' },
            { cui: 'C3679884', name: 'familial hypertension' },
            {
              cui: 'C3679866',
              name: 'myocardial infarction with non-obstructive coronary artery disease',
            },
            { cui: 'C3675846', name: 'chest pain with exercise' },
            { cui: 'C3675086', name: 'arrhythmia' },
            { cui: 'C3673288', name: 'echocardiogram' },
            { cui: 'C3673286', name: 'familial amyloidosis' },
            { cui: 'C3673279', name: 'familial cardiomyopathy' },
            { cui: 'C3672710', name: 'heart failure screening' },
            { cui: 'C3672616', name: 'microvascular dysfunction' },
            { cui: 'C3671643', name: 'inflammatory heart diseases' },
            { cui: 'C3671321', name: 'cardiac arrest' },
            {
              cui: 'C3671144',
              name: 'anomalous left coronary artery from the pulmonary artery (alcapa)',
            },
            { cui: 'C3671143', name: 'anomalous coronary artery (aca) repair' },
            { cui: 'C3670717', name: 'arteriosclerosis' },
            { cui: 'C3670342', name: 'multi system inflammatory syndrome' },
            { cui: 'C3670163', name: 'exercise induced arrhythmia' },
            { cui: 'C3670161', name: 'inappropriate sinus tachycardia' },
            { cui: 'C3670159', name: 'exercise induced syncope' },
            { cui: 'C3669688', name: 'variant angina' },
            { cui: 'C3669687', name: 'vasospastic angina' },
            { cui: 'C3669635', name: 'left ventricular dysfunction' },
            { cui: 'C3669491', name: 'heart valve disease' },
            { cui: 'C3669489', name: 'coronary artery calcification' },
            { cui: 'C3669485', name: 'cardiopulmonary disease' },
            {
              cui: 'C3667902',
              name: 'sports related cardiovascular assessment',
            },
            { cui: 'C3667658', name: 'valvular heart disease' },
            { cui: 'C3666968', name: 'left ventricular hypertrophy' },
            {
              cui: 'C3666782',
              name: 'arrhythmogenic right ventricular cardiomyopathy',
            },
            { cui: 'C3666443', name: 'heart disease in women' },
            { cui: 'C3666436', name: 'tachycardia bradycardia syndrome' },
            { cui: 'C3666374', name: 'single ventricle defect' },
            { cui: 'C3666371', name: 'fibromuscular dysplasia' },
            { cui: 'C3666368', name: 'aortic valve sclerosis' },
            { cui: 'C3666282', name: 'left heart failure' },
            { cui: 'C3666222', name: 'cardiac insufficiency' },
            { cui: 'C3666190', name: 'abnormal coronary angiography' },
            {
              cui: 'C3666122',
              name: 'transposition of the great arteries (tga)',
            },
            { cui: 'C3665557', name: 'prosthetic heart valves' },
            { cui: 'C3665458', name: 'heart disease' },
            { cui: 'C3665328', name: 'aortic stenosis' },
            { cui: 'C3665320', name: 'edema' },
            { cui: 'C3665204', name: 'electrocardiogram (ekg)' },
            { cui: 'C3663857', name: 'heart health' },
            { cui: 'C3663837', name: 'cardiovascular care' },
            { cui: 'C3663709', name: 'peripheral edema' },
            { cui: 'C3663107', name: 'exercise intolerance' },
            { cui: 'C3663039', name: 'pre excitation syndrome' },
            { cui: 'C3663037', name: 'premature atrial contraction' },
            { cui: 'C3662984', name: 'atrioventricular septal defect' },
            { cui: 'C3662828', name: 'cardiorenal syndrome' },
            { cui: 'C3662822', name: 'hypertensive urgency' },
            { cui: 'C3662821', name: 'uncontrolled hypertension' },
            { cui: 'C3662626', name: 'pericardial effusion' },
            { cui: 'C3662574', name: 'valvular endocarditis' },
            { cui: 'C3662573', name: 'acute bacterial endocarditis' },
            { cui: 'C3662519', name: 'abnormal stress test' },
            { cui: 'C3662501', name: 'abnormal electrocardiogram (ekg)' },
            { cui: 'C3662218', name: 'genetic cardiomyopathy' },
            { cui: 'C3662064', name: 'right heart failure' },
            { cui: 'C3662020', name: 'preoperative evaluation' },
            { cui: 'C3661991', name: 'orthopnea' },
            { cui: 'C3661932', name: 'mitral valve regurgitation' },
            { cui: 'C3661746', name: 'cholesterol management' },
            { cui: 'C3661737', name: 'cardiac tumor' },
            { cui: 'C3661490', name: 'atrial septal defect (asd)' },
            { cui: 'C3661407', name: 'cardiovascular disease' },
            { cui: 'C3661356', name: 'wolff-parkinson-white syndrome' },
            { cui: 'C3661263', name: 'vasovagal syncope' },
            { cui: 'C3661240', name: 'valve stenosis' },
            { cui: 'C3661043', name: 'type 2 second degree heart block' },
            { cui: 'C3661041', name: 'type 1 second degree heart block' },
            { cui: 'C3661005', name: 'tricuspid valve disease' },
            { cui: 'C3661001', name: 'tricuspid regurgitation' },
            { cui: 'C3660977', name: 'transposition of great vessels' },
            { cui: 'C3660846', name: 'thrombosis' },
            { cui: 'C3660762', name: 'tachycardia' },
            { cui: 'C3660745', name: 'syncope' },
            { cui: 'C3660724', name: 'supraventricular tachycardia' },
            { cui: 'C3660711', name: 'family history of sudden cardiac death' },
            { cui: 'C3660692', name: 'subaortic stenosis' },
            { cui: 'C3660461', name: 'sick sinus syndrome' },
            { cui: 'C3660384', name: 'secondary hypertension' },
            { cui: 'C3660274', name: 'right ventricular hypertrophy' },
            { cui: 'C3660267', name: 'right bundle branch block' },
            { cui: 'C3660255', name: 'rheumatic mitral stenosis' },
            { cui: 'C3660254', name: 'rheumatic mitral regurgitation' },
            { cui: 'C3660252', name: 'rheumatic heart disease' },
            { cui: 'C3660251', name: 'rheumatic fever' },
            { cui: 'C3660250', name: 'rheumatic diseases of pulmonary valve' },
            { cui: 'C3660249', name: 'rheumatic aortic stenosis' },
            { cui: 'C3660248', name: 'rheumatic aortic regurgitation' },
            { cui: 'C3660130', name: 'renovascular hypertension' },
            { cui: 'C3660081', name: 'refractory angina' },
            { cui: 'C3659982', name: 'pulmonary valve stenosis' },
            { cui: 'C3659981', name: 'pulmonary valve insufficiency' },
            { cui: 'C3659980', name: 'pulmonary valve disorders' },
            { cui: 'C3659968', name: 'pulmonary edema' },
            { cui: 'C3659964', name: 'pulmonary artery stenosis' },
            { cui: 'C3659957', name: 'pulmonary arterial hypertension' },
            { cui: 'C3659882', name: 'prinzmetals angina' },
            { cui: 'C3659873', name: 'preventative cardiology' },
            { cui: 'C3659861', name: 'premature ventricular contractions' },
            { cui: 'C3659857', name: 'premature beats' },
            { cui: 'C3659846', name: 'postural tachycardia syndrome' },
            { cui: 'C3659641', name: 'pericarditis' },
            { cui: 'C3659567', name: 'patent foramen ovale' },
            { cui: 'C3659566', name: 'patent ductus arteriosus' },
            {
              cui: 'C3659544',
              name: 'partial anomalous pulmonary venous connection (papvc)',
            },
            { cui: 'C3659542', name: 'paroxysmal ventricular tachycardia' },
            { cui: 'C3659541', name: 'paroxysmal tachycardia' },
            {
              cui: 'C3659540',
              name: 'paroxysmal supraventricular tachycardia',
            },
            { cui: 'C3659479', name: 'palpitations' },
            { cui: 'C3659421', name: 'orthostatic hypotension' },
            { cui: 'C3659153', name: 'myocarditis' },
            { cui: 'C3659151', name: 'myocardial infarction (mi)' },
            { cui: 'C3659022', name: 'mitral valve stenosis' },
            { cui: 'C3659019', name: 'mitral valve prolapse' },
            { cui: 'C3659017', name: 'mitral valve disease' },
            { cui: 'C3659016', name: 'mitral regurgitation' },
            { cui: 'C3658858', name: 'maternal hypertension' },
            { cui: 'C3658843', name: 'marfan syndrome' },
            { cui: 'C3658797', name: 'malignant hypertension' },
            { cui: 'C3658586', name: 'left bundle branch block' },
            { cui: 'C3658565', name: 'laser atherectomy' },
            { cui: 'C3658378', name: 'ischemic cardiomyopathy' },
            { cui: 'C3658358', name: 'intravascular ultrasound' },
            { cui: 'C3658326', name: 'intra aortic balloon pump' },
            { cui: 'C3658272', name: 'infiltrative disorders of the heart' },
            { cui: 'C3658242', name: 'implantable defibrillator' },
            {
              cui: 'C3658215',
              name: 'idiopathic hypertrophic subaortic stenosis',
            },
            { cui: 'C3658165', name: 'hypertrophic cardiomyopathy' },
            { cui: 'C3658158', name: 'hypertension' },
            { cui: 'C3657969', name: 'heart racing' },
            { cui: 'C3657968', name: 'heart problems' },
            { cui: 'C3657967', name: 'heart murmur' },
            { cui: 'C3657669', name: 'first degree heart block' },
            { cui: 'C3657612', name: 'family history of heart disease' },
            { cui: 'C3657597', name: 'familial arrhythmias' },
            { cui: 'C3657307', name: 'echocardiography' },
            { cui: 'C3657284', name: 'dyspnea on exertion' },
            { cui: 'C3657151', name: 'diabetic cardiomyopathy' },
            { cui: 'C3656923', name: 'coronary calcium scoring' },
            { cui: 'C3656921', name: 'coronary artery disease' },
            { cui: 'C3656919', name: 'anomalies of the coronary artery' },
            { cui: 'C3656844', name: 'congestive heart failure (chf)' },
            { cui: 'C3656765', name: 'conduction defects' },
            { cui: 'C3656673', name: 'chronic total occlusion' },
            { cui: 'C3656633', name: 'cholesterol disorders' },
            { cui: 'C3656603', name: 'chest pain' },
            { cui: 'C3656479', name: 'cardiomyopathy' },
            { cui: 'C3656478', name: 'cardiomegaly' },
            { cui: 'C3656477', name: 'cardiac sarcoma' },
            { cui: 'C3656475', name: 'cardiac risk assessment' },
            { cui: 'C3656473', name: 'cardiac mri' },
            { cui: 'C3656470', name: 'cardiac genetics' },
            { cui: 'C3656465', name: 'cardiac conduction defects' },
            { cui: 'C3656463', name: 'cardiac arrhythmias' },
            { cui: 'C3656462', name: 'cardiac angina' },
            { cui: 'C3656319', name: 'bradycardia' },
            { cui: 'C3656205', name: 'bicuspid aortic valve' },
            { cui: 'C3656132', name: 'bacterial endocarditis' },
            { cui: 'C3656101', name: 'autoimmune pericarditis' },
            { cui: 'C3656080', name: 'atrioventricular reentrant tachycardia' },
            {
              cui: 'C3656079',
              name: 'atrioventricular nodal reentrant tachycardia',
            },
            { cui: 'C3656078', name: 'atrioventricular block' },
            { cui: 'C3656077', name: 'atrial tachycardia' },
            { cui: 'C3656075', name: 'atrial flutter' },
            { cui: 'C3656073', name: 'atrial fibrillation' },
            { cui: 'C3656068', name: 'atherosclerosis of the aorta' },
            { cui: 'C3656066', name: 'atherosclerosis' },
            { cui: 'C3655970', name: 'aortic valve disease' },
            { cui: 'C3655969', name: 'aortic diseases' },
            { cui: 'C3655967', name: 'aortic coarctation' },
            { cui: 'C3655957', name: 'anticoagulation' },
            { cui: 'C3655845', name: 'primary systemic amyloidosis' },
            { cui: 'C3655844', name: 'amyloidosis' },
          ],
        },
        clinical_trial_text: null,
        clinical_trials: [],
        contact_info: null,
        contacts: [
          {
            contact_type: 'phone',
            extension: null,
            subtype: null,
            value: null,
          },
          { contact_type: 'fax', extension: null, subtype: null, value: null },
          {
            contact_type: 'clinical_contact_info',
            extension: null,
            subtype: null,
            value: null,
          },
        ],
        date_of_birth: null,
        dea_number: 'MR7852999',
        default_timezone: 'UTC',
        degrees: [{ name: 'CRNP', source: 'CRNP', source_url: null }],
        department_name: null,
        departments: ['Providers'],
        direct_book_capable: false,
        direct_book_pmac: false,
        direct_book_pmc: true,
        division: null,
        ehr_platform: null,
        entity_type: null,
        external_id: 'BB79BAD9-CD1B-4FBA-A1AE-42F3E445CDAE',
        external_links: null,
        external_systems: [
          {
            provider_id: '1061466',
            source_system: 'epic',
            system_type: 'epic',
          },
        ],
        gender: 'Female',
        google_category: null,
        graduate_education: [],
        grants: [],
        gx: { max_age: 43800, min_age: 7665 },
        has_video_url: false,
        id: 3844106,
        image_object: null,
        image_url:
          'https://cdn-images.kyruus.com/providermatch/ahn/photos/200/ravotti-angela-1528638848.jpg',
        institutes: null,
        insurance_accepted: [],
        internal_notes: null,
        is_primary_care: true,
        is_specialty_care: true,
        ksched_appointment_request_email: null,
        language_disclaimer: null,
        languages: ['English'],
        leadership_title: null,
        legal: [],
        lgbtq_inclusive: false,
        license: [],
        listings_syndication: null,
        logo: 'Display Logo',
        manually_created: null,
        marketing_campaign_name: null,
        media_mentions: [],
        medical_license: 'SP023923',
        multi_resource_scheduling: null,
        name: {
          first: 'Angela',
          first_last: 'Angela Ravotti',
          full: 'Angela M Ravotti',
          last: 'Ravotti',
          middle: 'M',
          preferred: 'Angela M',
        },
        name_pronunciation: null,
        near_term_availability: null,
        network_affiliations: [
          { name: 'Allegheny General Hospital', rank: null, type: 'Hospital' },
          { name: 'Grove City Hospital', rank: null, type: 'Hospital' },
          { name: 'Wexford Hospital', rank: null, type: 'Hospital' },
        ],
        networks: ['Customer Network'],
        new_pt_online_booking: false,
        night_weekend_availability: false,
        notes: null,
        npi: '1528638848',
        office_hours_text: null,
        open_scheduling: false,
        personal_interests: null,
        philosophy_of_care: null,
        pmc_db_only_provider: null,
        pmc_db_only_provider_flag: false,
        practice_groups: [
          {
            has_info: false,
            id: 85217,
            is_primary_care: false,
            name: 'Providers',
          },
        ],
        professional_memberships: null,
        professional_statement: null,
        pronouns: null,
        provider_business_description: null,
        provider_email: null,
        provider_is_employed: true,
        provider_is_location: false,
        provider_title: null,
        provider_type: 'Nurse Practitioner (NP)',
        publication_text: null,
        publication_url: null,
        publications: [],
        publish_to_brandify: null,
        rating: {},
        ratings: {},
        region: null,
        request_appointment_url: null,
        research_interest: null,
        reviews: null,
        sched_interval_duration_days: null,
        sched_interval_same_day_minute_delay: null,
        sched_interval_start_days: null,
        scheduling_notes: null,
        section: null,
        service_line: null,
        show_cta_modal: 'Yes',
        show_in_pmac: 'Yes',
        show_in_pmc: 'Yes',
        sort_field: '1',
        sort_preferences: {},
        source_specialty: 'Family Medicine',
        special_program: null,
        specialties: [{ eui: 'E0000008', name: 'Cardiology' }],
        status_transitions: null,
        suffix: null,
        surgical_status: null,
        syndicate_to_google: false,
        system_id: null,
        telehealth: false,
        telehealth_badge: false,
        training: [
          {
            city: null,
            country: null,
            degree: null,
            field_of_study: null,
            graduation_date: null,
            graduation_year: '2021',
            name: 'Carlow University',
            rank: null,
            state: null,
            street1: null,
            street2: null,
            type: 'Education',
            zip: null,
          },
        ],
        urgent_care_location: false,
        video_url: null,
        video_visit: false,
        virtual_care: false,
        virtual_care_url: null,
        walkins_welcome: false,
        web_phone_number: null,
        xp_provider_group: null,
        years_in_practice: null,
      },
      provider_id: 3844106,
      sort: [{ relevance: 13.0 }, { shuffle: 77895930.0 }],
    },
    {
      availability: {
        facets: null,
        meta: {
          availability_density_best: null,
          max_date_available: null,
          min_date_available: null,
          slot_count: 0,
          tz_max_date_available: null,
          tz_min_date_available: null,
        },
        slots: [],
      },
      customer: 'ahn',
      locations: [],
      match: {
        highlight: ['<em>Cardiology</em>'],
        score: 13,
        type: 'Specialty Exact Match',
      },
      provider: {
        about_the_provider: null,
        academic_department_name: null,
        academic_title: null,
        accepting_new_patients: true,
        access_program_status: null,
        additional_services: null,
        additional_visit_types: null,
        age_groups_seen: ['Adult 21-65', 'Older Adult >65'],
        aha_id: null,
        always_available_provider: false,
        appointment_ehr_purposes: [],
        awards: null,
        board_certifications: [
          {
            board_name: 'American Board of Internal Medicine',
            board_specialty: 'BC0000055',
            certification_type: 'board',
            certifying_board: 'BC0000007',
            rank: 1,
            specialty_name: 'Cardiovascular Disease',
            year_certified: 1987,
          },
        ],
        book_online_override_url: null,
        clinic_location_url: null,
        clinical_contact: null,
        clinical_interests: null,
        clinical_keywords: {
          promoted: [],
          searchable: [
            { cui: 'C3680038', name: 'cardiogenic syncope' },
            { cui: 'C3679884', name: 'familial hypertension' },
            {
              cui: 'C3679866',
              name: 'myocardial infarction with non-obstructive coronary artery disease',
            },
            { cui: 'C3675846', name: 'chest pain with exercise' },
            { cui: 'C3675086', name: 'arrhythmia' },
            { cui: 'C3673288', name: 'echocardiogram' },
            { cui: 'C3673286', name: 'familial amyloidosis' },
            { cui: 'C3673279', name: 'familial cardiomyopathy' },
            { cui: 'C3672710', name: 'heart failure screening' },
            { cui: 'C3672616', name: 'microvascular dysfunction' },
            { cui: 'C3671643', name: 'inflammatory heart diseases' },
            { cui: 'C3671321', name: 'cardiac arrest' },
            {
              cui: 'C3671144',
              name: 'anomalous left coronary artery from the pulmonary artery (alcapa)',
            },
            { cui: 'C3671143', name: 'anomalous coronary artery (aca) repair' },
            { cui: 'C3670717', name: 'arteriosclerosis' },
            { cui: 'C3670342', name: 'multi system inflammatory syndrome' },
            { cui: 'C3670163', name: 'exercise induced arrhythmia' },
            { cui: 'C3670161', name: 'inappropriate sinus tachycardia' },
            { cui: 'C3670159', name: 'exercise induced syncope' },
            { cui: 'C3669688', name: 'variant angina' },
            { cui: 'C3669687', name: 'vasospastic angina' },
            { cui: 'C3669635', name: 'left ventricular dysfunction' },
            { cui: 'C3669491', name: 'heart valve disease' },
            { cui: 'C3669489', name: 'coronary artery calcification' },
            { cui: 'C3669485', name: 'cardiopulmonary disease' },
            {
              cui: 'C3667902',
              name: 'sports related cardiovascular assessment',
            },
            { cui: 'C3667658', name: 'valvular heart disease' },
            { cui: 'C3666968', name: 'left ventricular hypertrophy' },
            {
              cui: 'C3666782',
              name: 'arrhythmogenic right ventricular cardiomyopathy',
            },
            { cui: 'C3666443', name: 'heart disease in women' },
            { cui: 'C3666436', name: 'tachycardia bradycardia syndrome' },
            { cui: 'C3666374', name: 'single ventricle defect' },
            { cui: 'C3666371', name: 'fibromuscular dysplasia' },
            { cui: 'C3666368', name: 'aortic valve sclerosis' },
            { cui: 'C3666282', name: 'left heart failure' },
            { cui: 'C3666222', name: 'cardiac insufficiency' },
            { cui: 'C3666190', name: 'abnormal coronary angiography' },
            {
              cui: 'C3666122',
              name: 'transposition of the great arteries (tga)',
            },
            { cui: 'C3665557', name: 'prosthetic heart valves' },
            { cui: 'C3665458', name: 'heart disease' },
            { cui: 'C3665328', name: 'aortic stenosis' },
            { cui: 'C3665320', name: 'edema' },
            { cui: 'C3665204', name: 'electrocardiogram (ekg)' },
            { cui: 'C3663857', name: 'heart health' },
            { cui: 'C3663837', name: 'cardiovascular care' },
            { cui: 'C3663709', name: 'peripheral edema' },
            { cui: 'C3663107', name: 'exercise intolerance' },
            { cui: 'C3663039', name: 'pre excitation syndrome' },
            { cui: 'C3663037', name: 'premature atrial contraction' },
            { cui: 'C3662984', name: 'atrioventricular septal defect' },
            { cui: 'C3662828', name: 'cardiorenal syndrome' },
            { cui: 'C3662822', name: 'hypertensive urgency' },
            { cui: 'C3662821', name: 'uncontrolled hypertension' },
            { cui: 'C3662626', name: 'pericardial effusion' },
            { cui: 'C3662574', name: 'valvular endocarditis' },
            { cui: 'C3662573', name: 'acute bacterial endocarditis' },
            { cui: 'C3662519', name: 'abnormal stress test' },
            { cui: 'C3662501', name: 'abnormal electrocardiogram (ekg)' },
            { cui: 'C3662218', name: 'genetic cardiomyopathy' },
            { cui: 'C3662064', name: 'right heart failure' },
            { cui: 'C3662020', name: 'preoperative evaluation' },
            { cui: 'C3661991', name: 'orthopnea' },
            { cui: 'C3661932', name: 'mitral valve regurgitation' },
            { cui: 'C3661746', name: 'cholesterol management' },
            { cui: 'C3661737', name: 'cardiac tumor' },
            { cui: 'C3661490', name: 'atrial septal defect (asd)' },
            { cui: 'C3661407', name: 'cardiovascular disease' },
            { cui: 'C3661356', name: 'wolff-parkinson-white syndrome' },
            { cui: 'C3661263', name: 'vasovagal syncope' },
            { cui: 'C3661240', name: 'valve stenosis' },
            { cui: 'C3661043', name: 'type 2 second degree heart block' },
            { cui: 'C3661041', name: 'type 1 second degree heart block' },
            { cui: 'C3661005', name: 'tricuspid valve disease' },
            { cui: 'C3661001', name: 'tricuspid regurgitation' },
            { cui: 'C3660977', name: 'transposition of great vessels' },
            { cui: 'C3660846', name: 'thrombosis' },
            { cui: 'C3660762', name: 'tachycardia' },
            { cui: 'C3660745', name: 'syncope' },
            { cui: 'C3660724', name: 'supraventricular tachycardia' },
            { cui: 'C3660711', name: 'family history of sudden cardiac death' },
            { cui: 'C3660692', name: 'subaortic stenosis' },
            { cui: 'C3660461', name: 'sick sinus syndrome' },
            { cui: 'C3660384', name: 'secondary hypertension' },
            { cui: 'C3660274', name: 'right ventricular hypertrophy' },
            { cui: 'C3660267', name: 'right bundle branch block' },
            { cui: 'C3660255', name: 'rheumatic mitral stenosis' },
            { cui: 'C3660254', name: 'rheumatic mitral regurgitation' },
            { cui: 'C3660252', name: 'rheumatic heart disease' },
            { cui: 'C3660251', name: 'rheumatic fever' },
            { cui: 'C3660250', name: 'rheumatic diseases of pulmonary valve' },
            { cui: 'C3660249', name: 'rheumatic aortic stenosis' },
            { cui: 'C3660248', name: 'rheumatic aortic regurgitation' },
            { cui: 'C3660130', name: 'renovascular hypertension' },
            { cui: 'C3660081', name: 'refractory angina' },
            { cui: 'C3659982', name: 'pulmonary valve stenosis' },
            { cui: 'C3659981', name: 'pulmonary valve insufficiency' },
            { cui: 'C3659980', name: 'pulmonary valve disorders' },
            { cui: 'C3659968', name: 'pulmonary edema' },
            { cui: 'C3659964', name: 'pulmonary artery stenosis' },
            { cui: 'C3659957', name: 'pulmonary arterial hypertension' },
            { cui: 'C3659882', name: 'prinzmetals angina' },
            { cui: 'C3659873', name: 'preventative cardiology' },
            { cui: 'C3659861', name: 'premature ventricular contractions' },
            { cui: 'C3659857', name: 'premature beats' },
            { cui: 'C3659846', name: 'postural tachycardia syndrome' },
            { cui: 'C3659641', name: 'pericarditis' },
            { cui: 'C3659567', name: 'patent foramen ovale' },
            { cui: 'C3659566', name: 'patent ductus arteriosus' },
            {
              cui: 'C3659544',
              name: 'partial anomalous pulmonary venous connection (papvc)',
            },
            { cui: 'C3659542', name: 'paroxysmal ventricular tachycardia' },
            { cui: 'C3659541', name: 'paroxysmal tachycardia' },
            {
              cui: 'C3659540',
              name: 'paroxysmal supraventricular tachycardia',
            },
            { cui: 'C3659479', name: 'palpitations' },
            { cui: 'C3659421', name: 'orthostatic hypotension' },
            { cui: 'C3659153', name: 'myocarditis' },
            { cui: 'C3659151', name: 'myocardial infarction (mi)' },
            { cui: 'C3659022', name: 'mitral valve stenosis' },
            { cui: 'C3659019', name: 'mitral valve prolapse' },
            { cui: 'C3659017', name: 'mitral valve disease' },
            { cui: 'C3659016', name: 'mitral regurgitation' },
            { cui: 'C3658858', name: 'maternal hypertension' },
            { cui: 'C3658843', name: 'marfan syndrome' },
            { cui: 'C3658797', name: 'malignant hypertension' },
            { cui: 'C3658586', name: 'left bundle branch block' },
            { cui: 'C3658565', name: 'laser atherectomy' },
            { cui: 'C3658378', name: 'ischemic cardiomyopathy' },
            { cui: 'C3658358', name: 'intravascular ultrasound' },
            { cui: 'C3658326', name: 'intra aortic balloon pump' },
            { cui: 'C3658272', name: 'infiltrative disorders of the heart' },
            { cui: 'C3658242', name: 'implantable defibrillator' },
            {
              cui: 'C3658215',
              name: 'idiopathic hypertrophic subaortic stenosis',
            },
            { cui: 'C3658165', name: 'hypertrophic cardiomyopathy' },
            { cui: 'C3658158', name: 'hypertension' },
            { cui: 'C3657969', name: 'heart racing' },
            { cui: 'C3657968', name: 'heart problems' },
            { cui: 'C3657967', name: 'heart murmur' },
            { cui: 'C3657669', name: 'first degree heart block' },
            { cui: 'C3657612', name: 'family history of heart disease' },
            { cui: 'C3657597', name: 'familial arrhythmias' },
            { cui: 'C3657307', name: 'echocardiography' },
            { cui: 'C3657284', name: 'dyspnea on exertion' },
            { cui: 'C3657151', name: 'diabetic cardiomyopathy' },
            { cui: 'C3656923', name: 'coronary calcium scoring' },
            { cui: 'C3656921', name: 'coronary artery disease' },
            { cui: 'C3656919', name: 'anomalies of the coronary artery' },
            { cui: 'C3656844', name: 'congestive heart failure (chf)' },
            { cui: 'C3656765', name: 'conduction defects' },
            { cui: 'C3656673', name: 'chronic total occlusion' },
            { cui: 'C3656633', name: 'cholesterol disorders' },
            { cui: 'C3656603', name: 'chest pain' },
            { cui: 'C3656479', name: 'cardiomyopathy' },
            { cui: 'C3656478', name: 'cardiomegaly' },
            { cui: 'C3656477', name: 'cardiac sarcoma' },
            { cui: 'C3656475', name: 'cardiac risk assessment' },
            { cui: 'C3656473', name: 'cardiac mri' },
            { cui: 'C3656470', name: 'cardiac genetics' },
            { cui: 'C3656465', name: 'cardiac conduction defects' },
            { cui: 'C3656463', name: 'cardiac arrhythmias' },
            { cui: 'C3656462', name: 'cardiac angina' },
            { cui: 'C3656319', name: 'bradycardia' },
            { cui: 'C3656205', name: 'bicuspid aortic valve' },
            { cui: 'C3656132', name: 'bacterial endocarditis' },
            { cui: 'C3656101', name: 'autoimmune pericarditis' },
            { cui: 'C3656080', name: 'atrioventricular reentrant tachycardia' },
            {
              cui: 'C3656079',
              name: 'atrioventricular nodal reentrant tachycardia',
            },
            { cui: 'C3656078', name: 'atrioventricular block' },
            { cui: 'C3656077', name: 'atrial tachycardia' },
            { cui: 'C3656075', name: 'atrial flutter' },
            { cui: 'C3656073', name: 'atrial fibrillation' },
            { cui: 'C3656068', name: 'atherosclerosis of the aorta' },
            { cui: 'C3656066', name: 'atherosclerosis' },
            { cui: 'C3655970', name: 'aortic valve disease' },
            { cui: 'C3655969', name: 'aortic diseases' },
            { cui: 'C3655967', name: 'aortic coarctation' },
            { cui: 'C3655957', name: 'anticoagulation' },
            { cui: 'C3655845', name: 'primary systemic amyloidosis' },
            { cui: 'C3655844', name: 'amyloidosis' },
          ],
        },
        clinical_trial_text: null,
        clinical_trials: [],
        contact_info: null,
        contacts: [
          {
            contact_type: 'phone',
            extension: null,
            subtype: null,
            value: null,
          },
          { contact_type: 'fax', extension: null, subtype: null, value: null },
          {
            contact_type: 'clinical_contact_info',
            extension: null,
            subtype: null,
            value: null,
          },
        ],
        date_of_birth: null,
        dea_number: 'AR2132758',
        default_timezone: 'UTC',
        degrees: [{ name: 'MD', source: 'MD', source_url: null }],
        department_name: null,
        departments: ['Providers'],
        direct_book_capable: false,
        direct_book_pmac: false,
        direct_book_pmc: false,
        division: null,
        ehr_platform: null,
        entity_type: null,
        external_id: '7EFA1235-F931-44FA-8BB3-4A06DB3C794C',
        external_links: null,
        external_systems: null,
        gender: 'Male',
        google_category: null,
        graduate_education: [],
        grants: [],
        gx: { max_age: 43800, min_age: 7665 },
        has_video_url: false,
        id: 3845565,
        image_object: null,
        image_url: null,
        institutes: null,
        insurance_accepted: [],
        internal_notes: null,
        is_primary_care: false,
        is_specialty_care: true,
        ksched_appointment_request_email: null,
        language_disclaimer: null,
        languages: ['English'],
        leadership_title: null,
        legal: [],
        lgbtq_inclusive: false,
        license: [],
        listings_syndication: null,
        logo: null,
        manually_created: null,
        marketing_campaign_name: null,
        media_mentions: [],
        medical_license: 'MD028923E',
        multi_resource_scheduling: null,
        name: {
          first: 'Robert',
          first_last: 'Robert Ruffner',
          full: 'Robert John Ruffner',
          last: 'Ruffner',
          middle: 'John',
          preferred: 'Robert John',
        },
        name_pronunciation: null,
        near_term_availability: null,
        network_affiliations: [{ name: 'West Penn Hospital', rank: null, type: 'Hospital' }],
        networks: ['Affiliated'],
        new_pt_online_booking: false,
        night_weekend_availability: false,
        notes: null,
        npi: '1871531632',
        office_hours_text: null,
        open_scheduling: false,
        personal_interests: null,
        philosophy_of_care: null,
        pmc_db_only_provider: null,
        pmc_db_only_provider_flag: false,
        practice_groups: [
          {
            has_info: false,
            id: 85217,
            is_primary_care: false,
            name: 'Providers',
          },
        ],
        professional_memberships: null,
        professional_statement: null,
        pronouns: null,
        provider_business_description: null,
        provider_email: null,
        provider_is_employed: false,
        provider_is_location: false,
        provider_title: null,
        provider_type: 'Physician',
        publication_text: null,
        publication_url: null,
        publications: [],
        publish_to_brandify: null,
        rating: {},
        ratings: {},
        region: null,
        request_appointment_url: null,
        research_interest: null,
        reviews: null,
        sched_interval_duration_days: null,
        sched_interval_same_day_minute_delay: null,
        sched_interval_start_days: null,
        scheduling_notes: null,
        section: null,
        service_line: null,
        show_cta_modal: 'Yes',
        show_in_pmac: 'No',
        show_in_pmc: 'Yes',
        sort_field: '2',
        sort_preferences: {},
        source_specialty: 'Cardiovascular Disease',
        special_program: null,
        specialties: [{ eui: 'E0000008', name: 'Cardiology' }],
        status_transitions: null,
        suffix: null,
        surgical_status: null,
        syndicate_to_google: false,
        system_id: null,
        telehealth: false,
        telehealth_badge: false,
        training: [
          {
            city: null,
            country: null,
            degree: null,
            field_of_study: null,
            graduation_date: null,
            graduation_year: '1981',
            name: 'Johns Hopkins University School of Medicine',
            rank: null,
            state: null,
            street1: null,
            street2: null,
            type: 'Education',
            zip: null,
          },
          {
            city: null,
            country: null,
            degree: null,
            field_of_study: 'Internal Medicine',
            graduation_date: null,
            graduation_year: '1984',
            name: 'UPMC-University of Pittsburgh Medical Center',
            rank: null,
            state: null,
            street1: null,
            street2: null,
            type: 'Residency',
            zip: null,
          },
          {
            city: null,
            country: null,
            degree: null,
            field_of_study: 'Internal Medicine',
            graduation_date: null,
            graduation_year: '1982',
            name: 'UPMC-University of Pittsburgh Medical Center',
            rank: null,
            state: null,
            street1: null,
            street2: null,
            type: 'Internship',
            zip: null,
          },
          {
            city: null,
            country: null,
            degree: null,
            field_of_study: 'Other, Not Listed',
            graduation_date: null,
            graduation_year: '1985',
            name: 'UPMC Presbyterian',
            rank: null,
            state: null,
            street1: null,
            street2: null,
            type: 'Residency',
            zip: null,
          },
          {
            city: null,
            country: null,
            degree: null,
            field_of_study: 'Cardiovascular Disease (Cardiology)',
            graduation_date: null,
            graduation_year: '1987',
            name: 'UPMC-University of Pittsburgh Medical Center',
            rank: null,
            state: null,
            street1: null,
            street2: null,
            type: 'Fellowship',
            zip: null,
          },
        ],
        urgent_care_location: false,
        video_url: null,
        video_visit: false,
        virtual_care: false,
        virtual_care_url: null,
        walkins_welcome: false,
        web_phone_number: null,
        xp_provider_group: null,
        years_in_practice: null,
      },
      provider_id: 3845565,
      sort: [{ relevance: 13.0 }, { shuffle: 86879510.0 }],
    },
    {
      availability: {
        facets: null,
        meta: {
          availability_density_best: null,
          max_date_available: null,
          min_date_available: null,
          slot_count: 0,
          tz_max_date_available: null,
          tz_min_date_available: null,
        },
        slots: [],
      },
      customer: 'ahn',
      locations: [],
      match: {
        highlight: ['<em>Cardiology</em>'],
        score: 13,
        type: 'Specialty Exact Match',
      },
      provider: {
        about_the_provider: null,
        academic_department_name: null,
        academic_title: null,
        accepting_new_patients: true,
        access_program_status: null,
        additional_services: null,
        additional_visit_types: null,
        age_groups_seen: ['Adult 21-65', 'Older Adult >65'],
        aha_id: null,
        always_available_provider: false,
        appointment_ehr_purposes: [],
        awards: null,
        board_certifications: [],
        book_online_override_url: null,
        clinic_location_url: null,
        clinical_contact: null,
        clinical_interests: null,
        clinical_keywords: {
          promoted: [],
          searchable: [
            { cui: 'C3680038', name: 'cardiogenic syncope' },
            { cui: 'C3679884', name: 'familial hypertension' },
            {
              cui: 'C3679866',
              name: 'myocardial infarction with non-obstructive coronary artery disease',
            },
            { cui: 'C3675846', name: 'chest pain with exercise' },
            { cui: 'C3675086', name: 'arrhythmia' },
            { cui: 'C3673288', name: 'echocardiogram' },
            { cui: 'C3673286', name: 'familial amyloidosis' },
            { cui: 'C3673279', name: 'familial cardiomyopathy' },
            { cui: 'C3672710', name: 'heart failure screening' },
            { cui: 'C3672616', name: 'microvascular dysfunction' },
            { cui: 'C3671643', name: 'inflammatory heart diseases' },
            { cui: 'C3671321', name: 'cardiac arrest' },
            {
              cui: 'C3671144',
              name: 'anomalous left coronary artery from the pulmonary artery (alcapa)',
            },
            { cui: 'C3671143', name: 'anomalous coronary artery (aca) repair' },
            { cui: 'C3670717', name: 'arteriosclerosis' },
            { cui: 'C3670342', name: 'multi system inflammatory syndrome' },
            { cui: 'C3670163', name: 'exercise induced arrhythmia' },
            { cui: 'C3670161', name: 'inappropriate sinus tachycardia' },
            { cui: 'C3670159', name: 'exercise induced syncope' },
            { cui: 'C3669688', name: 'variant angina' },
            { cui: 'C3669687', name: 'vasospastic angina' },
            { cui: 'C3669635', name: 'left ventricular dysfunction' },
            { cui: 'C3669491', name: 'heart valve disease' },
            { cui: 'C3669489', name: 'coronary artery calcification' },
            { cui: 'C3669485', name: 'cardiopulmonary disease' },
            {
              cui: 'C3667902',
              name: 'sports related cardiovascular assessment',
            },
            { cui: 'C3667658', name: 'valvular heart disease' },
            { cui: 'C3666968', name: 'left ventricular hypertrophy' },
            {
              cui: 'C3666782',
              name: 'arrhythmogenic right ventricular cardiomyopathy',
            },
            { cui: 'C3666443', name: 'heart disease in women' },
            { cui: 'C3666436', name: 'tachycardia bradycardia syndrome' },
            { cui: 'C3666374', name: 'single ventricle defect' },
            { cui: 'C3666371', name: 'fibromuscular dysplasia' },
            { cui: 'C3666368', name: 'aortic valve sclerosis' },
            { cui: 'C3666282', name: 'left heart failure' },
            { cui: 'C3666222', name: 'cardiac insufficiency' },
            { cui: 'C3666190', name: 'abnormal coronary angiography' },
            {
              cui: 'C3666122',
              name: 'transposition of the great arteries (tga)',
            },
            { cui: 'C3665557', name: 'prosthetic heart valves' },
            { cui: 'C3665458', name: 'heart disease' },
            { cui: 'C3665328', name: 'aortic stenosis' },
            { cui: 'C3665320', name: 'edema' },
            { cui: 'C3665204', name: 'electrocardiogram (ekg)' },
            { cui: 'C3663857', name: 'heart health' },
            { cui: 'C3663837', name: 'cardiovascular care' },
            { cui: 'C3663709', name: 'peripheral edema' },
            { cui: 'C3663107', name: 'exercise intolerance' },
            { cui: 'C3663039', name: 'pre excitation syndrome' },
            { cui: 'C3663037', name: 'premature atrial contraction' },
            { cui: 'C3662984', name: 'atrioventricular septal defect' },
            { cui: 'C3662828', name: 'cardiorenal syndrome' },
            { cui: 'C3662822', name: 'hypertensive urgency' },
            { cui: 'C3662821', name: 'uncontrolled hypertension' },
            { cui: 'C3662626', name: 'pericardial effusion' },
            { cui: 'C3662574', name: 'valvular endocarditis' },
            { cui: 'C3662573', name: 'acute bacterial endocarditis' },
            { cui: 'C3662519', name: 'abnormal stress test' },
            { cui: 'C3662501', name: 'abnormal electrocardiogram (ekg)' },
            { cui: 'C3662218', name: 'genetic cardiomyopathy' },
            { cui: 'C3662064', name: 'right heart failure' },
            { cui: 'C3662020', name: 'preoperative evaluation' },
            { cui: 'C3661991', name: 'orthopnea' },
            { cui: 'C3661932', name: 'mitral valve regurgitation' },
            { cui: 'C3661746', name: 'cholesterol management' },
            { cui: 'C3661737', name: 'cardiac tumor' },
            { cui: 'C3661490', name: 'atrial septal defect (asd)' },
            { cui: 'C3661407', name: 'cardiovascular disease' },
            { cui: 'C3661356', name: 'wolff-parkinson-white syndrome' },
            { cui: 'C3661263', name: 'vasovagal syncope' },
            { cui: 'C3661240', name: 'valve stenosis' },
            { cui: 'C3661043', name: 'type 2 second degree heart block' },
            { cui: 'C3661041', name: 'type 1 second degree heart block' },
            { cui: 'C3661005', name: 'tricuspid valve disease' },
            { cui: 'C3661001', name: 'tricuspid regurgitation' },
            { cui: 'C3660977', name: 'transposition of great vessels' },
            { cui: 'C3660846', name: 'thrombosis' },
            { cui: 'C3660762', name: 'tachycardia' },
            { cui: 'C3660745', name: 'syncope' },
            { cui: 'C3660724', name: 'supraventricular tachycardia' },
            { cui: 'C3660711', name: 'family history of sudden cardiac death' },
            { cui: 'C3660692', name: 'subaortic stenosis' },
            { cui: 'C3660461', name: 'sick sinus syndrome' },
            { cui: 'C3660384', name: 'secondary hypertension' },
            { cui: 'C3660274', name: 'right ventricular hypertrophy' },
            { cui: 'C3660267', name: 'right bundle branch block' },
            { cui: 'C3660255', name: 'rheumatic mitral stenosis' },
            { cui: 'C3660254', name: 'rheumatic mitral regurgitation' },
            { cui: 'C3660252', name: 'rheumatic heart disease' },
            { cui: 'C3660251', name: 'rheumatic fever' },
            { cui: 'C3660250', name: 'rheumatic diseases of pulmonary valve' },
            { cui: 'C3660249', name: 'rheumatic aortic stenosis' },
            { cui: 'C3660248', name: 'rheumatic aortic regurgitation' },
            { cui: 'C3660130', name: 'renovascular hypertension' },
            { cui: 'C3660081', name: 'refractory angina' },
            { cui: 'C3659982', name: 'pulmonary valve stenosis' },
            { cui: 'C3659981', name: 'pulmonary valve insufficiency' },
            { cui: 'C3659980', name: 'pulmonary valve disorders' },
            { cui: 'C3659968', name: 'pulmonary edema' },
            { cui: 'C3659964', name: 'pulmonary artery stenosis' },
            { cui: 'C3659957', name: 'pulmonary arterial hypertension' },
            { cui: 'C3659882', name: 'prinzmetals angina' },
            { cui: 'C3659873', name: 'preventative cardiology' },
            { cui: 'C3659861', name: 'premature ventricular contractions' },
            { cui: 'C3659857', name: 'premature beats' },
            { cui: 'C3659846', name: 'postural tachycardia syndrome' },
            { cui: 'C3659641', name: 'pericarditis' },
            { cui: 'C3659567', name: 'patent foramen ovale' },
            { cui: 'C3659566', name: 'patent ductus arteriosus' },
            {
              cui: 'C3659544',
              name: 'partial anomalous pulmonary venous connection (papvc)',
            },
            { cui: 'C3659542', name: 'paroxysmal ventricular tachycardia' },
            { cui: 'C3659541', name: 'paroxysmal tachycardia' },
            {
              cui: 'C3659540',
              name: 'paroxysmal supraventricular tachycardia',
            },
            { cui: 'C3659479', name: 'palpitations' },
            { cui: 'C3659421', name: 'orthostatic hypotension' },
            { cui: 'C3659153', name: 'myocarditis' },
            { cui: 'C3659151', name: 'myocardial infarction (mi)' },
            { cui: 'C3659022', name: 'mitral valve stenosis' },
            { cui: 'C3659019', name: 'mitral valve prolapse' },
            { cui: 'C3659017', name: 'mitral valve disease' },
            { cui: 'C3659016', name: 'mitral regurgitation' },
            { cui: 'C3658858', name: 'maternal hypertension' },
            { cui: 'C3658843', name: 'marfan syndrome' },
            { cui: 'C3658797', name: 'malignant hypertension' },
            { cui: 'C3658586', name: 'left bundle branch block' },
            { cui: 'C3658565', name: 'laser atherectomy' },
            { cui: 'C3658378', name: 'ischemic cardiomyopathy' },
            { cui: 'C3658358', name: 'intravascular ultrasound' },
            { cui: 'C3658326', name: 'intra aortic balloon pump' },
            { cui: 'C3658272', name: 'infiltrative disorders of the heart' },
            { cui: 'C3658242', name: 'implantable defibrillator' },
            {
              cui: 'C3658215',
              name: 'idiopathic hypertrophic subaortic stenosis',
            },
            { cui: 'C3658165', name: 'hypertrophic cardiomyopathy' },
            { cui: 'C3658158', name: 'hypertension' },
            { cui: 'C3657969', name: 'heart racing' },
            { cui: 'C3657968', name: 'heart problems' },
            { cui: 'C3657967', name: 'heart murmur' },
            { cui: 'C3657669', name: 'first degree heart block' },
            { cui: 'C3657612', name: 'family history of heart disease' },
            { cui: 'C3657597', name: 'familial arrhythmias' },
            { cui: 'C3657307', name: 'echocardiography' },
            { cui: 'C3657284', name: 'dyspnea on exertion' },
            { cui: 'C3657151', name: 'diabetic cardiomyopathy' },
            { cui: 'C3656923', name: 'coronary calcium scoring' },
            { cui: 'C3656921', name: 'coronary artery disease' },
            { cui: 'C3656919', name: 'anomalies of the coronary artery' },
            { cui: 'C3656844', name: 'congestive heart failure (chf)' },
            { cui: 'C3656765', name: 'conduction defects' },
            { cui: 'C3656673', name: 'chronic total occlusion' },
            { cui: 'C3656633', name: 'cholesterol disorders' },
            { cui: 'C3656603', name: 'chest pain' },
            { cui: 'C3656479', name: 'cardiomyopathy' },
            { cui: 'C3656478', name: 'cardiomegaly' },
            { cui: 'C3656477', name: 'cardiac sarcoma' },
            { cui: 'C3656475', name: 'cardiac risk assessment' },
            { cui: 'C3656473', name: 'cardiac mri' },
            { cui: 'C3656470', name: 'cardiac genetics' },
            { cui: 'C3656465', name: 'cardiac conduction defects' },
            { cui: 'C3656463', name: 'cardiac arrhythmias' },
            { cui: 'C3656462', name: 'cardiac angina' },
            { cui: 'C3656319', name: 'bradycardia' },
            { cui: 'C3656205', name: 'bicuspid aortic valve' },
            { cui: 'C3656132', name: 'bacterial endocarditis' },
            { cui: 'C3656101', name: 'autoimmune pericarditis' },
            { cui: 'C3656080', name: 'atrioventricular reentrant tachycardia' },
            {
              cui: 'C3656079',
              name: 'atrioventricular nodal reentrant tachycardia',
            },
            { cui: 'C3656078', name: 'atrioventricular block' },
            { cui: 'C3656077', name: 'atrial tachycardia' },
            { cui: 'C3656075', name: 'atrial flutter' },
            { cui: 'C3656073', name: 'atrial fibrillation' },
            { cui: 'C3656068', name: 'atherosclerosis of the aorta' },
            { cui: 'C3656066', name: 'atherosclerosis' },
            { cui: 'C3655970', name: 'aortic valve disease' },
            { cui: 'C3655969', name: 'aortic diseases' },
            { cui: 'C3655967', name: 'aortic coarctation' },
            { cui: 'C3655957', name: 'anticoagulation' },
            { cui: 'C3655845', name: 'primary systemic amyloidosis' },
            { cui: 'C3655844', name: 'amyloidosis' },
          ],
        },
        clinical_trial_text: null,
        clinical_trials: [],
        contact_info: null,
        contacts: [
          {
            contact_type: 'phone',
            extension: null,
            subtype: null,
            value: null,
          },
          { contact_type: 'fax', extension: null, subtype: null, value: null },
          {
            contact_type: 'clinical_contact_info',
            extension: null,
            subtype: null,
            value: null,
          },
        ],
        date_of_birth: null,
        dea_number: null,
        default_timezone: 'UTC',
        degrees: [{ name: 'PA-C', source: 'PA-C', source_url: null }],
        department_name: null,
        departments: ['Providers'],
        direct_book_capable: false,
        direct_book_pmac: false,
        direct_book_pmc: false,
        division: null,
        ehr_platform: null,
        entity_type: null,
        external_id: 'E64A2087-EFA6-44B2-8187-A0A7513EC80A',
        external_links: null,
        external_systems: null,
        gender: 'Male',
        google_category: null,
        graduate_education: [],
        grants: [],
        gx: { max_age: 43800, min_age: 7665 },
        has_video_url: false,
        id: 3847256,
        image_object: null,
        image_url: null,
        institutes: null,
        insurance_accepted: [],
        internal_notes: null,
        is_primary_care: true,
        is_specialty_care: true,
        ksched_appointment_request_email: null,
        language_disclaimer: null,
        languages: ['English'],
        leadership_title: null,
        legal: [],
        lgbtq_inclusive: false,
        license: [],
        listings_syndication: null,
        logo: null,
        manually_created: null,
        marketing_campaign_name: null,
        media_mentions: [],
        medical_license: 'MA058591',
        multi_resource_scheduling: null,
        name: {
          first: 'Matthew',
          first_last: 'Matthew Pontoriero',
          full: 'Matthew John Pontoriero',
          last: 'Pontoriero',
          middle: 'John',
          preferred: 'Matthew John',
        },
        name_pronunciation: null,
        near_term_availability: null,
        network_affiliations: [{ name: 'Forbes Hospital', rank: null, type: 'Hospital' }],
        networks: ['Affiliated'],
        new_pt_online_booking: false,
        night_weekend_availability: false,
        notes: null,
        npi: '1013466028',
        office_hours_text: null,
        open_scheduling: false,
        personal_interests: null,
        philosophy_of_care: null,
        pmc_db_only_provider: null,
        pmc_db_only_provider_flag: false,
        practice_groups: [
          {
            has_info: false,
            id: 85217,
            is_primary_care: false,
            name: 'Providers',
          },
        ],
        professional_memberships: null,
        professional_statement: null,
        pronouns: null,
        provider_business_description: null,
        provider_email: null,
        provider_is_employed: false,
        provider_is_location: false,
        provider_title: null,
        provider_type: 'Physician Assistant (PA)',
        publication_text: null,
        publication_url: null,
        publications: [],
        publish_to_brandify: null,
        rating: {},
        ratings: {},
        region: null,
        request_appointment_url: null,
        research_interest: null,
        reviews: null,
        sched_interval_duration_days: null,
        sched_interval_same_day_minute_delay: null,
        sched_interval_start_days: null,
        scheduling_notes: null,
        section: null,
        service_line: null,
        show_cta_modal: 'Yes',
        show_in_pmac: 'No',
        show_in_pmc: 'Yes',
        sort_field: '2',
        sort_preferences: {},
        source_specialty: 'Cardiovascular Disease',
        special_program: null,
        specialties: [{ eui: 'E0000008', name: 'Cardiology' }],
        status_transitions: null,
        suffix: null,
        surgical_status: null,
        syndicate_to_google: false,
        system_id: null,
        telehealth: false,
        telehealth_badge: false,
        training: [
          {
            city: null,
            country: null,
            degree: null,
            field_of_study: null,
            graduation_date: null,
            graduation_year: '2016',
            name: 'Chatham University',
            rank: null,
            state: null,
            street1: null,
            street2: null,
            type: 'Education',
            zip: null,
          },
        ],
        urgent_care_location: false,
        video_url: null,
        video_visit: false,
        virtual_care: false,
        virtual_care_url: null,
        walkins_welcome: false,
        web_phone_number: null,
        xp_provider_group: null,
        years_in_practice: null,
      },
      provider_id: 3847256,
      sort: [{ relevance: 13.0 }, { shuffle: 103164301.0 }],
    },
  ],
  facets: [],
  geocoded_location: {},
  global_availability: {},
  interpretation: [{ specialties: ['<em>Cardiology</em>'] }],
  messages: {
    distance_expansion: 'not used',
    nlp_actions: {},
    search_alerts: [],
  },
  suggestions: {},
  warnings: { informational: [], warnings: [] },
};
