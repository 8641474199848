/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  SecurityContext,
  AfterViewChecked,
  Inject,
  PLATFORM_ID,
  AfterViewInit,
} from '@angular/core';
import { ProviderResult } from '../../components/provider-result/provider-result';
import {
  FadSeachStateService,
  FadSearchResult,
  FadSearchService,
  FadSearchRequest,
} from '@dmx-fad-ui/fad/data-access';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { FadSearchPayload } from '../../models/fadSearchPayload/fadSearchPayload';
import {
  FilterService,
  ClearFilterService,
  LocationService,
  UrlMonitorService,
  SimpleUtilsService,
  SeoService,
  WindowSizeService,
} from '@dmx-fad-ui/fad/services';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { MatSidenav } from '@angular/material/sidenav';
import { ProviderPracticeBuilding } from '../../components/provider-result/provider-practice-building/provider-practice-building';
import { SelectedFilters } from '../../components/provider-results-filter/selectedFilters';
import { ViewportScroller } from '@angular/common';
import { isPlatformBrowser } from '@angular/common';
import { DistanceOptionChange } from '../../components/provider-results-filter/distance-filter/distanceOptionChange';
import { ahnTextPath } from '../../components/ahn-text/ahn-text.enum';
import { environment } from '../../../../environments/environment';
import { search_result_mock } from '../../../../assets/mockdata/search_result_mock_kyruus';

@Component({
  selector: 'atlas-search-results-page',
  templateUrl: './search-results-page.component.html',
  styleUrls: ['./search-results-page.component.scss'],
})
export class SearchResultsPageComponent
  implements OnInit, OnDestroy, AfterViewChecked, AfterViewInit
{
  providerResults: ProviderResult[];
  result: FadSearchResult = undefined;
  fadSearchQuery: string = '';
  fadSearchResultCount: number = 0;
  userAdded: string;
  plainSearchTextSub: Subscription;
  isHideResultsBar: boolean = false;
  isRequestPending: boolean = true;
  @ViewChild('drawer') sidenav: MatSidenav;
  startIndex: number;
  currentSearchResultCount: number;
  isLoadMoreActive: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  queryParamObj: any;
  isDesktop: boolean = undefined;

  checkLoadMoreSub: Subscription = new Subscription();
  currentIndexSub: Subscription = new Subscription();
  currentResultsCountSub: Subscription = new Subscription();
  currentSearchOrderSub: Subscription = new Subscription();
  locationProviderFilterSub: Subscription;
  spinnerSub: Subscription = new Subscription();
  windowWidthSub: Subscription = new Subscription();
  currentWindowWidth: number = 0;
  locationProviderSelectionString: string;
  isAhnProvidersSelected: boolean = false;
  isSchedulableByPhoneSelected: boolean = false;
  isVideoBioSelected: boolean = false;
  genderSelected: string;
  languageSelected: string;
  conditionSelected: string;
  locationSelected: string;
  locationDistanceSelected: number;
  isSchedulableOnlineSelected: boolean = false;
  isLGBTQChampSelected: boolean = false;
  totalResultsCount: number;
  sortOrderSub: Subscription;
  sortOrderSelected: string = 'DEFAULT';
  distanceOptionsSelected: DistanceOptionChange = new DistanceOptionChange('', false, '', 0);

  selectedFilters: SelectedFilters = {
    outpatientenabled: false,
    ahn: false,
    video: false,
    languages: '',
    conditions: '',
    gender: 'Any Gender',
    schedule: false,
    lgbtq: false,
    location: '',
    locationdistance: 0,
    sortorder: 'DEFAULT',
    totalResultsCount: 0,
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  urlArray: any;
  urlMonitorSub: Subscription;
  isScrolledToProvider: boolean = false;
  pageType: ahnTextPath;
  isV2Filter: boolean = environment.kyruusFeatures.resultsPage.isV2Filter;
  isV2ProviderCard: boolean = environment.kyruusFeatures.resultsPage.isV2ProviderCard;
  providerResultsSetV2: any[] = [];

  constructor(
    private fadSeachStateService: FadSeachStateService,
    private activatedRoute: ActivatedRoute,
    private fadSearchService: FadSearchService,
    private filterServ: FilterService,
    private locationService: LocationService,
    private clearFilterService: ClearFilterService,
    private urlMonitorServ: UrlMonitorService,
    private utilityServ: SimpleUtilsService,
    private sanitizer: DomSanitizer,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: string,
    private seoService: SeoService,
    private windowSizeServ: WindowSizeService,
    private viewportScroller: ViewportScroller,
    private simpleUtilsService: SimpleUtilsService,
  ) {
    this.fadSeachStateService = fadSeachStateService;
    this.activatedRoute = activatedRoute;
    this.fadSearchService = fadSearchService;
    this.filterServ = filterServ;
    this.locationService = locationService;
    this.clearFilterService = clearFilterService;
    this.urlMonitorServ = urlMonitorServ;
    this.utilityServ = utilityServ;
    this.windowSizeServ = windowSizeServ;
    this.sanitizer = sanitizer;
    this.router = router;
    this.pageType = ahnTextPath.results;
  }

  ngOnInit(): void {
    if (this.isV2ProviderCard === true) {
      this.subToPhoneSchedulableProvSubj();
    } else {
      this.seoService.addMainContent();
      this.subToAhnProvSubj();
      this.subToVideoBioSubj();
      this.subToLanguagesSpokenSubj();
      this.subToConditionOrTreatmentSubj();
      this.subToGenderSubj();
      this.subToOnlineSchedulableProvSubj();
      this.subToLGBTQChampProvSubj();
      this.subToLocationSub();
      this.setSearchIndexSub();
      this.setCurrentResultsCountSub();
      this.setLoadMoreSub();
      this.subToTotalResultsCountSubj();
      this.subToSpinnerSubj();
      this.simpleUtilsService.isShowProviderReviewsTab.next(false);
      this.urlMonitorSub = this.urlMonitorServ.urlMonitorSubj.subscribe((navEvents) => {
        this.urlArray = [];
        navEvents.forEach((item) => {
          if (item) {
            const textUrl = item.url;
            const isHashPresentUrl = textUrl.includes('#');
            if (isHashPresentUrl === false) {
              this.urlArray.push(item);
            }
          }
        });
      });
    }

    this.activatedRoute.queryParamMap.subscribe((params) => {
      this.queryParamObj = { ...params };
      if (this.queryParamObj.params['term']) {
        const q = this.sanitizer.sanitize(SecurityContext.HTML, this.queryParamObj.params['term']);
        this.router.navigate([`/search-results/${q}`]);
      }
      if (this.queryParamObj.params['ahn'] === 'true') {
        this.filterServ.ahnProviderSubj.next(true);
      } else {
        this.filterServ.ahnProviderSubj.next(false);
      }
      if (this.queryParamObj.params['outpatientenabled'] === 'true') {
        this.filterServ.makeAppointmentSubj.next(true);
      } else {
        this.filterServ.makeAppointmentSubj.next(false);
      }
      if (this.queryParamObj.params['video'] === 'true') {
        this.filterServ.videoBioSubj.next(true);
      } else {
        this.filterServ.videoBioSubj.next(false);
      }
      if (this.queryParamObj.params['languages']) {
        this.filterServ.lanaguageFilterSubj.next(
          this.sanitizer.sanitize(SecurityContext.HTML, this.queryParamObj.params['languages']),
        );
      }
      if (this.queryParamObj.params['conditions']) {
        this.filterServ.conditionFilterSubj.next(
          this.sanitizer.sanitize(SecurityContext.HTML, this.queryParamObj.params['conditions']),
        );
      }
      if (this.queryParamObj.params['gender']) {
        this.filterServ.genderFilterSubj.next(
          this.sanitizer.sanitize(SecurityContext.HTML, this.queryParamObj.params['gender']),
        );
      }

      if (this.queryParamObj.params['totalResultsCount'] > 0) {
        this.filterServ.totalResultsCountSubj.next(this.queryParamObj.params['totalResultsCount']);
      }

      if (this.queryParamObj.params['schedule'] === 'true') {
        this.filterServ.onlineSchedulingSubj.next(true);
      } else {
        this.filterServ.onlineSchedulingSubj.next(false);
      }

      if (this.queryParamObj.params['lgbtq'] === 'true') {
        this.filterServ.LGBTQQIP2SAASubj.next(true);
      } else {
        this.filterServ.LGBTQQIP2SAASubj.next(false);
      }
      if (this.queryParamObj.params['location'] && this.queryParamObj.params['locationdistance']) {
        this.filterServ.locationFilterSubj.next([
          this.sanitizer.sanitize(SecurityContext.HTML, this.queryParamObj.params['location']),
          this.sanitizer.sanitize(
            SecurityContext.HTML,
            this.queryParamObj.params['locationdistance'],
          ),
        ]);
      }

      if (
        this.queryParamObj.params['sortorder'] !== undefined &&
        this.queryParamObj.params['sortorder'] !== null
      ) {
        this.selectedFilters.sortorder = this.queryParamObj.params['sortorder'];
        this.fadSeachStateService.currentSearchOrderSubj.next(this.selectedFilters.sortorder);
      } else {
        this.selectedFilters.sortorder = 'DEFAULT';
        this.fadSeachStateService.currentSearchOrderSubj.next(this.selectedFilters.sortorder);
      }

      this.setForNewQuery();
      if (this.isLoadMoreActive === true) {
        const fadSearch: FadSearchRequest = {
          searchQuery: {
            userQuery: this.fadSeachStateService.request.searchQuery.userQuery,
            startIndex: 0,
            requestResultCount: this.totalResultsCount,
            requestLocation: this.fadSeachStateService.request.searchQuery.requestLocation,
            filterDistance: this.fadSeachStateService.request.searchQuery.filterDistance,
            sortOrder: this.selectedFilters.sortorder,
          },
        };
        if (this.isV2ProviderCard === true) {
          this.fadSearchService.searchSubj.next(search_result_mock);
          console.log('Search Mock Data:');
          console.log(search_result_mock[0]._result[0]);
        } else {
          this.fadSearchService.search(fadSearch);
        }
      } else {
        const fadSearch: FadSearchRequest = {
          searchQuery: {
            userQuery: this.fadSeachStateService.request.searchQuery.userQuery,
            startIndex: 0,
            requestResultCount: this.totalResultsCount,
            requestLocation: this.fadSeachStateService.request.searchQuery.requestLocation,
            filterDistance: this.fadSeachStateService.request.searchQuery.filterDistance,
            sortOrder: this.selectedFilters.sortorder,
          },
        };
        if (this.isV2ProviderCard === true) {
          this.fadSearchService.searchSubj.next(search_result_mock);
          console.log('Search Mock Data:');
          console.log(search_result_mock);
        } else {
          this.fadSearchService.search(fadSearch);
        }
      }
    });

    this.setSearchSub();
    this.setPlainTextSub();
    this.setSideNavClose();
    this.urlMonitorServ.isUserUsingSearchResultsSubj.next(true);
  }

  setCurrentResultsCountSub() {
    this.currentResultsCountSub = this.fadSeachStateService.currentVisibleResultCountSubj.subscribe(
      (value) => {
        this.currentSearchResultCount = value;
      },
    );
  }

  setSearchIndexSub() {
    this.currentIndexSub = this.fadSeachStateService.currentIndexSubj.subscribe((value) => {
      this.startIndex = value;
    });
  }

  setLoadMoreSub() {
    this.checkLoadMoreSub = this.fadSeachStateService.checkLoadMoreSubj.subscribe((value) => {
      this.isLoadMoreActive = value;
    });
  }

  setSideNavClose() {
    this.clearFilterService.isSideNavClosed.subscribe((data) => {
      if (data == true) {
        this.closeSideNav();
      }
    });
  }

  setForNewQuery() {
    const urlfetchedSearchTerm: string = decodeURIComponent(
      this.activatedRoute.snapshot.params['search'],
    );

    const fillerQuery = this.utilityServ.buildSimpleSearchRequest(urlfetchedSearchTerm);
    if (this.isAhnProvidersSelected === true) {
      fillerQuery.userQuery += ' AND employed:Employed';
    }
    if (this.isSchedulableByPhoneSelected === true) {
      fillerQuery.userQuery += ' AND outpatientenabled:true';
    }
    if (this.isVideoBioSelected === true) {
      fillerQuery.userQuery += ' AND video:true';
    }
    if (this.languageSelected) {
      fillerQuery.userQuery += ' AND languages:' + this.languageSelected;
    }
    if (this.conditionSelected) {
      fillerQuery.userQuery += ' AND conditions:' + this.conditionSelected;
    }
    if (this.isSchedulableOnlineSelected === true) {
      fillerQuery.userQuery += ' AND schedule:true';
    }
    if (this.isLGBTQChampSelected === true) {
      fillerQuery.userQuery += ' AND lgbtq:true';
    }

    if (this.locationSelected && this.locationDistanceSelected) {
      fillerQuery.filterDistance = this.locationDistanceSelected;
      fillerQuery.requestLocation = this.locationSelected;
      this.locationService.searchAddress = this.locationSelected;
      this.locationService.searchDistance = this.locationDistanceSelected;
      this.filterServ.nearestToMeDisabled.next(false);
    }
    if (this.genderSelected != 'Any Gender') {
      fillerQuery.userQuery += ' AND gender:' + this.genderSelected;
    }
    this.fadSeachStateService.request = { searchQuery: fillerQuery };
    this.fadSeachStateService.currentSearchQuerySubj.next(urlfetchedSearchTerm);
    this.filterServ.plainSearchTextSubj.next(urlfetchedSearchTerm);
  }

  setPlainTextSub() {
    this.plainSearchTextSub = this.filterServ.plainSearchTextSubj.subscribe((text) => {
      this.fadSearchQuery = text;
    });
  }

  setSearchSub() {
    this.fadSearchService.searchSubj.subscribe((result: FadSearchResult | any) => {
      console.log('STUFF IN SUBSCRIPTION:');
      console.log(result);
      this.result = result;
      if (this.isV2ProviderCard === false) {
        this.checkForNewIndexAndReset(this.result);
      }
      if (this.isV2ProviderCard) {
        this.providerResultsSetV2 = result._result;
        console.log('V2 DATA:');
        console.log(this.providerResultsSetV2);
      } else {
        this.providerResults = this.buildProviderList(this.result, this.providerResults);
        if (this.providerResults) {
          this.fadSearchResultCount = this.providerResults.length;
          if (this.providerResults.length > 0) {
            this.isHideResultsBar = false;
          } else {
            this.isHideResultsBar = true;
          }
        }
      }
    });
  }

  closeSideNav() {
    this.sidenav.close();
  }

  parseValue(result: FadSearchPayload, pullValue: string): string {
    const val = result.metadata?.fields.find((field) => {
      return field.name === pullValue;
    }).textValues.values[0];
    return val;
  }

  parseValuesInArray(result: FadSearchPayload, pullValue: string): string[] {
    const arrayVals: string[] = [];
    result.metadata?.fields
      .find((field) => {
        return field.name === pullValue;
      })
      .textValues.values.forEach((val) => {
        arrayVals.push(val);
      });
    return arrayVals;
  }

  buildProviderList(
    results: FadSearchResult,
    currentProviderList: ProviderResult[],
  ): ProviderResult[] {
    const builtProviderList: ProviderResult[] = [];

    if (currentProviderList != undefined) {
      currentProviderList.forEach((prov) => {
        builtProviderList.push(prov);
      });
    }

    if (this.isV2ProviderCard === false) {
      if (results.payload.requestResultCount > 0) {
        results.payload.results.forEach((result) => {
          const tempProvider = new ProviderResult();

          if (result.metadata.objectType == 'provider') {
            // eslint-disable-next-line @typescript-eslint/no-array-constructor
            tempProvider.providerPracticeBuildings = new Array<ProviderPracticeBuilding>();
            tempProvider.displayName = this.parseValue(result, 'displayName');
            tempProvider.profileName = this.parseValue(result, 'profileName');
            tempProvider.id = this.parseValue(result, 'providerId');

            if (this.doesContainField(result, 'conditions')) {
              tempProvider.conditions = this.parseValuesInArray(result, 'conditions');
            }

            if (this.doesContainField(result, 'signatureSpecialty')) {
              tempProvider.signatureSpecialty = this.parseValue(result, 'signatureSpecialty');
            }
            if (this.doesContainField(result, 'employed')) {
              tempProvider.employed = this.parseValue(result, 'employed');
            }

            if (this.doesContainField(result, 'practiceBuildings')) {
              const pracBuildings = this.parseValuesInArray(result, 'practiceBuildings');
              pracBuildings.forEach((item) => {
                const providerPracticeBuilding =
                  this.locationService.transformPracticeBuildingStringToObject(item);

                tempProvider.providerPracticeBuildings.push(providerPracticeBuilding);
              });
              if (
                this.locationService.locationAddress ||
                this.locationService.locationCoordinates ||
                this.locationService.searchAddress
              ) {
                tempProvider.providerPracticeBuildings =
                  tempProvider.providerPracticeBuildings.sort((a, b) => {
                    const distanceA = parseFloat(a.distance.split(' ')[0].trim());
                    const distanceB = parseFloat(b.distance.split(' ')[0].trim());

                    if (distanceB < distanceA) {
                      return 1;
                    } else if (distanceB > distanceA) {
                      return -1;
                    } else {
                      return 0;
                    }
                  });
              } else
                tempProvider.providerPracticeBuildings =
                  tempProvider.providerPracticeBuildings.sort((a, b) =>
                    b.primaryPractice > a.primaryPractice ? 1 : -1,
                  );
            }

            if (this.doesContainField(result, 'ratingCount')) {
              tempProvider.ratingCount = this.parseValue(result, 'ratingCount');
            }

            if (this.doesContainField(result, 'ratingValue')) {
              tempProvider.ratingValue = parseFloat(this.parseValue(result, 'ratingValue')).toFixed(
                1,
              );
            }

            if (this.doesContainField(result, 'bio')) {
              tempProvider.bio = this.parseValue(result, 'bio');
            }

            if (this.doesContainField(result, 'schedulable')) {
              tempProvider.schedulable = this.parseValue(result, 'schedulable');
            }

            if (this.doesContainField(result, 'npi')) {
              tempProvider.npi = this.parseValue(result, 'npi');
            }

            if (this.doesContainField(result, 'providerImage')) {
              tempProvider.providerImage = this.parseValue(result, 'providerImage');
            }

            if (this.doesContainField(result, 'visitTypes')) {
              tempProvider.visitTypes = this.parseValuesInArray(result, 'visitTypes');
            }

            if (this.doesContainField(result, 'outPatientEnabled')) {
              const outPatientEnabledString: string = this.parseValue(result, 'outPatientEnabled');
              if (outPatientEnabledString === 'true') {
                tempProvider.outPatientEnabled = true;
              } else {
                tempProvider.outPatientEnabled = false;
              }
            }
            //false means default type cards
            tempProvider.isPractiseLocation = false;
            builtProviderList.push(tempProvider);
          }
        });
      }
      return builtProviderList;
    }
  }

  doesContainField(result: FadSearchPayload, fieldCheck: string): boolean {
    let containsField: boolean = false;
    result.metadata.fields.forEach((field) => {
      if (fieldCheck === field.name) {
        containsField = true;
      }
    });
    return containsField;
  }

  checkForNewIndexAndReset(searchResult: FadSearchResult) {
    if (searchResult.payload.startIndex === 0) {
      this.providerResults = [];
    }
  }

  subToAhnProvSubj() {
    this.filterServ.ahnProviderSubj.subscribe((data) => {
      this.isAhnProvidersSelected = data;
      this.selectedFilters.ahn = this.isAhnProvidersSelected;
    });
  }

  subToSpinnerSubj() {
    this.spinnerSub = this.fadSeachStateService.isShowSpinnerSubj.subscribe((isSpinning) => {
      this.isRequestPending = isSpinning;
    });
  }

  subToPhoneSchedulableProvSubj() {
    this.filterServ.makeAppointmentSubj.subscribe((data) => {
      this.isSchedulableByPhoneSelected = data;
      this.selectedFilters.outpatientenabled = this.isSchedulableByPhoneSelected;
    });
  }

  subToVideoBioSubj() {
    this.filterServ.videoBioSubj.subscribe((data) => {
      this.isVideoBioSelected = data;
      this.selectedFilters.video = this.isVideoBioSelected;
    });
  }
  subToLanguagesSpokenSubj() {
    this.filterServ.lanaguageFilterSubj.subscribe((data) => {
      this.languageSelected = data;
      this.selectedFilters.languages = this.languageSelected;
    });
  }

  subToGenderSubj() {
    this.filterServ.genderFilterSubj.subscribe((data) => {
      this.genderSelected = data;
    });
  }

  subToConditionOrTreatmentSubj() {
    this.filterServ.conditionFilterSubj.subscribe((data) => {
      this.conditionSelected = data;
      this.selectedFilters.conditions = this.conditionSelected;
    });
  }

  subToOnlineSchedulableProvSubj() {
    this.filterServ.onlineSchedulingSubj.subscribe((data) => {
      this.isSchedulableOnlineSelected = data;
      this.selectedFilters.schedule = this.isSchedulableOnlineSelected;
    });
  }

  subToLGBTQChampProvSubj() {
    this.filterServ.LGBTQQIP2SAASubj.subscribe((data) => {
      this.isLGBTQChampSelected = data;
      this.selectedFilters.lgbtq = this.isLGBTQChampSelected;
    });
  }

  subToLocationSub() {
    this.filterServ.locationFilterSubj.subscribe((data) => {
      this.locationSelected = data[0];
      this.locationDistanceSelected = parseInt(data[1]);

      this.selectedFilters.location = this.locationSelected;
      this.selectedFilters.locationdistance = this.locationDistanceSelected;
    });
  }

  subToTotalResultsCountSubj() {
    this.filterServ.totalResultsCountSubj.subscribe((data) => {
      this.totalResultsCount = data;
      this.selectedFilters.totalResultsCount = this.totalResultsCount;
    });
  }

  ngOnDestroy(): void {
    this.checkLoadMoreSub.unsubscribe();
    this.currentIndexSub.unsubscribe();
    this.currentSearchOrderSub.unsubscribe();
    this.currentResultsCountSub.unsubscribe();
  }

  receiveShowTenMoreRequest($event: number) {
    this.isScrolledToProvider = false;
    const scroll = $event;
    this.fadSeachStateService.scrollY = scroll;
    this.updateSearchCount();
    this.sortOrderSelected = this.selectedFilters.sortorder;
    this.buildSearchParams(true);
  }

  receiveSortEmitter(sort) {
    this.sortOrderSelected = sort;
    this.buildSearchParams(false);
  }

  //Desktop Conditions Dropdown
  receiveConditionFilterEmitter(cond: string) {
    this.conditionSelected = cond;
    this.buildSearchParams(false);
  }

  receiveLanguageFilterEmitter(lang: string) {
    this.languageSelected = lang;
    this.buildSearchParams(false);
  }

  receiveGenderFilterEmitter(gender: string) {
    this.genderSelected = gender;
    this.buildSearchParams(false);
  }

  receiveCheckboxFilterEmitter(type: string, value: boolean) {
    switch (type) {
      case 'ahn':
        this.isAhnProvidersSelected = value;
        break;
      case 'video':
        this.isVideoBioSelected = value;
        break;
      case 'lgbtq':
        this.isLGBTQChampSelected = value;
        break;
      case 'phone':
        this.isSchedulableByPhoneSelected = value;
        break;
      case 'online':
        this.isSchedulableOnlineSelected = value;
        break;
    }
    this.buildSearchParams(false);
  }

  receiveCheckboxFilterEmitterMobile(type: string, value: boolean) {
    switch (type) {
      case 'ahn':
        this.isAhnProvidersSelected = value;
        break;
      case 'video':
        this.isVideoBioSelected = value;
        break;
      case 'lgbtq':
        this.isLGBTQChampSelected = value;
        break;
      case 'phone':
        this.isSchedulableByPhoneSelected = value;
        break;
      case 'online':
        this.isSchedulableOnlineSelected = value;
        break;
    }
  }

  receiveDistanceFilterEmitter(locOption: DistanceOptionChange) {
    this.distanceOptionsSelected = locOption;
    if (locOption.optionName === '' || locOption.optionValue === 0) {
      this.sortOrderSelected = 'DEFAULT';
    }
    this.buildSearchParams(false);
  }

  receiveDistanceFilterEmitterMobile(locOption: DistanceOptionChange) {
    this.distanceOptionsSelected = locOption;
  }

  receiveMobileApplyEmitter($event) {
    this.buildSearchParams(false);
  }

  buildSearchParams(tenMore: boolean) {
    this.selectedFilters.conditions = this.conditionSelected;
    this.selectedFilters.languages = this.languageSelected;
    this.selectedFilters.gender = this.genderSelected;
    this.selectedFilters.sortorder = this.sortOrderSelected;
    this.selectedFilters.ahn = this.isAhnProvidersSelected;
    this.selectedFilters.lgbtq = this.isLGBTQChampSelected;
    this.selectedFilters.video = this.isVideoBioSelected;
    this.selectedFilters.outpatientenabled = this.isSchedulableByPhoneSelected;
    this.selectedFilters.schedule = this.isSchedulableOnlineSelected;
    this.selectedFilters.location = this.locationService.searchAddress;
    this.selectedFilters.locationdistance = this.locationService.searchDistance;
    if (!tenMore) {
      this.filterServ.totalResultsCountSubj.next(10);
    }

    let searchTerm = '';
    searchTerm = this.activatedRoute.snapshot.params['search'];
    let params = {};

    Object.entries(this.selectedFilters).map(([key, value]) => {
      if (value != false || value != '' || value != 0) {
        params[key] = value;
      }
    });

    if (
      Object.keys(params).length === 3 &&
      this.selectedFilters.gender === 'Any Gender' &&
      this.selectedFilters.sortorder === 'DEFAULT' &&
      this.selectedFilters.totalResultsCount === 10
    ) {
      params = {};
    } else if (
      Object.keys(params).length === 5 &&
      this.selectedFilters.location != '' &&
      this.selectedFilters.sortorder === 'DEFAULT' &&
      this.selectedFilters.totalResultsCount === 10
    ) {
      params = {
        location: this.selectedFilters.location,
        locationdistance: this.selectedFilters.locationdistance,
      };
    }

    const urlfetchedSearchTerm: string = decodeURIComponent(
      this.activatedRoute.snapshot.params['search'],
    );

    const encodeUrlSearchTerm = this.getEncodedUrl(urlfetchedSearchTerm);
    this.router.navigate(['search-results/' + encodeUrlSearchTerm], {
      queryParams: params,
    });
  }

  getEncodedUrl(keyword: string): string {
    return encodeURIComponent(keyword);
  }

  updateSearchCount() {
    this.selectedFilters.totalResultsCount = Number(this.totalResultsCount) + 10;
  }

  ngAfterViewChecked() {
    if (isPlatformBrowser(this.platformId)) {
      const currentY = this.viewportScroller.getScrollPosition()[1];
      const destinationY = this.fadSeachStateService.scrollY;
      if (this.isScrolledToProvider === false) {
        setTimeout(() => {
          this.viewportScroller.scrollToPosition([0, this.fadSeachStateService.scrollY]);
          if (currentY >= destinationY) {
            this.isScrolledToProvider = true;
            this.viewportScroller.scrollToPosition([0, this.fadSeachStateService.scrollY]);
          }
        }, 400);
      }
    }
  }

  ngAfterViewInit() {
    this.subToWindowWidthSubj();
    if (isPlatformBrowser(this.platformId)) {
      if (document.getElementById('base-page')) {
        const wid = document.getElementById('base-page').clientWidth;
        this.windowSizeServ.windowWidthSubj.next(wid);
      }
      const basePage: HTMLElement = document.getElementById('base-page');

      if (basePage) {
        window.addEventListener('resize', () => {
          const wid = document.getElementById('base-page').clientWidth;
          this.windowSizeServ.windowWidthSubj.next(wid);
        });
      }
    }
  }

  subToWindowWidthSubj() {
    this.windowWidthSub = this.windowSizeServ.windowWidthSubj
      .pipe(debounceTime(500))
      .subscribe((width) => {
        this.currentWindowWidth = width;
        this.isDesktop = this.convertWindowWidthToIsDesktop(
          width,
          this.windowSizeServ.desktopMinWidth,
        );
      });
  }

  convertWindowWidthToIsDesktop(width: number, desktopMinWidth: number) {
    if (width >= desktopMinWidth) {
      return true;
    } else {
      return false;
    }
  }
}
